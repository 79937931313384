<template>
  <div class="custom-report" :class="{'point-lang': locale === 'ru'}">
    <custom-reports-context-menu
      ref="contextMenu"
      :disabled-actions="disabledActions"
      :export-urls="exportUrls"
      @before-create-report="beforeCreateReport"
    ></custom-reports-context-menu>

    <custom-reports-header
      ref="header"
      :loading="loading"
      :page-size="pageSize"
      :selected-row.sync="selectedRow"
      :all-dimension="allDimension"
      :current-report-prop.sync="currentReport"
      :count="count"
      :disable-drilldown="disableDrilldown"
      :disabled-actions="disabledActions"
      :export-urls="exportUrls"
      :reports.sync="reports"
      :columns-groups="columns_groups"
      :default-cols="default_cols"
      :cache-data="cacheData"
      :group-by-field="groupByField"
      :get-table-fields="getTableFields"
      :single-grouping-select.sync="singleGroupingSelect"
      :grouping-selects.sync="groupingSelects"
      :supported-metrics-prop="supportedMetrics"
      @get-report="handleGetReport"
      @before-create-report="beforeCreateReport"
      @create-report="createReport"
      @drilldown-report="drilldownReport"
      @reset-filters="resetFilters"
      @before-get-report="beforeGetReport"
      @select-report="selectReport"
      @cache-query="cacheQuery"
    ></custom-reports-header>

    <ui-table
      ref="dataTable"
      :fields="getTableFields"
      :data="data"
      :hide-fields="hiddenFields"
      :total="totals"
      :rows-count="reports[currentReport].limit"
      :sort="sorting"
      :class="{wavebg: loading}"
      show-total
      :select-row="multiCheckedRow"
      row-check
      custom-context
      i18n-path="reports"
      element-loading-custom-class="data-loader-spinner"
      @current-change="handleCurrentRowChange"
      @sort="handleSort"
      @filter="handleFilter"
      @context="rowContextHandler"
      @checkedRows="handleCheckedRows"
      @row-click="handleCurrentRowClick"
    />
    <mobtable
      ref="datatable"
      :class="{wavebg: loading}"
      :data.sync="data"
      :columns.sync="getTableFields"
      :formaters="formaters"
      :totals="totals"
      :hide-fields="hiddenFields"
      :sort-field="reports[currentReport].sort_by"
      :sort_dir="reports[currentReport].sort_dir"
      :drilldown="true"
      :hide-exclude="reports[currentReport].group_by === 'sub_affiliate_email'"
      @drilldown="handleCurrentRowMob"
    />
    <mobpagination
      v-if="data.length"
      :page="reports[currentReport].page"
      :page-size="reports[currentReport].limit || 0"
      :count="count"
      show-size-select
      class="mb-32"
      @page-change="pageChange"
    />
  </div>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import { setTimeout } from 'timers';
import CustomReportsCommonMixin from 'src/views/Dashboard/CustomReports/custom-reports-common-mixin';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import ColumnsMixin from 'src/views/Dashboard/Reports/columns-mixin';
import moment from 'moment';
import { resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import ResponsiveMixin from 'src/components/Helpers/responsive-mixin.js';
import CustomReportsHeader from 'src/views/Dashboard/CustomReports/CustomReportsHeader.vue';
import CustomReportsContextMenu from 'src/views/Dashboard/CustomReports/CustomReportsContextMenu.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';


const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'reports/custom';
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});
const currentSupportedMetrics = null;
let $tapReady = null;
const $readyPromise = new Promise((resolve) => {
  $tapReady = resolve;
});
const fetch = async (ctx) => {
  await ctx.$store.dispatch('getSubAffiliates', {
    limit: 10000,
    offset: 0,
  });
  return {};
};

export default {
  fetch,
  name: 'CustomReport',
  components: {
    Mobtable,
    Mobpagination,
    CustomReportsHeader,
    CustomReportsContextMenu,
  },
  mixins: [
    CustomReportsCommonMixin,
    InjectPlugins,
    DimensionsMixin,
    ColumnsMixin,
    UrlMixin,
    UrlMixinHooks,
    ResponsiveMixin,
    HelpersMixin,
  ],
  props: ['passedParams'],
  data() {
    return {
      viewName,
      loading: false,
      columns_groups: [],
      default_cols: [],
      hiddenFields: [],
      data: [],
      totals: {},
      count: 0,
      render: 0,
      renders: 0,
      formaters: Formaters,
      passedPeriod: {},
      period: 30,
      reports: [
        {
          bookmarkName: '',
          group_by: 'date_formatted',
          prevDimension: '',
          nextDimension: {},
          dateRange: [],
          searchQuery: '',
          limit: pageSize,
          page: 1,
          sort_by: '',
          sort_dir: 'desc',
          defaultFilter: {},
          currentFilter: {},
          currentFilterNames: {},
          defaultFilterName: '',
          columnsFilter: {},
          isPinned: false,
          isPinnedBookmark: false,
          selectedColumns: [],
          playersType: 'all',
          period: '',
        },
      ],
      currentReport: 0,
      singleGroupingSelect: '',
      groupingSelects: { datetime: { name: 'date_formatted' } },
      selectedRow: {},
      disableDrilldown: true,
      i18nPath: 'reports',
      playersTypes: [
        {
          label: 'All Players',
          value: 'all',
        }, {
          label: 'New Players',
          value: 'new',
        }, {
          label: 'Old Players',
          value: 'old',
        },
      ],
      promocodeReport: {},
      subAffiliateReport: {},
      selectRows: [],
      rowSelectable: false,
      selectIds: [],
      multiCheckedRow: false,
      isPressCtrl: false,
      locale: this.$locale,
      activateGetReport: false,
      exportUrls: {},
      allDimension: [],
      pageSize,
    };
  },
  computed: {
    cacheData() {
      this.reports[this.currentReport].period = this.passedPeriod.period || this.reports[this.currentReport].period;
      return {
        singleGroupingSelect: this.singleGroupingSelect,
        groupingSelects: this.groupingSelects,
        currentReport: this.currentReport || 0,
        reports: this.reports,
        from: this.period.from,
        to: this.period.to,
        period: this.period.period,
        passedPeriod: this.passedPeriod,
        searchQuery: this.searchQuery,
      };
    },
    getTableFields() {
      const metrics = (this.$store.state.settings.metrics || []).map((e) => {
        this.allMetrics.map((i) => { if (i.name === e.column_name) e = { ...e, ...i }; });
        return e;
      });
      const columns = this.reports[this.currentReport].selectedColumns;
      const fields = columns.map(e => metrics.find(f => f.column_name === e));

      fields.map(e => e.format = false);

      if (this.groupByField && this.groupByField.column_name === 'date_formatted') {
        this.groupByField.component = null;
      }
      fields.unshift({
        ...this.groupByField,
        source: 'metrics',
      } || {});
      fields.map(e => e.sortable = 'custom');
      this.render++;

      return fields;
    },
    groupByField() {
      const gb = this.reports[this.currentReport].group_by;
      return this.allDimension.find(field => field.name === gb);
    },
    sorting() {
      return {
        prop: this.reports[this.currentReport].sort_by,
        order: this.reports[this.currentReport].sort_dir,
      };
    },
    disabledActions() {
      return {
        drilldown: this.disableDrilldown || this.$_.isEmpty(this.selectedRow) && this.selectIds.length === 0,
        exclude: this.disableDrilldown || this.reports[this.currentReport].group_by === 'sub_affiliate_email' || this.$_.isEmpty(this.selectedRow) && this.selectIds.length === 0,
      };
    },
  },
  updated() {
    this.locale = this.$locale;
  },
  watch: {
    currentReport(index) {
      const periodItems = [7, -70, -7, -14, 30, -30, 100, 365, -365, -3650];
      this.passedPeriod = { period: this.reports[index].period };

      if (!periodItems.includes(this.reports[index].period) && typeof this.reports[index].period === 'symbol') {
        const a = this.reports[index].period.substr(0, 10);
        const b = this.reports[index].period.substr(-10, 10);

        this.passedPeriod = {
          period: `${moment(a)
            .format('YYYY-MM-DD')} ${this.$t('periodWrapper.to')} ${moment(b)
            .format('YYYY-MM-DD')}`,
        };
      }
      this.selectedRow = {};
      // this.getWidthItemForTooltip();
    },
    groupingSelects() {
      this.$refs.dataTable.clearCheck();
      this.selectRows = [];
      this.selectIds = [];
      this.disableDrilldown = true;
    },
    $locale: {
      immediate: true,
      handler(value) {
        if (this.locale !== value) {
          this.locale = value;
          this.loading = true;

          Promise.all([
            this.$store.dispatch('getSettings'),
            this.resolveMetrics(),
          ]).then(() => this.getReport('locale'));
        }
      },
    },
  },
  created() {
    this.loading = true;
    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.custom') {
        this.getReport('created');
      }
    });
    this.lastTab();
  },
  async mounted() {
    const default_cols = await this.resolveMetrics();

    Object.keys(this.passedParams)
      .map((key) => {
        this[key] = this.$_.cloneDeep(this.passedParams[key]);
      });
    if (this.passedParams.reports) {
      this.$refs.dataTable.setColumnFilters(this.passedParams.reports[this.passedParams.currentReport].columnsFilter);
    }
    if (!this.passedParams.reports || !this.passedParams.reports[this.passedParams.currentReport].selectedColumns.length) {
      this.reports[this.currentReport].selectedColumns = default_cols;
    }

    const isRequestSent = this.handleRedirect();
    $tapReady();
    if (!isRequestSent) {
      await this.getReport('mounted', true);
    }

    this.activateGetReport = true;

    document.addEventListener('click', this.pressCtrl, true);
    document.addEventListener('keydown', this.switchMultiSelect, true);
    document.addEventListener('keyup', this.switchMultiSelect, true);
  },
  async activated() {
    this.activateGetReport = true;
    const isRequestSent = this.handleRedirect();

    if (!isRequestSent) {
      await this.getReport('activated', true);
    }

    this.activateGetReport = true;
  },
  deactivated() {
    this.activateGetReport = false;
  },
  destroyed() {
    document.removeEventListener('click', this.pressCtrl, true);
    document.removeEventListener('keydown', this.switchMultiSelect, true);
    document.removeEventListener('keyup', this.switchMultiSelect, true);
  },
  methods: {
    resetFilters(index) {
      this.singleGroupingSelect = '';
      this.reports[index].dateRange = [
        this.$moment()
          .startOf('month')
          .format('YYYY-MM-DD 00:00:00'),
        this.$moment()
          .endOf('day')
          .format('YYYY-MM-DD 23:59:59'),
      ];
      this.reports[index].period = 30;
      this.reports[index].searchQuery = '';
      this.reports[index].page = 1;
      this.reports[index].sort_dir = 'desc';
      this.reports[index].columnsFilter = {};
      this.reports[index].playersType = 'all';

      if (index === 0) {
        this.groupingSelects = { datetime: { name: 'date_formatted' } };
        this.reports[index].group_by = 'date_formatted';
        this.reports[index].nextDimension = {};
        this.reports[index].sort_by = 'date_formatted';
        this.reports[index].defaultFilter = {};
        this.reports[index].currentFilter = {};
      } else {
        this.reports[index].sort_by = this.reports[index].group_by;
      }

      this.getReport('reset home');

      this.$refs.dataTable.$refs.dataTable.store.clearSort();
      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.$refs.dataTable.clearCheck();
      this.$refs.dataTable.$refs.dataTable.sort(this.reports[index].sort_by, `${this.reports[index].sort_dir}ending`);
      this.$refs.dataTable.$refs.dataTable.doLayout();
      this.$refs.dataTable.setColumnFilters();
      this.$refs.dataTable.cleanUpSelection();
    },
    async resolveMetrics() {
      const { ready } = this.$store.state.settings;

      return await ready.then(() => {
        const {
          dimensions, grouped_dimensions, grouped_metrics, metrics, metrics_visible_by_default,
        } = this.$store.state.settings;
        const report = this.reports[this.currentReport];

        this.columns_groups = grouped_metrics.map((e) => {
          e.items.map((i) => {
            if (i.items && !i.items.length) delete i.items;
            return i;
          });
          return e;
        });

        this.supportedMetrics = metrics.map(e => e.name);
        const dimension = (dimensions || []).map((e) => {
          this.allDimensions.map((i) => { if (i.name === e.column_name) e = { ...e, ...i }; });
          return e;
        });
        let dim = [];
        const gim = (grouped_dimensions || []).map(e => e.items.map((i) => {
          dim.push(i);
        }));
        dim = dim.map((e) => {
          dimension.map((i) => { if (i.name === e.name) e = i; });
          return e;
        });
        this.allDimension = dim;
        this.reports[this.currentReport].selectedColumns = report.selectedColumns.filter(e => this.supportedMetrics.includes(e));
        this.default_cols = metrics_visible_by_default;

        return this.default_cols;
      });
    },
    getDataQuery(report) {
      const sort_by = report.sort_by && report.selectedColumns.includes(report.sort_by) ? report.sort_by : report.group_by;
      const columnsFilter = {};
      this.$_.forOwn(report.columnsFilter, (e, key) => {
        columnsFilter[key] = this.$_.pick(e, ['op', 'value']);
      });
      Object.keys(columnsFilter)
        .forEach((e) => {
          let flagDel = false;
          report.selectedColumns.forEach((el) => {
            e === el ? flagDel = true : '';
          });
          !flagDel ? delete columnsFilter[e] : '';
        });

      const filter = {};

      for (const key in report.defaultFilter) {
        filter[report.defaultFilter[key].row_filter_column] = {
          op: report.defaultFilter[key].op,
          value: typeof report.defaultFilter[key].row_id === 'object' ? report.defaultFilter[key].row_id : [report.defaultFilter[key].row_id],
        };
      }

      for (const key in report.currentFilter) {
        filter[report.currentFilter[key].row_filter_column] = {
          op: report.currentFilter[key].op,
          value: typeof report.currentFilter[key].row_id === 'object' ? report.currentFilter[key].row_id : [report.currentFilter[key].row_id],
        };
      }
      this.reports[this.currentReport].sort_by = sort_by || report.group_by;

      return {
        dimensions: [report.group_by],
        from: report.dateRange[0],
        to: report.dateRange[1],
        limit: report.limit,
        offset: report.limit * report.page - report.limit,
        sorting: [{
          sort_by: sort_by || report.group_by,
          sort_dir: report.sort_dir || 'desc',
        }],
        players_filter: report.playersType,
        having: columnsFilter,
        search: report.searchQuery ? {
          [report.group_by]: {
            op: 'like',
			      value: [report.searchQuery],
          },
        } : {},
        metrics: report.selectedColumns,
        filters: filter,
        metrics_format: 'pretty',
      };
    },
    beforeGetReport(value) {
      if (value && value.length) {
        this.reports[this.currentReport].selectedColumns = value;
      }

      // позже убрать эту функцию вообще
      this.getReport('beforeGetReport');
    },
    handleGetReport(report) {
      this.reports[this.currentReport] = report;
      this.activateGetReport = true;
      this.getReport('handleGetReport');
    },
    async getReport(from, ignoreActivateGetReport) {
      this.loading = true;
      await this.afterBrowserRedraw();

      if (!ignoreActivateGetReport && !this.activateGetReport) {
        return;
      }

      await this.resolveMetrics();

      setTimeout(() => {
        const el = document.getElementById('myel');
        if (el) el.scrollTo(1000000, 0);
        this.lastTab();
      }, 0);

      this.isContextOpen = false;
      const query = this.getDataQuery(this.reports[this.currentReport]);

      if (!query.metrics || !query.metrics.length) {
        this.loading = false;
        return;
      }

      this.cacheQuery();
      this.$api
        .postReport(query)
        .then((res) => {
          this.data = res.payload.data.map((e) => {
            e.sub_affiliate_email = e.affiliate_email;
            return e;
          });
          this.totals = res.payload.totals;
          this.count = res.misc.count;
          this.exportUrls = res.misc.export_urls;
          this.$refs.dataTable.setSortVisibility([query.sort_by, ...Object.keys(query.having)]);
          this.$refs.dataTable.$refs.dataTable.sort(query.sort_by, `${query.sort_dir}ending`);
          // this.$forceUpdate();
          // this.$refs.dataTable.$forceUpdate();
          this.afterBrowserRedraw(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async beforeCreateReport(customSettings, op) {
      this.loading = true;
      await this.afterBrowserRedraw();

      if (!this.$_.isEmpty(this.reports[this.currentReport].defaultFilter)) {
        if (op === '!=' && this.reports[this.currentReport].defaultFilter[this.reports[this.currentReport].group_by]) {
          const defaultFilter = this.reports[this.currentReport].defaultFilter[this.reports[this.currentReport].group_by];
          const row_ids = this.$_.union(defaultFilter.row_id, this.selectIds);
          const selectedRow = this.$_.union(defaultFilter.selectedRow, this.selectRows.map(e => e[this.reports[this.currentReport].group_by]));
          const selectedRowList = selectedRow.map((e, i) => ({
            rowTooltip: e,
            row_id: row_ids[i],
          }));

          this.reports[this.currentReport].defaultFilter[this.reports[this.currentReport].group_by] = {
            row_id: row_ids,
            visible: selectedRow[0],
            group_by: this.reports[this.currentReport].group_by,
            op: defaultFilter.op || '!=',
            selectIdsLength: row_ids.length <= 1 ? '' : `[+${row_ids.length - 1}]`,
            selectedRow,
            selectedRowList,
            row_filter_column: defaultFilter.row_filter_column,
          };

          this.getReport('add exclude report');
        } else if (op === '!=' && this.reports[this.currentReport].currentFilter[this.reports[this.currentReport].group_by]) {
          const currentFilter = this.reports[this.currentReport].currentFilter[this.reports[this.currentReport].group_by];
          const row_ids = this.$_.union(currentFilter.row_id, this.selectIds);
          const selectedRow = this.$_.union(currentFilter.selectedRow, this.selectRows.map(e => e[this.reports[this.currentReport].group_by]));
          const selectedRowList = selectedRow.map((e, i) => ({
            rowTooltip: e,
            row_id: row_ids[i],
          }));

          this.reports[this.currentReport].currentFilter[this.reports[this.currentReport].group_by] = {
            row_id: row_ids,
            visible: selectedRow[0],
            group_by: this.reports[this.currentReport].group_by || '',
            op: currentFilter.op || '!=',
            selectIdsLength: row_ids.length <= 1 ? '' : `[+${row_ids.length - 1}]`,
            selectedRow,
            selectedRowList,
            row_filter_column: currentFilter.row_filter_column,
          };

          this.getReport('add exclude drilldown');
        } else if (op === '=' && this.reports[this.currentReport].defaultFilter[this.reports[this.currentReport].group_by]) {
          this.createReport(null, op, true);
        } else {
          this.drilldownReport(op);
        }
      } else {
        this.createReport(null, op);
      }
      this.selectedRow = {};
      this.selectIds = [];
    },
    createReport(customSettings, op, changeOp) {
      const { referral_program_enabled } = this.$store.state.profile.user;
      const { row_filter_column } = this.selectedRow || Object.keys(customSettings.defaultFilter)[0] || {};
      const ref_selected = row_filter_column === 'affiliate_id';
      const date_types = ['date_raw', 'hour_raw', 'start_of_week_raw', 'start_of_month_raw', 'year_raw', 'day_of_week_raw', 'day_of_month_raw'];

      if (date_types.includes(row_filter_column) && op === '=') {
        customSettings = customSettings || {
          group_by: referral_program_enabled && !ref_selected ? 'sub_affiliate_email' : 'affiliate_offer_name',
        };
      } else if (op === '=') {
        customSettings = customSettings || {
          group_by: 'date_formatted',
        };
      }
      this.isContextOpen = false;
      const current = this.reports[this.currentReport];
      let group_by = this.reports[this.currentReport].group_by;

      if (customSettings && customSettings.group_by_filters) {
        group_by = customSettings.group_by_filters;
      }

      const report = {
        bookmarkName: '',
        group_by: '',
        nextDimension: {},
        selectedColumns: current.selectedColumns,
        dateRange: current.dateRange,
        period: current.period,
        limit: current.limit,
        currentFilterNames: {},
        page: 1,
        sort_by: '',
        sort_dir: 'desc',
        defaultFilter: {},
        currentFilter: {},
        columnsFilter: {},
        isPinned: false,
        isPinnedBookmark: false,
        playersType: 'all',
      };

      if (!this.$_.isEmpty(this.selectedRow)) {
        report.defaultFilter[group_by] = this.selectedRow.row_id;

        const rowTooltip = this.selectRows.map(e => e[group_by] || '');
        const rowTooltipList = this.selectRows.map(e => ({
          rowTooltip: e[group_by] || '',
          row_id: e.row_id,
        }));

        report.defaultFilter[group_by] = {
          row_id: this.selectIds.length ? this.selectIds : [this.selectedRow.row_id],
          visible: this.selectIds.length ? `${this.selectRows[0][group_by]} ` : this.selectedRow[group_by] || customSettings.defaultFilterName,
          group_by,
          op: op || '=',
          selectIdsLength: this.selectIds.length <= 1 ? '' : `[+${this.selectIds.length - 1}]`,
          selectedRow: rowTooltip,
          selectedRowList: rowTooltipList,
          row_filter_column: (this.selectRows[0] || {}).row_filter_column,
        };
        report.defaultFilterName = this.selectIds.length ? `${this.selectRows[0][group_by]} ` : this.selectedRow[group_by];

        if (op === '=') {
          report.group_by = customSettings.group_by;
        } else {
          report.group_by = group_by;
        }
      }

      report.sort_by = current.sort_by === current.group_by ? report.group_by : current.sort_by || current.group_by;

      if (customSettings) {
        if (customSettings.selectedColumns && report.selectedColumns !== customSettings.selectedColumns) {
          report.selectedColumns = customSettings.selectedColumns; // for a bookmark
        }
        this.$_.merge(report, customSettings);
      }
      this.passedPeriod.period = report.period;
      this.selectedRow = {};

      if (changeOp) {
        this.reports[this.currentReport] = report;
        this.currentReport = 0;
      } else {
        this.reports.push(report);
      }
      this.selectReport(this.reports.length - 1);
    },
    selectReport(index) {
      if (this.currentReport === index) {
        return;
      }

      if (this.$refs.dataTable) {
        this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.$refs.dataTable.setColumnFilters(this.reports[index].columnsFilter);
      }

      this.currentReport = index;
      this.groupingSelects = {};
      this.singleGroupingSelect = '';
      this.groupingSelects[this.groupByField.type] = this.groupByField;

      this.getReport('select report');
    },
    lastTab() {
      if (this.$refs.header) {
        this.$refs.header.lastTab();
      }
    },
    drilldownReport(op) {
      this.$refs.dataTable.clearCheck();
      this.isContextOpen = false;
      this.rowSelectable = false;
      this.allDimension.find(field => field.name === this.reports[this.currentReport].group_by).filterName = this.selectedRow.row_filter_column;
      this.$set(this.reports[this.currentReport].currentFilterNames, this.selectedRow.row_filter_column, this.selectedRow[this.reports[this.currentReport].group_by]);

      const rowTooltip = this.selectRows.map(e => e[this.reports[this.currentReport].group_by]);
      const rowTooltipList = this.selectRows.map(e => ({
        rowTooltip: e[this.reports[this.currentReport].group_by],
        row_id: e.row_id,
      }));

      this.$set(this.reports[this.currentReport].currentFilter, this.reports[this.currentReport].group_by, {
        row_id: this.selectIds.length ? this.selectIds : this.selectedRow.row_id,
        visible: this.selectIds.length ? `${this.selectRows[0][this.reports[this.currentReport].group_by]} ` : this.selectedRow[this.reports[this.currentReport].group_by],
        group_by: this.reports[this.currentReport].group_by,
        op,
        selectIdsLength: this.selectIds.length <= 1 ? '' : `[+${this.selectIds.length - 1}]`,
        selectedRow: rowTooltip,
        selectedRowList: rowTooltipList,
        row_filter_column: this.selectRows[0].row_filter_column,
      });

      const allDimension = this.allDimension.filter(e => !this.isHidden(e.column_name) || e.name === this.reports[this.currentReport].group_by);

      const currentDimension = allDimension.find(field => field.name === this.reports[this.currentReport].group_by);
      const index = this.$_.findIndex(allDimension, e => currentDimension.name === e.name);
      let nextDimension = op === '=' ? allDimension[index + 1] : allDimension[index];

      if (!nextDimension) {
        nextDimension = allDimension[0];
      }

      if (!nextDimension) {
        console.error('can\'t select next dimension');

        this.disableDrilldown = true;
        this.loading = false;
        return;
      }

      this.reports[this.currentReport].nextDimension = this.$_.clone(nextDimension);
      this.reports[this.currentReport].prevDimension = this.reports[this.currentReport].group_by;

      if (this.reports[this.currentReport].sort_by === this.reports[this.currentReport].group_by) {
        this.reports[this.currentReport].sort_by = this.reports[this.currentReport].nextDimension.name;
      }

      this.reports[this.currentReport].group_by = this.reports[this.currentReport].nextDimension.name;
      this.reports = this.$_.cloneDeep(this.reports);
      this.groupingSelects = {};
      this.singleGroupingSelect = '';

      if (this.groupByField.type === 'single' || this.groupByField.type === 'affiliate') {
        this.singleGroupingSelect = this.reports[this.currentReport].group_by;
      } else {
        this.groupingSelects[this.groupByField.type] = this.groupByField;
      }

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.reports[this.currentReport].page = 1;
      this.reports[this.currentReport].searchQuery = '';
      this.reports[this.currentReport].columnsFilter = {};
      this.$refs.dataTable.setColumnFilters();
      this.getReport('drilldown');
    },

    handleRedirect() {
      const { openPromocodeReport, openSubAffiliateReport, passedPeriod } = this.urlQuery;
      const periodItems = [7, -70, -7, -14, 30, -30, 100, 365, -365, -3650];

      if (passedPeriod && periodItems.includes(passedPeriod.period)) {
        passedPeriod.period = passedPeriod.period;
      } else if (passedPeriod) {
        passedPeriod.period = `${moment(passedPeriod.from)
          .format('YYYY-MM-DD')} ${this.$t('periodWrapper.to')} ${moment(passedPeriod.to)
          .format('YYYY-MM-DD')}`;
      }

      $readyPromise.then(() => {
        if (openSubAffiliateReport) {
          this.removePromocode();
          this.subAffiliateReport = openSubAffiliateReport;
          this.selectedRow = {
            row_filter_column: 'affiliate_id',
            row_id: openSubAffiliateReport.data.id,
          };
          this.selectRows = [this.selectedRow];
          this.createReport({
            defaultFilterName: openSubAffiliateReport.sub_affiliate_email,
            dateRange: [passedPeriod.from, passedPeriod.to],
            period: passedPeriod.period,
            group_by: 'affiliate_offer_name',
            group_by_filters: 'sub_affiliate_email',
            sort_by: 'affiliate_offer_name',
            sort_dir: 'desc',
          });
          this.passedPeriod = passedPeriod;
        }
        if (openPromocodeReport) {
          this.removePromocode();
          this.promocodeReport = openPromocodeReport;
          this.selectedRow = {
            row_filter_column: openPromocodeReport.affiliate_id ? 'affiliate_id' : 'affiliate_player_promo_code_id',
            row_id: openPromocodeReport.affiliate_id ? openPromocodeReport.sub_affiliate_id : openPromocodeReport.data.id,
          };
          this.selectRows = [this.selectedRow];
          this.createReport({
            defaultFilterName: openPromocodeReport.affiliate_id ? openPromocodeReport.affiliate_id : openPromocodeReport.promo_code,
            dateRange: [passedPeriod.from, passedPeriod.to],
            period: passedPeriod.period,
            group_by: 'date_formatted',
            group_by_filters: openPromocodeReport.affiliate_id ? 'sub_affiliate_email' : 'affiliate_player_promo_code_name',
            sort_by: 'date_formatted',
            sort_dir: 'desc',
          });
          this.passedPeriod = passedPeriod;
        }
      });

      // check send report query or not
      return openSubAffiliateReport || openPromocodeReport;
    },
    handleCheckedRows(id, ids, row) {
      this.isContextOpen = false;
      this.rowSelectable = false;
      this.selectRows.push(row);
      this.selectRows = this.$_.filter(this.selectRows, e => ids.some(elem => elem === e.row_id));

      if (this.$_.isEqual(this.selectedRow, row) || !this.selectRows.length) {
        this.selectedRow = {};
        this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.disableDrilldown = true;
      } else {
        this.selectedRow = row;
        this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);
        this.disableDrilldown = false;
      }

      this.selectIds = ids;
    },
    handleCurrentRowClick(row) {
      if (this.$_.isEqual(this.selectedRow, row)) {
        this.selectedRow = {};
        this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.disableDrilldown = true;
      } else {
        this.selectedRow = row;
        this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);
        this.disableDrilldown = false;
      }

      if (!this.isPressCtrl) {
        this.$refs.dataTable.clearCheck();
        this.selectRows = [];
        this.selectIds = [];
      }
    },
    switchMultiSelect(event) {
      this.multiCheckedRow = !!(event.metaKey || event.ctrlKey);
    },
    pressCtrl(event) {
      this.isPressCtrl = event.metaKey || event.ctrlKey;
    },
    handleCurrentRowChange(current) {
      this.disableDrilldown = !current;
      if (this.selectRows.length) {
        this.disableDrilldown = false;
      }
    },
    handleCurrentRowMob({ current, op }) {
      this.selectIds = [current.row_id];
      this.selectedRow = current;
      this.disableDrilldown = !current;
      this.selectRows = [current];
      this.beforeCreateReport(null, op);
    },
    handleSort({ prop, order }) {
      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.reports[this.currentReport].sort_by = prop;
      this.reports[this.currentReport].sort_dir = order;
      this.getReport('handle sort');
    },
    pageChange(page, size) {
      if (this.reports[this.currentReport].page !== page || this.reports[this.currentReport].limit !== size) {
        this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.reports[this.currentReport].page = page;
        this.reports[this.currentReport].limit = size;
        this.getReport('handle page change');
      }
    },
    handleFilter(filter) {
      this.reports[this.currentReport].columnsFilter = {};
      Object.keys(filter)
        .forEach((key) => {
          if (filter[key].value && filter[key].op) {
            this.reports[this.currentReport].columnsFilter[key] = {
              op: filter[key].op,
              value: filter[key].value,
            };
          }
        });
      this.getReport('handle columns filter');
    },
    rowContextHandler(row, event) {
      this.selectedRow = row;

      if (this.selectRows.length >= 1 && this.selectRows.find(e => e.row_id === row.row_id)) {

      } else if (true) {
        this.selectRows = [row];
        this.selectIds = [row.row_id];
      }

      this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);

      if (this.selectIds.length === 0) {
        this.rowSelectable = true;
        this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);
      }

      if (this.$refs.contextMenu) {
        this.$refs.contextMenu.showContextMenu(event);
      }
    },
    removePromocode() {
      if (this.$refs.header) {
        this.$refs.header.removePromocode();
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/theme/default/reports/icons.scss';
  @import 'src/assets/theme/default/reports/element-theme.scss';
  @import 'src/assets/theme/default/CustomReports/styles.scss';
</style>
