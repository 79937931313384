import Promise from 'bluebird';
import { debounce } from 'fullcalendar';

export default {
  getGeoData({ commit }) {
    return this.$api.getGeoData().then((data) => {
      commit('saveGeoData', data.payload);
      return data;
    });
  },
  getCountries({ commit }) {
    return this.$api.getCountries({}).then((data) => {
      commit('saveCountries', data.payload);
      return data;
    });
  },
  getCustomerCurrencies({ commit }) {
    return this.$api.getCustomerCurrencies().then((data) => {
      commit('saveCustomerCurrencies', data.payload);
      return data;
    });
  },
  getPaymentMethods({ commit }) {
    return this.$api.getPaymentMethods({}).then((data) => {
      commit('savePaymentMethods', data.payload);
      return data;
    });
  },
  getTimezones({ commit }) {
    return this.$api.getTimezones({}).then((data) => {
      commit('saveTimezones', data.payload);
      return data;
    });
  },
  getMessengers({ commit }) {
    return this.$api.getMessengers().then((data) => {
      commit('saveMessengers', data.payload);
      return data;
    });
  },
  getLanguages({ commit }) {
    return this.$api.getLanguages({}).then((data) => {
      commit('saveLanguages', data.payload);
      return data;
    });
  },
  getCurrencies({ commit }) {
    return this.$api.getCurrencies({}).then((data) => {
      commit('saveCurrencies', data.payload);
      return data;
    });
  },
  getAvailableLanguages({ commit, dispatch, state }) {
    if (Array.isArray(state.availableLanguages) && state.availableLanguages.length > 0) {
      return state.availableLanguages;
    }

    if (state.availableLanguagesPromise) {
      return state.availableLanguagesPromise;
    }

    const availableLanguagesPromise = Promise.props({
      languagesList: dispatch('getLanguages').then(response => response.payload),
    }).then(({ languagesList }) => {
      const languagesMap = {};
      languagesList.forEach((item) => {
        languagesMap[item.code] = item;
      });
      const availableLanguages = Vue.prototype.$languages.map(({ code: languageCode }) => ({
        code: languagesMap[languageCode].code,
        name: languagesMap[languageCode].name,
        nameNative: languagesMap[languageCode].name_native,
      }));
      commit('saveAvailableLanguages', availableLanguages);
      return availableLanguages;
    });

    commit('saveAvailableLanguagesPromise', availableLanguagesPromise);
    return availableLanguagesPromise;
  },
  getLanguagesLazy: debounce(({ dispatch }) => dispatch('getLanguages'), 0),

  // Media Buying
  getPaymentMethodCount({ commit }, filter) {
    return this.$api.getPaymentMethodCount(filter).then((data) => {
      commit('savePaymentMethodCount', data.payload);
      return data;
    });
  },
  getPostPlatforms({ commit }, filter) {
    return this.$api.getPostPlatforms(filter).then((data) => {
      commit('savePostPlatforms', data.payload);
      return data;
    });
  },
  getPostPlatformsCount({ commit }, filter) {
    return this.$api.getPostPlatformsCount(filter).then((data) => {
      commit('savePostPlatformsCount', data.payload);
      return data;
    });
  },
  getChannelIcons({ commit }) {
    return this.$api.getChannelIcons().then((data) => {
      commit('saveChannelIcons', data.payload);
      return data;
    });
  },

  // Traffic Sources
  getTagTemplates({ commit }) {
    return this.$api.getTagTemplates().then((data) => {
      commit('saveTagTemplates', data.payload);
      return data;
    });
  },

  getMiscSettings({ commit }) {
    return this.$api.getMiscSettings().then((data) => {
      commit('saveMiscSettings', data.payload);
      return data;
    });
  },
};
