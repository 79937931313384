<template>
  <section class="media-buying media-buying-ad" :class="{ wavebg: loading }">
    <table-card
      ref="datatable"
      class="media-buying-ad__table campaign-table"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.mediaBuying.campaignsCount"
      :formaters="formaters"
      :actions="actions"
      :header="$t('mediaBuying.campaigns.adCampaigns')"
      tablet-layout
      is-static
      extend-height
      dont-fill-empty
      custom-context
      placementTooltip="bottom"
      i18n-path="mediaBuying.channels"
      @fetch-data="fetchDataTable"
      @checkedRows="handleCheckedRows"
    >
      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-desktop-no-wrap',
          'ui-mobile-wrap',
          'ui-mobile-12',
        ]"
      >
        <table-card-search-header
          v-model="search"
          class="ui-g-md"
          @search="handleSearch"
        ></table-card-search-header>

        <v-select
          :id="`${$randomizer()}status`"
          v-model="status"
          :options="statusFilters"
          class="ui-select2 ui-align-center ui-mobile-12 ui-g-md"
          style="width: 200px"
          @change="selectStatusCampaign"
          :settings="statusFiltersSettings"
        />
        <div
          class="checkbox payment-history__checkbox ui-mobile-12 ui-g-md"
        >
          <input
            v-model="needs_attention_first"
            id="checkbox1"
            type="checkbox"
            @change="fetchDataAction"
          >
          <label
            class="checkbox-label checkbox-label__action-required"
            for="checkbox1"
          >
            {{ $t('mediaBuying.campaign.action_required') }}
          </label>
        </div>
      </div>

      <div slot="actionsPrepend" class="ui-g-md ui-mobile-12">
        <button
          :class="[
            'btn',
            'btn-primary',
            'btn-sm',
            'text-nowrap',
            'ui-mobile-12',
          ]"
          @click="addCampaign"
        >
          +&nbsp;{{ $t('mediaBuying.campaigns.add_campaign') }}
        </button>
      </div>
    </table-card>

    <edit-campaign
      ref="editCampaign"
      id="adversitingCampaign"
      @addCampaign="refresh"
    />
  </section>
</template>

<script>
import formattedCurrency from 'src/helpers/formattedCurrency.js';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import Formatters from 'src/views/Dashboard/CustomReports/formaters';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import EditCampaign from 'src/views/Dashboard/MediaBuying/Modal/EditCampaign';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';

const defaultParams = {
  page: 1,
  pageSize: 20,
  search: '',
  sort_column: 'created_at',
  sort_dir: 'desc',
  status: 'active',
  root_campaign_id: 'null',
  needs_attention_first: true,
};

const fetch = async (ctx, params) => {
  if (!params || ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const campaignsParams = {
    limit: defaultParams.pageSize,
    offset: 0,
    ...params,
    root_campaign_id: params.root_campaign_id || defaultParams.root_campaign_id,
    sort_column: params.sort_column || defaultParams.sort_column,
    sort_dir: params.sort_dir || defaultParams.sort_dir,
    status: params.status,
    needs_attention_first: params.needs_attention_first !== undefined ? params.needs_attention_first : defaultParams.needs_attention_first,

  };
  campaignsParams.sort_column = campaignsParams.sort_column !== 'channel_status'
    ? campaignsParams.sort_column
    : 'status';

  const campaigns = await ctx.$store.dispatch('getCampaigns', campaignsParams);
  return [campaigns];
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  name: 'Campaigns',
  fetch,
  components: {
    EditCampaign,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
    TableCardSearchHeader,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    HelpersMixin,
    pageSizeMixin,
    tableFlowMixin,
  ],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      status: 'active',
      statusFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },
      columns: [
        {
          name: 'incrementing_id',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          width: 60,
        },
        {
          name: 'channel_status',
          align: 'center',
          filterable: true,
          width: 90,
          renderValue: v => `<span class="${v === 'active' ? 'green' : 'red'}">
              ${this.$t(`mediaBuying.channels.camp_status.${v}`)}
            </span>`,
        },
        {
          name: 'campaign_name',
          align: 'left',
          filterable: true,
          minWidth: 200,
          customTooltip: true,
          renderValue: v => `<span class="name-bubble ffff d-flex justify-content-between">
                              <span class="dark-green">${v.text}</span>
                            </span>`,
        },
        {
          name: 'balance_sum_usd_approved',
          align: 'right',
          filterable: true,
          sortable: 'custom',
          width: 130,
          renderValue: v => `$${formattedCurrency(v)}`,
        },
        {
          name: 'balance_sum_usd_total',
          align: 'right',
          filterable: true,
          sortable: 'custom',
          width: 130,
          renderValue: v => `$${formattedCurrency(v)}`,
        },
        {
          name: 'paid_sum_usd_approved',
          align: 'right',
          filterable: true,
          sortable: 'custom',
          width: 130,
          renderValue: (v, row) => `$${formattedCurrency(v)}`,
        },
        {
          name: 'paid_sum_usd_pending',
          align: 'right',
          filterable: true,
          sortable: 'custom',
          width: 130,
          renderValue: (v, row) => `$${formattedCurrency(v)}`,
        },
        {
          name: 'spent_sum_usd_approved',
          align: 'right',
          filterable: true,
          sortable: 'custom',
          width: 130,
          renderValue: (v, row) => `$${formattedCurrency(v)}`,
        },
        {
          name: 'spent_sum_usd_pending',
          align: 'right',
          filterable: true,
          sortable: 'custom',
          width: 130,
          renderValue: (v, row) => `$${formattedCurrency(v)}`,
        },
        {
          name: 'registrations_count',
          align: 'right',
          filterable: true,
          // sortable: 'custom',
          width: 110,
        },
        {
          name: 'first_deposits_count',
          align: 'right',
          filterable: true,
          // sortable: 'custom',
          width: 110,
        },
        {
          name: 'deposits_sum_usd',
          align: 'right',
          filterable: true,
          // sortable: 'custom',
          // format: 'ncurrency',
          width: 110,
          renderValue: (v, row) => `$${formattedCurrency(v)}`,
        },
        {
          name: 'profit_sum_usd',
          align: 'right',
          filterable: true,
          // sortable: 'custom',
          // format: 'ncurrency',
          width: 110,
          renderValue: this.formattedCurrencyColor,
        },
        {
          name: 'cost_per_player_usd',
          align: 'right',
          filterable: true,
          // sortable: 'custom',
          width: 110,
          renderValue: (v, row) => `$${formattedCurrency(v)}`,
        },
        {
          name: 'created_at',
          align: 'left',
          filterable: true,
          sortable: 'custom',
          format: 'date-time',
          width: 150,
        },
        {
          name: 'updated_at',
          align: 'left',
          filterable: true,
          sortable: 'custom',
          format: 'date-time',
          width: 150,
        },
      ],
      actions: {
        fixed: 'right',
        width: 80,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'show',
            component: (row, ctx) => {
              const { handleCheckedRows } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      handleCheckedRows(null, null, row);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-eye text-green',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green show-channel',
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.show'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
          {
            name: 'edit',
            component: (row, ctx) => {
              const { editCampaign } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.stopPropagation();
                      editCampaign(row);
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );

              return row.status === 'active' && elTooltip;
            },
          },
          {
            name: 'archive',
            component: (row, ctx) => {
              const { editStatusCampaign } = this;
              const disabled = false;
              // const disabled =
              //   row.balance_sum > 0 ||
              //   row.payments_on_review_sum > 0 ||
              //   row.payments_not_finished_count > 0 ||
              //   row.posts_not_finished_count > 0;

              const span = ctx.$createElement(
                'span',
                {
                  attrs: {
                    disabled,
                  },
                  on: {
                    click(e) {
                      e.stopPropagation();
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-archive',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-red',
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.archive'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              const elPopover = ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 250,
                    message: this.$t('mediaBuying.campaigns.archive_message'),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('mediaBuying.channels.archive'),
                        type: 'danger',
                        click: () => editStatusCampaign(row),
                      },
                    ],
                    disabled,
                  },
                },
                [elTooltip],
              );
              return row.status === 'active' && elPopover;
            },
          },
          {
            name: 'active',
            component: (row, ctx) => {
              const { editStatusCampaign } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.stopPropagation();
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-history',
                    },
                  }),
                ],
              );
              const elTooltip = ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: `el-tooltip icon__wrapper text-green ${
                      // this.statusChannel ? 'disabled' : ''
                      ''
                    }`,
                    placement: 'top',
                    content: this.$t('mediaBuying.channels.restore'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
              const elPopover = ctx.$createElement(
                'ac-popover',
                {
                  attrs: {
                    width: 270,
                    message: this.$t('mediaBuying.campaigns.restore_message'),
                    buttons: [
                      {
                        text: this.$t('mediaBuying.channels.cancel'),
                      },
                      {
                        text: this.$t('mediaBuying.channels.restore'),
                        type: 'success',
                        click: () => editStatusCampaign(row),
                      },
                    ],
                  },
                },
                [elTooltip],
              );
              return row.status === 'archived' && elPopover;
            },
          },
        ],
      },
      loading: false,
      formaters: Formatters,
      channel_id: '',
      listener: '',
    };
  },
  created() {
    this.setFromQuery(this.passedParams);
    this.actions.operations.map((e) => {
      if (e.component) e.component = e.component.bind(this);
    });
    this.$store.dispatch('getCurrencies', {});
  },
  activated() {
    this.clearTooltip();
  },
  mounted() {
    this.channel_id = this.$route.params.id;
    this.clearTooltip();
  },
  computed: {
    rows() {
      return this.$store.state.mediaBuying.campaigns.map(p => ({
        ...p,
        channel_status: p.status,
        campaign_name: {
          text: p.name,
          count: p.needs_attention_total,
        },
        balance_sum: this.currencyFormatter(
          p.balance_sum_usd_total,
          'usd',
        ),
        spent_sum: this.currencyFormatter(
          p.spent_sum_usd_total,
          'usd',
        ),
        paid_sum: this.currencyFormatter(p.paid_sum_usd_total, 'usd'),
      }));
    },
    statusChannel() {
      return this.$store.state.mediaBuying.channel.status !== 'active';
    },
    statusFilters() {
      return [
        {
          id: '',
          text: this.$t('mediaBuying.campaigns.all_campaigns'),
        },
        {
          id: 'active',
          text: this.$t('mediaBuying.campaigns.active_campaigns'),
        },
        {
          id: 'archived',
          text: this.$t('mediaBuying.campaigns.archived_campaigns'),
        },
      ];
    },
  },
  methods: {
    formattedCurrencyColor(v) {
      const amount = v;
      let sum = this.$options.filters.currency(amount, '$', null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });
      sum = sum.includes('NaN') ? amount : sum;

      if (amount < 0) {
        return `<span class="text-red">${sum}</span>`;
      }

      if (amount > 0) {
        return `<span class="text-green">${sum}</span>`;
      }

      return `<span>${sum}</span>`;
    },

    fetchDataAction() {
      this.fetchData({
        ...this.offsetParams,
        ...this.sorting,
        needs_attention_first: this.needs_attention_first,
        status: this.status,
        search: this.search,
      });
    },

    addCampaign() {
      this.$refs.editCampaign.open({ edited: false });
    },
    editCampaign(row) {
      this.$refs.editCampaign.open({
        ...row,
        name: row.name,
        edited: true,
      });
    },
    selectStatusCampaign(v) {
      this.resetPager();
      v === 'all' ? (v = '') : null;
      this.fetchData({
        ...this.offsetParams,
        status: v,
        search: this.search,
      });
    },
    editStatusCampaign(row) {
      if (row.status === 'active') {
        this.$store
          .dispatch('postCampaignArchive', row.id)
          .then(() => {
            this.fetchData({
              ...this.offsetParams,
              status: this.status,
              search: this.search,
            });
            this.$alert.success(
              this.$t('mediaBuying.campaigns.archive_success_message'),
            );
          })
          .catch(e => {
            const { message } = e.response.data;

            if (message) {
              this.$alert.error(message);
            }
          });
      } else {
        this.$store.dispatch('postCampaignActivate', row.id).then(() => {
          this.fetchData({
            ...this.offsetParams,
            status: this.status,
            search: this.search,
          });
          this.$alert.success(
            this.$t('mediaBuying.campaigns.restore_success_message'),
          );
        });
      }
    },
    handleCheckedRows(id, ids, row) {
      localStorage.setItem(
        'getBackUrlCampaign',
        JSON.stringify(this.$route.path),
      );
      this.$router.push(
        `/dashboard/media-buying-campaigns/${row.id}/${this.compressUrlQuery({
          ...row,
          campaign_id: row.id,
          status: '',
        })}`,
      );
      document.removeEventListener('mouseup', this.listener);
    },
    back() {
      this.$router.push(JSON.parse(localStorage.getItem('getBackUrlChannel')));
      document.removeEventListener('mouseup', this.listener);
    },
    clearTooltip() {
      document.addEventListener(
        'mouseup',
        (this.listener = () => {
          const tooltip = document.querySelectorAll('.el-tooltip__popper');
          tooltip.forEach((e, i) => {
            tooltip[i].style.display = 'none';
          });
        }),
      );
    },
    currencyFormatter(amount, code) {
      amount = amount.toFixed(2);
      const amountData = amount.split('.');
      const result = (+amountData[0]).toLocaleString();

      const miscCurrencies = this.$store.state.misc.currencies;
      const currency = miscCurrencies.find(
        c => (code ? code.toLowerCase() : null) === c.code?.toLowerCase(),
      );

      if (!code) {
        return `${result}.${amountData[1]}`;
      }

      if (!currency) {
        return `${result}.${amountData[1]}`;
      }

      return `${currency.code.toUpperCase()} ${result}.${amountData[1]}`;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/mediabuying/mediabuying';

.media-buying-ad {
  &__table {
    .table-card__header {
      @media (min-width: 1050px) and (max-width: 1455px) {
        .ui-tablet-6 {
          flex-grow: 0.5;
          width: 50% !important;
        }

        .ui-tablet-12 {
          flex-grow: 1;
          width: 100% !important;
        }

        .ui-tablet-wrap {
          flex-wrap: wrap !important;
        }
      }
    }

    @media (max-width: 1455px) {
      .table-card__header-right {
        flex-grow: 0;
        flex-shrink: 1;
      }

      .table-card__header-left {
        flex-grow: 1;
        width: 100% !important;
      }
    }
  }
}
</style>
