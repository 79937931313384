<template>
  <section class="index-dashboard">
    <div class="row justify-content-center">
      <div class="card-wrap">
        <div class="card card-info">
          <h5 class="head text-success">
            {{ $t('dashboard.available') }}
          </h5>
          <h4 class v-html="available" />
        </div>
      </div>
      <div class="card-wrap">
        <div class="card card-info">
          <h5 class="head text-info">
            {{ $t('dashboard.yesterday') }}
          </h5>
          <h4 class v-html="yesterday" />
        </div>
      </div>
      <div class="card-wrap">
        <div class="card card-info">
          <h5 class="head text-info">
            {{ $t('dashboard.currentMonth') }}
          </h5>
          <h4 class v-html="thisMonth" />
        </div>
      </div>
      <div class="card-wrap">
        <div class="card card-info">
          <h5 class="head text-info">
            {{ $t('dashboard.lastMonth') }}
          </h5>
          <h4 class v-html="lastMonth" />
        </div>
      </div>
      <div class="card-wrap">
        <div class="card card-info">
          <h5 class="head text-black">
            {{ $t('dashboard.total') }}
          </h5>
          <h4 class v-html="total" />
        </div>
      </div>
    </div>

    <charts
      ref="charts"
      class="mt-20"
      @chartPeriod="cacheChart"
    />

    <table-card
      class="mt-20"
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :totals="$store.state.report.totals"
      :count="$store.state.report.dashboardRows"
      :formaters="formaters"
      :show-total="true"
      period-picker
      :period.sync="period"
      :header="$t('dashboard.dailyStatistics')"
      i18n-path="reports"
      is-static
      extend-height
      dont-fill-empty
      @update:period="period = $event"
      @change-range="fetchDataTable"
      @fetch-data="fetchDataTable"
    ></table-card>
  </section>
</template>

<script>
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper';
import Charts from 'src/components/Charts/Charts.vue';
import Formatters from 'src/views/Dashboard/CustomReports/formaters';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import TableCard from 'src/components/Cards/TableCard.vue';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator, { getDefaultPeriod } from 'src/views/Dashboard/Reports/tableFlowMixin.js';

const sizes = {
  1080: 10,
  1440: 10,
};

const viewName = '/dashboard';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});
const dashboardColumns = [
  'impressions_count',
  'visits_count',
  'registrations_count',
  'deposits_first_count',
  'deposits_all_count',
  'deposits_all_sum',
  'net_profit_sum',
  'revenue_sum',
];

const defaultPeriod = getDefaultPeriod();

const defaultParams = {
  page: 1,
  pageSize,
  sort_by: 'date_formatted',
  sort_dir: 'desc',
  chartPeriod: '',
  miniChartPeriod: '',
};

async function fetch(ctx, params = {}) {
  if (localStorage.getItem('auth-token') !== 'null') {
    const sorting = params.sort_by
      ? {
        sort_by: params.sort_by,
        sort_dir: params.sort_dir,
      }
      : ctx.sorting
        ? ctx.sorting
        : {
          sort_by: defaultParams.sort_by,
          sort_dir: defaultParams.sort_dir,
        };

    const requestParms = {
      metrics_format:	'pretty',
      from: ctx.period
        ? ctx.period.from
        : params.period
          ? params.period.from
          : defaultPeriod.from,
      to: ctx.period
        ? ctx.period.to
        : params.period
          ? params.period.to
          : defaultPeriod.to,
      sorting: [sorting],
      metrics: dashboardColumns,
      limit: pageSize,
      offset: 0,
      ...params,
    };


    const earnings = () => ctx.$store.dispatch('getDashboardEarnings');
    const summary = () => ctx.$store.dispatch('getReportDashboardSummary', requestParms);

    const data = await Promise.all([summary(), earnings()]);

    return data[0];
  }
}

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  requestOmit: ['chartPeriod', 'miniChartPeriod'],
  fetch,
  period: 'param',
  windowRefreshHook: true,
});


export default {
  components: {
    PeriodWrapper,
    Charts,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    DimensionsMixin,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
  ],
  fetch,
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      dashboardColumns,
      loading: false,
      pagination: true,
      count: 0,
      formaters: Formatters,
      chartPeriod: '',
      miniChartPeriod: '',
      lastQuery: {},
      unwatch: Function,
      columns: [
        {
          name: 'date_formatted',
          source: 'dimensions',
          filterable: false,
          sortable: 'custom',
        }, {
          name: 'impressions_count',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'visits_count',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'registrations_count',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'deposits_first_count',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'deposits_all_count',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'deposits_all_sum',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'net_profit_sum',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        }, {
          name: 'revenue_sum',
          source: 'metrics',
          filterable: false,
          sortable: 'custom',
          align: 'right',
        },
      ],
    };
  },
  computed: {
    available() {
      if (this.$store.state.payments) {
        return this.formatPrice(this.$store.state.payments.availableBalance);
      }
    },
    yesterday() {
      if (this.$store.state.report.earnings) {
        return this.formatPrice(this.$store.state.report.earnings.yesterday);
      }
    },
    thisMonth() {
      if (this.$store.state.report.earnings) {
        return this.formatPrice(this.$store.state.report.earnings.this_month);
      }
    },
    lastMonth() {
      if (this.$store.state.report.earnings) {
        return this.formatPrice(this.$store.state.report.earnings.last_month);
      }
    },
    total() {
      if (this.$store.state.report.earnings) {
        return this.formatPrice(this.$store.state.report.earnings.total);
      }
    },
    rows() {
      return this.$store.state.report.dashboard_reports
        .map(e => this.$_.omit(e, [
          'row_id',
          'row_filter_column',
          'row_filter_column',
        ]));
    },
    firstDay() {
      return this.$store.state.profile.user.first_day_of_week;
    },
  },
  watch: {
    '$i18n.locale': async function () {
      if (this.isComponentActivated) {
        const promise = Promise.all([
          this.fetchData(this.$_.cloneDeep(this.currentQuery)),
          this.$store.dispatch('getSettings'),
        ]);

        await promise;
        await this.afterBrowserRedraw();
        this.loading = false;
      }
    },
  },
  methods: {
    async refresh() {
      this.loading = true;
      await this.fetchData(this.$_.cloneDeep(this.currentQuery));
      this.$refs.charts.$emit('refresh');
      await this.afterBrowserRedraw();
      this.loading = false;
    },
    cacheChart(params) {
      if (params.type === 'normal') {
        this.chartPeriod = params.period;
        this.pushQuery(this.$route, this.cacheData);
      } else {
        this.miniChartPeriod = params.period;
        this.pushQuery(this.$route, this.cacheData);
      }
    },
    formatPrice(amount) {
      const sum = this.$options.filters.currency(amount, '$', null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });

      if (amount < 0 && Math.abs(amount) >= 0.005) {
        return `<span class="text-red">${sum}</span>`;
      }

      if (amount > 0 && Math.abs(amount) >= 0.005) {
        return `<span class="text-green">${sum}</span>`;
      }

      return `<span>${sum.replace('-', '')}</span>`;
    },
  },
  created() {
    this.setFromQuery(this.passedParams);
  },
  activated() {
    this.fetchData(null, this.lastQuery);
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/dashboard/index';
</style>
