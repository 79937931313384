import JQuery from 'jquery/dist/jquery';
import select2 from 'select2/dist/js/select2.full';
import Vue from 'vue';
import moment from 'moment';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import languagePlugin from 'src/i18n/plugin';
import ElementLocale from 'element-ui/lib/locale';
import VueIntercom from 'vue-intercom';
import TextareaAutosize from 'vue-textarea-autosize';
import VueCurrencyInput from 'vue-currency-input';
import packageJson from '../package.json';
import config from './config';
import App from './App.vue';
import MainView from './views/Main';
import Request from './lib/request';
import routes from './routes';
import store from './store';
import translations from './i18n';
import Errors from './lib/errors';
import eventBus from './lib/eventBus';
import routeEvents from './lib/events';
import filters from './lib/filters';
import './assets/sass/element_variables.scss';
import 'intl-tel-input/build/css/intlTelInput.css';
import InjectUi from 'src/components/Report/Components/inject-ui';
import { fetchMiscSettings } from './helpers/fetchMiscSettings';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import randomizer from './helpers/randomizer';
import localeBN from 'src/i18n/messages/bn/locale_bn.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
/* заменены цифры на стандартные арабские, для каректной отправки на сервер */
moment.defineLocale('bn', localeBN);

const appmount = async () => {
  window.intercomSettings = config.intercomSettings;
  window.jQuery = JQuery;
  window.$ = JQuery;
  window.select2 = select2;
  window.Vue = Vue;

  window.moment = moment;

  Vue.config.productionTip = true;
  Vue.prototype.$config = config;
  Vue.prototype.$appVersion = packageJson.version;
  window.export_translations = () => {
    const text = JSON.stringify(translations, null, 4);
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`,
    );
    element.setAttribute('download', 'translations.json.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  window.addEventListener('keydown', (ev) => {
    if (ev.code === 'F5' && location.hash.includes('/dashboard')) {
      ev.preventDefault();
      eventBus.$emit('window-refresh');
    }
  });
  Vue.use(languagePlugin, config);
  Vue.use(Vuelidate);
  Vue.use(Errors);
  Vue.use(VueI18n);
  Vue.use(VueRouter);
  Vue.use(filters);
  Vue.use(VueIntercom, { app_id: 'gijawcr0' });
  Vue.use(TextareaAutosize);
  Vue.use(VueCurrencyInput);
  Vue.use(InjectUi);

  const i18n = new VueI18n(translations);
  ElementLocale.i18n((key, value) => i18n.t(key, value));
  Object.defineProperty(Vue.prototype, '$locale', {
    get() {
      return i18n.locale;
    },
    set(locale) {
      i18n.locale = locale;
    },
  });
  Object.defineProperty(Vue.prototype, '$randomizer', {
    get() {
      return randomizer;
    },
  });

  const router = new VueRouter({
    routes,
    linkActiveClass: 'active',
  });

  Vue.use(Request, {
    baseURL: config.api.baseURL,
    miscURL: config.api.miscURL,
    store,
    eventBus,
    router,
    errors: Vue.prototype.$errors,
    environment: config.environment,
  });

  try {
    const {
      payload: {
        recaptcha: { public_key },
      },
    } = await fetchMiscSettings();
    Vue.use(VueReCaptcha, {
      siteKey: public_key,
      loaderOptions: {
        autoHideBadge: true,
      },
    });
  } catch (error) {
    console.error(error);
  }

  Vue.use(MainView);

  const app = new Vue({
    // el: '#app',
    render: h => h(App),
    i18n,
    router,
    store,
  });

  const fpPromise = FingerprintJS.load({ monitoring: false });

  try {
    const fp = await fpPromise;
    const result = await fp.get();
    window.AffCtrlUserId = result && result.visitorId ? result.visitorId : '';
  } catch (err) {
    console.log(err);
  }

  store.$api.affiliates.defaults.headers.common['X-User-Id'] = window.AffCtrlUserId || '';
  store.$api.misc.defaults.headers.common['X-User-Id'] = window.AffCtrlUserId || '';

  store.$app = app;

  routeEvents(app, router);

  if (config.environment === 'test') {
    const Test = require('./test');
    Vue.use(Test.default);
  }

  app.$mount('#app');
};

appmount();
