export default {
  saveDashboardEarnings(state, payload) {
    state.earnings = payload;
  },

  saveReportSummary(state, payload) {
    state.summary_cols = state.SUMMARY_COLS;
    state.summary = payload.payload.data;
    state.summary_totals = payload.payload.totals;
    state.rows_before_limit_at_least = payload.payload.rows_before_limit_at_least;
    state.summary_totals.responseURL = payload.misc.export_urls;
    state.loading = false;
  },

  saveReportSummaryMb(state, payload) {
    state.summary_cols = state.SUMMARY_COLS;
    state.summary_totals = payload.payload;
    state.loading = false;
  },

  saveReportDashboardSummary(state, payload) {
    state.totals = payload.payload.totals;
    state.dashboard_reports = payload.payload.data;
    state.dashboardRows = payload.misc.count;
  },

  saveStatistics(state, payload) {
    state.chart = payload.data;
    state.loadingmain = false;
    state.dataerrmain = true;
  },

  saveStatisticsMini(state, payload) {
    state.chartmini = payload.data;
    state.loadingmini = false;
    state.dataerr = true;
  },

  // START report options
  saveReportOptionsTrafficSources(state, payload) {
    if (payload.tab === 'my_traffic') {
      state.filters[payload.view].options_traffic.traffic_sources = payload.data;
    } else {
      state.filters[payload.view].options_subaffiliate.traffic_sources = payload.data;
    }
  },
  saveReportOptionsCountries(state, payload) {
    if (payload.tab === 'my_traffic') {
      state.filters[payload.view].options_traffic.countries = payload.data;
    } else {
      state.filters[payload.view].options_subaffiliate.countries = payload.data;
    }
  },
  saveReportOptionsAffiliateOffers(state, payload) {
    if (payload.tab === 'my_traffic') {
      state.filters[payload.view].options_traffic.offers = payload.data;
    } else {
      state.filters[payload.view].options_subaffiliate.offers = payload.data;
    }
  },
  saveReportOptionsPlayers(state, payload) {
    if (payload.tab === 'my_traffic') {
      state.filters[payload.view].options_traffic.players = payload.data;
    } else {
      state.filters[payload.view].options_subaffiliate.players = payload.data;
    }
  },
  saveReportSubs(state, payload) {
    if (payload.tab === 'my_traffic') {
      state.filters[payload.view].options_traffic[`subids${payload.n}`] = payload.data;
    } else {
      state.filters[payload.view].options_subaffiliate[`subids${payload.n}`] = payload.data;
    }
  },
  savePlayerReportSubs(state, payload) {
    state.filters.players[`subids${payload.n}`] = payload.data;
  },
  // END report options

  saveReportSupportedDimmentions(state, payload) {
    state.supported_dimentions = payload;
  },

  saveReportSupportedMetrics(state, payload) {
    state.supported_metrics = payload;
  },

  promocodeTitle(state, payload) {
    state.promocode_title = payload;
  },
};
