import apiUrls from './api-urls';
import { getPageTitle, getHomepageDomain, getLogo, getTheme } from './utils';

const customerId = process.env.VUE_APP_CUSTOMER_ID || 'demo';
const environmentName = process.env.VUE_APP_ENVIRONMENT_NAME || 'test';
const selectedTheme = process.env.VUE_APP_THEME_OVERRIDE || customerId;

if (typeof apiUrls[environmentName] === 'undefined') {
  throw new Error(`Environment ${environmentName} doesnt have defined API URL`);
}

const apiBaseUrl = apiUrls[environmentName];

export default {
  environment: 'dev',
  theme: getTheme(selectedTheme),
  mainScreen: '/dashboard/main',
  pageTitle: getPageTitle(selectedTheme),
  api: {
    baseURL: `${apiBaseUrl}/affiliates`,
    miscURL: `${apiBaseUrl}/misc`,
  },
  customerId,
  frontPageUrl: getHomepageDomain(selectedTheme),
  enabledLanguages: ['de', 'en', 'es', 'fr', 'tr', 'he', 'ru', 'pt', 'bn'],
  intercomSettings: {
    app_id: 'gijawcr0',
    hide_default_launcher: true,
    alignment: 'right',
  },
  layout: {
    logoFull: getLogo(selectedTheme),
    logoMini: 'src/assets/logo-mini.svg',
    logoutIcon: '@/assets/theme/logout-icon.svg',
  },
  sidebar: {
    theme: 'betwinner',
    menus: [
      {
        title: 'sidebar.menu.main.title',
        links: [
          {
            path: '/dashboard/main',
            icon: 'tachometer-alt',
            title: 'sidebar.menu.main.dashboard',
          },
          {
            path: '/dashboard/profile',
            icon: 'user',
            title: 'sidebar.menu.main.profile',
          },
          {
            path: '/dashboard/payment-information',
            icon: 'money-bill-wave',
            title: 'sidebar.menu.main.paymentInformation',
          },
          {
            path: '/dashboard/sub-affiliates',
            name: 'routes.subAffiliates',
            icon: 'users',
            title: 'sidebar.menu.main.subAffiliates',
          },
        ],
      },
      {
        title: 'sidebar.menu.marketingTools.title',
        links: [
          {
            path: '/dashboard/traffic-source',
            icon: 'link',
            title: 'sidebar.menu.marketingTools.trafficSource',
          },
          {
            path: '/dashboard/offers',
            icon: 'tags',
            title: 'sidebar.menu.marketingTools.offers',
          },
          {
            path: '/dashboard/domains',
            icon: 'globe',
            name: 'routes.domain',
            title: 'sidebar.menu.marketingTools.dedicatedDomains',
          },
          {
            path: '/dashboard/promo-codes',
            name: 'routes.promoCodes',
            icon: 'badge-percent',
            title: 'sidebar.menu.marketingTools.promoCodes',
          },
          {
            path: '/dashboard/android-apps',
            name: 'routes.androidApps',
            img: require('@/assets/img/android.svg'),
            title: 'sidebar.menu.marketingTools.androidApps',
          },
          {
            path: '/dashboard/promo-materials',
            name: 'routes.promoMaterials',
            icon: 'photo-video',
            title: 'sidebar.menu.marketingTools.tools',
          },
        ],
      },
      {
        title: 'sidebar.menu.marketingTools.mediaBuying',
        links: [
          {
            path: '/dashboard/media-buying-campaigns',
            name: 'routes.mediaBuying',
            icon: 'ad',
            title: 'sidebar.menu.marketingTools.mediaBuying_campaigns',
          },
          {
            path: '/dashboard/media-buying-payments',
            name: 'routes.mediaPayments',
            icon: 'badge-dollar',
            title: 'sidebar.menu.marketingTools.mediaBuying_payments',
          },
          {
            path: '/dashboard/media-buying-posts',
            name: 'routes.mediaPosts',
            icon: 'images',
            title: 'sidebar.menu.marketingTools.mediaBuying_posts',
          },
        ],
      },
      {
        title: 'sidebar.menu.reports.title',
        links: [
          {
            path: '/dashboard/summary',
            icon: 'chart-bar',
            name: 'routes.summary',
            title: 'sidebar.menu.reports.summary',
          },
          {
            path: '/dashboard/daily',
            icon: 'calendar-alt',
            name: 'routes.daily',
            title: 'sidebar.menu.reports.daily',
          },
          {
            path: '/dashboard/players',
            icon: 'user-tie',
            name: 'routes.players',
            title: 'sidebar.menu.reports.players',
          },
          {
            path: '/dashboard/custom',
            icon: 'sliders-h',
            name: 'routes.custom',
            title: 'sidebar.menu.reports.custom',
          },
          {
            path: '/dashboard/sub-affiliate-reports',
            icon: 'users',
            name: 'routes.subAffiliatesReports',
            title: 'sidebar.menu.reports.subAffiliates',
          },
        ],
      },
      {
        title: 'sidebar.menu.help.title',
        links: [
          {
            path: false,
            icon: 'tickets-icon.svg',
            title: 'sidebar.menu.help.tickets',
          },
          {
            path: false,
            icon: 'livechat-icon.svg',
            title: 'sidebar.menu.help.livechat',
          },
        ],
      },
    ],
  },
};
