export default {
  computed: {
    metricsHash() {
      return this.getMetrics(this.$store.state.settings.metrics);
    },
    dimensionsHash() {
      return this.getMetrics(this.$store.state.settings.dimensions);
    },
  },
  methods: {
    processColumns(columns) {
      return columns.map((column) => {
        switch (column.source) {
          case 'dimensions':
            return {
              ...this.dimensionsHash[column.name],
              ...column,
            };
          case 'metrics':
            return {
              ...this.metricsHash[column.name],
              ...column,
            };
          case 'i18n':
            const i18nPath = column.i18nPath || 'reports';
            const i18n = column.i18n || `${i18nPath}.fields.${column.name}.title`;

            return {
              ...column,
              title: this.$t(i18n),
            };
          default:
            return column;
        }
      });
    },
    getMetrics(source, field = 'column_name') {
      const result = {};
      source = source || [];

      source.forEach((item) => {
        result[item[field]] = item;
      });

      return result;
    },
  },
};
