<template>
  <span style="display: inline-block;" class="">

    <button class="btn btn-sm btn-outline-primary btn-req" @click="requestPayment(requestPermission)">
      <img :src="require('@/assets/theme/sidebar/payment-history.svg')" alt="i" height="14" style="filter: invert(1)">
      {{ $t('paymentHistory.requestPayment') }}
    </button>

    <modal
      :show.sync="active"
      :class="{ 'loading': loadingState }"
      class="request-payment"
      @close="close()"
    >
      <h5 slot="header" class="modal-title">
        {{ $t('paymentHistory.requestPayment') }}
      </h5>
      <section class="request__body">
        <div class="tip d-flex mb-4">
          {{ $t('paymentHistory.specifySum') }}
          <span class="text-danger">*</span>
        </div>
        <div :class="{'is-disabled':loading}" class="money form-group m-0">
          <div class="input-group-append">
            <span>$</span>
          </div>
          <input
            v-model="form.amount"
            placeholder="30.00"
            autocomplete="off"
            :disabled="loading"
            type="text"
            class="form-control m-0 w-100"
            @click="errors_.amount = ''"
          />
        </div>
        <div class="text-danger text-right">{{ errors_.amount }}</div>

        <div v-if="success && requestPermission" class="success__block">
          <div>
            <img :src="require('@/assets/theme/icon/ok.svg')" alt>
          </div>
          <span>{{ $t('paymentHistory.withdrawalReview') }}</span>
        </div>

        <div v-if="!requestPermission && !success" class="success__block">
          <div>
            <i v-if="errors_.amount" class="far fa-times-circle"></i>
            <img v-if="!errors_.amount" :src="require('@/assets/theme/icon/ok.svg')" alt>
          </div>
          <span>{{ errors_.amount || $t('paymentHistory.withdrawalRequest') }}</span>
        </div>
      </section>

      <div class="loading__block">
        <i class="fa fa-spinner login__spinner" />
      </div>

      <div slot="footer" class="text-center w-100">
        <button
          v-if="!success && requestPermission"
          slot="reference"
          :disabled="loading"
          class="btn btn-primary btn-sm d-done w-100 btn-send"
          @click="sendRequest"
        >{{ $t('paymentHistory.sendRequest') }}</button>
        <button
          v-if="success || !requestPermission"
          class="btn btn-primary btn-block big login btn-md"
          @click="close()"
        >{{ $t('paymentHistory.close') }}</button>
      </div>
    </modal>

  </span>
</template>

<script>
import { Popover } from 'element-ui';
import { Modal } from 'src/components/index';
import PasswordInput from 'src/components/PasswordInput';

export default {
  components: {
    Modal,
    PasswordInput,
    [Popover.name]: Popover,
  },
  props: ['changeSuccess'],

  data() {
    return {
      active: false,
      loading: false,
      loadingState: false,
      success: false,
      form: {},
      errors_: {},
      poperVis: false,
      requestPermission: false,
      amount: '',
    };
  },
  watch: {
    active() {
      this.errors_ = {};
      this.form = {};
    },
    'form.amount': function (value) {
      if (!value || this.amount === value || value.trim() === '' || /^[0-9]+\.[0-9]{2}$/g.test(value)) {
        return;
      }

      value = value.replace(/((?![0-9,.]).)*/g, '').replace(/,/g, '.');

      if (value.includes('.')) {
        const strArray = value.split('.');
        value = `${(+strArray.slice(0, strArray.length - 1).join('')) || '0'}.${strArray.slice(strArray.length - 1)[0].slice(0, 2)}`;
      } else {
        value = `${+value}`;
      }

      this.amount = value;
      this.form = { ...this.form, amount: value };
    },
  },

  mounted() {
    this.$api.getPaymentsHistoryOrder({ status: 'waiting_approve' }).then((res) => {
      this.requestPermission = res.payload.able_to_set_payment_amount;
    });
  },

  methods: {
    requestPayment(v) {
      this.active = true;
      if (!v) {
        this.loadingState = true;
        this.$api
          .postPaymentsHistoryOrderPayment({})
          .then((res) => {
            this.loadingState = false;
          })
          .catch((err) => {
            this.errors_ = {
              amount: err.response.data.errors?.amount[0].message || err.response.data.message,
            };
            this.loadingState = false;
          });
      }
    },

    close() {
      this.$emit('update:changeSuccess', (this.success = false));
      this.active = false;
      this.success = false;
      this.loadingState = false;
    },

    sendRequest() {
      this.errors_ = {};
      this.loading = true;
      this.$api
        .postPaymentsHistoryOrderPayment({ amount: this.form.amount })
        .then(() => {
          this.loading = false;
          this.success = true;

          const { period, sort_column, sort_dir, pageSize } = this.$parent

          this.$store.dispatch('getWalletHistoryLazy', {
            limit: pageSize,
            start_timestamp: period.from,
            end_timestamp: period.to,
            offset: 0,
            sort_dir: sort_dir,
            sort_column: sort_column,
          });

          this.$parent.getWaintingApproval(this.success);
        })
        .catch((err) => {
          this.loading = false;
          this.errors_ = {
            amount: err.response.data.errors?.amount[0].message || err.response.data.message,
          };
        });
    },
  },
};
</script>

<style lang="scss">
.request-payment {
  .modal-dialog {
    width: 500px;
  }
  .request__body {
    padding-top: 24px;
    padding-bottom: 24px;
    .tip {
      font-weight: normal;
      font-size: 14px;
    }
    .input-group-append {
      padding-top: 4px;
      left: 12px;
      font-size: 18px;
      position: absolute;
    }
    .form-control {
      padding-left: 28px !important;
      margin-left: 0 !important;
    }
    .success__block {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: #fff;
      > div {
        padding-bottom: 24px;
      }
    }

    .text-danger {
      width: auto!important;
    }
  }
  .btn-send {
    width: auto !important;
    float: right;
  }
  .btn-done {
    width: auto;
    margin: 10px auto;
  }
}

.request-payment.loading{
  .modal-header, .request__body, .modal-footer{
    opacity: 0;
    *{
      cursor: unset !important;
      pointer-events: none !important;
    }
  }
  .login__spinner:before{
    font-size: 45px;
    top: 35%;
    left: 45%;
  }
}

</style>
