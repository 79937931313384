export default {
  saveGeoData(state, payload) {
    state.geoData = payload;
  },
  saveCountries(state, payload) {
    state.countries = payload;
  },
  savePaymentMethods(state, payload) {
    state.paymentMethods = payload;
  },
  saveTimezones(state, payload) {
    state.timezones = payload;
  },
  saveMessengers(state, payload) {
    state.messengers = payload;
  },
  saveLanguages(state, payload) {
    state.languages = payload;
  },
  saveTranslations(state, payload) {
    state.translations = payload;
  },
  saveTranslatedLanguageCodes(state, payload) {
    state.translatedlanguages = payload;
  },
  saveAvailableLanguages(state, payload) {
    state.availableLanguages = payload;
  },
  saveAvailableLanguagesPromise(state, payload) {
    state.availableLanguagesPromise = payload;
  },
  saveUrlQuery(state, params) {
    state.urlQueries[params.name] = params.data;
  },
  saveIntercomSettings(state, payload) {
    state.intercomSettings = payload;
  },
  saveTelegramBotSettings(state, payload) {
    state.telegramBotSettings = payload;
  },
  savePaymentMethodCount(state, payload) {
    state.paymentMethodCount = payload;
  },
  savePostPlatforms(state, payload) {
    state.postPlatforms = payload;
  },
  savePostPlatformsCount(state, payload) {
    state.postPlatformsCount = payload;
  },
  saveChannelIcons(state, payload) {
    state.channelIcons = payload;
  },
  saveCurrencies(state, payload) {
    state.currencies = payload;
  },
  saveCustomerCurrencies(state, payload) {
    state.customerCurrencies = payload;
  },
  saveTagTemplates(state, payload) {
    state.tagTemplates = payload;
  },
  saveMiscSettings(state, payload) {
    state.miscSettings = payload;
  },
  savePostbackTokens(state, payload) {
    state.postbackTokens = payload;
  },
  saveTagManagerTokens(state, payload) {
    state.tagManagerTokens = payload;
  },
};
