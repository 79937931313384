<template>
  <section
    class="player-report player-report__breakpoint"
    :class="{
      wavebg: loading,
      mediaSAid: mediaSAid
    }"
  >
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="getTableFields"
      :hide-columns="hiddenFields"
      :sort.sync="sorting"
      :page.sync="page"
      :page-size.sync="limit"
      :rows="data"
      :totals="totals"
      :count="count"
      i18n-path="reports"
      period-picker
      tablet-layout
      condenced
      show-total
      height-fix
      :period.sync="period"
      :col-filters="columnsFilter"
      @change-range="fetchDataTable"
      @filter="handleFilter"
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-mobile-12',
          'ui-mobile-wrap'
        ]"
      >
        <table-card-search-header
          :class="{
            'ui-g-md': true,
            'ui-mobile-12': true,
            'ui-mobile-hide': !swowInput,
            'ui-tablet-hide': true,
          }"
          v-model="search"
          @search="handleSearch"
        ></table-card-search-header>

        <filter-reports
          class="ui-g-md ui-mobile-12"
          :value="filters"
          @input="updateFilters"
          :from="period.from"
          :to="period.to"
          :use-filters="useFilters"
          @changeSAid="v => mediaSAid = v"
        />
      </div>

      <div
        class="ui-d-flex ui-mobile-hide"
        slot="actionsPrepend"
      >
        <export-report
          class="ui-g-md"
          :export-links="exportUrls"
        ></export-report>

        <div class="ui-g-md">
          <ui-button
            icon="eye"
            :icon-size="9"
            @click="openColumnsPopup"
          />
        </div>

        <div class="ui-g-md">
          <ui-button
            icon="refresh"
            @click="fetchDataTable"
          />
        </div>
      </div>

      <template slot="headerPeriodPickerActions">
        <table-card-search-header
          :class="[
            'ui-g-md',
            'ui-mobile-hide',
            'ui-desktop-only-hide'
          ]"
          v-model="search"
          @search="handleSearch"
        ></table-card-search-header>

        <div
          :class="[
            'ui-tablet-hide',
            'ui-desktop-hide',
            'ui-g-md',
          ]"
        >
          <ui-button
            icon="search"
            @click="swowInput = !swowInput"
          />
        </div>

        <export-report
          :class="[
            'ui-tablet-hide',
            'ui-desktop-hide',
            'ui-g-md'
          ]"
          :export-links="exportUrls"
        ></export-report>

        <div
          :class="[
            'ui-tablet-hide',
            'ui-desktop-hide',
            'ui-g-md'
          ]"
        >
          <ui-button
            icon="refresh"
            @click="fetchDataTable"
          />
        </div>
      </template>
    </table-card>

    <ui-columns-select
      ref="columnsPopup"
      v-model="selectedColumns"
      :groups="columns_groups"
      :default="default_cols"
      :supported="supportedMetrics"
      :metrics="getMetrics"
      @changed="fetchDataTable"
    />
  </section>
</template>

<script>
import { Select, Option } from 'element-ui';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import FilterReports from 'src/components/Filter-reports/Filter-reports.vue';
import SortBy from 'src/components/SortBy.vue';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import ColumnsMixin from 'src/views/Dashboard/Reports/columns-mixin';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import ExportReport from 'src/views/Dashboard/Reports/ExportReport.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'reports/players';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
let $tapReady = null;
const $readyPromise = new Promise((resolve) => {
  $tapReady = resolve;
});

const defaultParams = {
  page: 1,
  pageSize,
  sort_dir: 'desc',
  sort_by: 'site_player_id',
  sub_affiliate_id: null,
};

const fetch = async (ctx) => {
  const settings = () => ctx.$store.dispatch('getSettings');
  const subAffiliatesCount = () => ctx.$api.getSubAffiliatesCount({});
  const subAffiliates = () => ctx.$store.dispatch('getSubAffiliates', {
    limit: 10000,
    offset: 0,
  });

  await Promise.all([settings(), subAffiliatesCount(), subAffiliates()]);

  return [];
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  period: 'param',
  windowRefreshHook: true,
  export: true,
  fetchOnActivated: false,
});

export default {
  fetch,
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PeriodWrapper,
    VSelectMenu,
    Mobtable,
    FilterReports,
    SortBy,
    Mobpagination,
    TableCard,
    TableCardSearchHeader,
    ExportReport,
  },
  mixins: [
    InjectPlugins,
    ColumnsMixin,
    DimensionsMixin,
    UrlMixin,
    UrlMixinHooks,
    pageSizeMixin,
    HelpersMixin,
    tableFlowMixin,
  ],
  props: ['passedParams'],
  data() {
    return {
      viewName,
      default_cols: [],
      hiddenFields: [],
      sub_affiliate_id: null,
      sort_dir: 'desc',
      sort_by: 'site_player_id',
      search: '',
      oldFilter: '',
      group_by: 'site_player_id',
      period: {},
      count: 0,
      passedPeriod: {
        period: 30,
      },
      columnsFilter: {},
      data: [],
      loading: false,
      loadingOnStart: true,
      loadingError: false,
      subIdFiterOpen: false,
      totalRows: 0,
      query: null,
      multiSelectBlock: false,
      totals: {},
      formaters: Formaters,
      filters: null,
      useFilters: [
        'traffic_source_id', 'country_code', 'affiliate_offer_id', 'subid1', 'subid2', 'subid3', 'subid4', 'subid5',
      ],
      selectedColumns: [],
      columns_groups: [],
      swowInput: false,
      mediaSAid: false,
      exportUrls: {},
      screenWidth: '',
      render: 0,
    };
  },
  computed: {
    cacheData() {
      return {
        query: this.query,
      };
    },
    getTableFields() {
      if (!this.getMetrics.length) {
        return [];
      }

      const metrics = this.getMetrics.map((e) => {
        this.allMetrics.map((i) => { if (i.name === e.column_name) e = { ...e, ...i }; });
        return e;
      });

      const fields = this.selectedColumns.map(e => metrics.find(f => f.column_name === e));
      fields.forEach((item) => {
        item.sortable = 'custom';
        item.format = false;
      });

      fields.unshift(this.groupByField);
      fields[0].sortable = 'custom';
      fields[0].minWidth = 130; // костыль

      this.render++;
      return fields;
    },
    getMetrics() {
      return this.$store.state.settings.metrics || [];
    },
    groupByField() {
      return this.$store.state.settings.dimensions.find(field => field.column_name === this.group_by);
    },
    target() {
      return this;
    },
  },
  watch: {
    // filters() {
    //   this.fetchDataTable();
    // },
    showPlayersWithoutDeposits() {
      this.getReport('show players without deposits');
    },

    '$store.state.profile.user.reportSettings': {
      deep: true,
      handler(value) {
        this.resolveMetrics();
      },
    },
    '$i18n.locale': async function () {
      if (this.isComponentActivated) {
        const promise = Promise.all([
          this.$store.dispatch('getSettings'),
          this.resolveMetrics(),
          this.getReport(),
        ]);

        this.loading = true;
        await promise;
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.resolveMetrics();

    this.selectedColumns = this.default_cols;
    this.setFromQuery(this.passedParams);

    await this.getReport();

    this.afterBrowserRedraw(() => {
      this.isTableReady = true;
      this.loading = false;
    });
    // this.screenWidth = window.innerWidth < 650;
    // window.addEventListener('resize', this.calcWidth, true);
  },
  async activated() {
    this.$store.dispatch('getSettingsLazy');
    await this.resolveMetrics();

    this.setFromQuery({
      ...this.query,
      ...this.urlQuery,
    });

    this.setQuery();

    await this.getReport();

    this.afterBrowserRedraw(() => {
      this.loading = false;
    });
  },
  deactivated() {
    this.loading = true;
    // window.removeEventListener('resize', this.calcWidth, true);
  },
  async created() {
    this.setFromQuery(this.passedParams);
    this.loading = true;
    await this.resolveMetrics();
  },
  methods: {
    updateFilters(value) {
      this.filters = value;
      this.fetchDataTable();
    },
    async resolveMetrics() {
      const { ready } = this.$store.state.settings;
      return await ready.then(() => {
        const {
          dimensions, grouped_metrics, metrics, metrics_visible_by_default,
        } = this.$store.state.settings;
        this.columns_groups = grouped_metrics.map((e) => {
          e.items.map((i) => {
            if (i.items && !i.items.length) delete i.items;
            return i;
          });
          return e;
        });

        this.supportedMetrics = metrics.map(e => e.name);
        this.default_cols = metrics_visible_by_default;
		    return this.default_cols;
      });
    },
    setFromQuery(params) {
      let { query = {} } = params || this.urlQuery;

      if (this.urlQuery && this.urlQuery.subAffiliateActive) query = this.urlQuery.query;

      query = Object.keys(query).length == 0 ? { ...params } : query;

      this.query = query;
      this.sub_affiliate_id = query.sub_affiliate_id || '';

      if (query.sub_affiliate_id) {
        this.filters = [{ sub_affiliate_id: this.sub_affiliate_id }];
      }

      if (query.page) {
        this.page = query.page;
      }

      if (query.limit) {
        this.limit = query.limit;
      }

      if (query.group_by) {
        this.group_by = query.group_by;
        this.sort_by = query.sorting[0].sort_by;
        this.sort_dir = query.sorting[0].sort_dir;
      }

      if (query.metrics) {
        this.selectedColumns = query.metrics;
      }

      if (query.having) {
        this.columnsFilter = query.having;
      }

      if (query.passedPeriod) {
        this.setPeriod(query.passedPeriod);
      } else if (query.period) {
        this.setPeriod({
          from: query.from,
          to: query.to,
          period: query.period,
        });
      }

      if (query.search && query.search.site_player_id) {
        this.search = query.search.site_player_id.value[0];
      }

      this.filters = this.filters || [];

      Object.keys(query)
        .map((type) => {
          if (this.useFilters.indexOf(type) === -1) {
            return;
          }

          if (this.filters.find(e => e.type === type) && query[type] === this.filters.find(e => e.type === type).values) {

          } else {
            (this.filters || []).push({
              type,
              values: query[type],
            });
          }
        });

      this.cacheQuery();
    },
    getDataQuery(opts) {
      let query = { ...opts, ...this.period };
      const columnsFilter = {};

      this.$_.forOwn(this.columnsFilter, (e, key) => {
        columnsFilter[key] = this.$_.pick(e, ['op', 'value']);
      });
      Object.keys(columnsFilter)
        .forEach((e) => {
          let flagDel = false;
          this.selectedColumns.forEach((el) => {
            e === el ? flagDel = true : '';
          });
          !flagDel ? delete columnsFilter[e] : '';
        });

      const sort_by = this.sort_by && this.selectedColumns.includes(this.sort_by) ? this.sort_by : this.group_by;
      let params = {
        dimensions: [this.group_by],
        sorting: [{
          sort_by,
          sort_dir: this.sort_dir,
        }],
        metrics: this.selectedColumns,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        having: columnsFilter,
        search: this.search ? {
          [this.group_by]: {
            op: 'like',
			      value: [this.search],
          },
        } : {},
        from: this.period.from,
        to: this.period.to,
        metrics_format: 'pretty',
        filters: this.filters.filter(e => e.values && e.values !== '')
          .map(e => e = { [e.type]: { op: '=', value: e.values } }),
      };

      this.filters.forEach((e) => {
        if (e.sub_affiliate_id) {
          params.filters.push({ affiliate_id: { op: '=', value: e.sub_affiliate_id } });
        }
      });

      params = {
        ...params,
        filters: params.filters.reduce((accumulator, currentValue) => {
          const key = Object.keys(currentValue)[0];
          accumulator[key] = currentValue[key];
          return accumulator;
        }, {}),
      };

      (this.filters || []).map((filter) => {
        if (filter.sub_affiliate_id !== void 0) {
          if (filter.sub_affiliate_id !== 0) query.sub_affiliate_id = filter.sub_affiliate_id;
        } else {
          query = {
            sub_affiliate_id: null,
            ...query,
            [filter.type]: filter.values,
          };
        }
      });

      this.query = {
        ...params,
        ...query,
        group_by: this.group_by,
        page: this.page,
      };

      this.sub_affiliate_id = params.sub_affiliate_id;

      this.cacheQuery();
      return params;
    },
    async fetchData() {
      this.loading = true;
      await this.getReport();
      this.loading = false;
    },
    async getReport() {
      this.loadingError = false;

      const params = this.getDataQuery();

      if (!params.metrics) {
        return;
      }

      try {
        return this.$api
          .postReport(params)
          .then((res) => {
            this.count = res.misc.count;
            this.exportUrls = res.misc.export_urls;
            this.totals = res.payload.totals;
            this.data = res.payload.data.map((e) => {
              e.data = {
                id: e.row_id,
              };

              if (e.site_player_id === 'Not registered') {
                e.site_player_id = this.$t('customReports.not_registered');
              }

              delete e.row_filter_column;

              Object
                .keys(e)
                .forEach((k) => {
                  if (e[k] === null) e[k] = 0; // TEMPFIX
                });
              return e;
            });

            delete params.having;
            this.lastReportQuery = res.response.request.responseURL;
          })
          .catch(() => {
            this.loading = false;
            this.data = [{}];
            delete params.having;
          });
      } catch (e) {
        console.error(e);
      }
    },
    handleFilter(filter) {
      this.columnsFilter = {};
      Object.keys(filter)
        .forEach((key) => {
          if (filter[key].value && filter[key].op) {
            this.columnsFilter[key] = {
              op: filter[key].op,
              value: filter[key].value,
            };
          }
        });
      this.getReport('handle columns filter');
    },
    handleSort({ prop, order }) {
      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.sort_by = prop;
      this.sort_dir = order;
      this.getReport('handle sort');
    },
    handleSelectColumns(columns) {
      if (columns.indexOf(this.sort_by) === -1) {
        this.sort_by = this.group_by;
      }
    },
    subidFilter() {
      this.fetchDataTable();
    },
    groupedExpand() {
      this.multiSelectBlock = !this.multiSelectBlock;
    },
    getSubIds() {
      if (this.subIdFiterOpen) {
        this.$store.dispatch('getPlayerReportSubs', {
          from: this.period.from,
          to: this.period.to,
          subid1: this.subid1,
          subid2: this.subid2,
          subid3: this.subid3,
          subid4: this.subid4,
          subid5: this.subid5,
          sub_affiliate_id: this.sub_affiliate_id,
        });
      }
      this.oldFilter = this.filter;
    },
    selectChange(selectList) {
      this.selectedColumns = selectList;
      this.fetchDataTable();
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    openColumnsPopup() {
      this.$refs.columnsPopup.open();
    },
    // resetFilters() {
    //   this.query = this.getQueryData();
    //   this.sub_affiliate_id = '';
    //   this.cacheQuery();
    // },
    setQuery(query) {
      this.query = this.getDataQuery(query);
      this.loading = true;
      this.cacheQuery();
      return this.$store.dispatch('getSubAffiliatesLazy', {});
    },
    dependsHack() {
      return this.getTableFields;
    },
    // calcWidth(event) {
    //   this.screenWidth = event.target.innerWidth < 650;
    // },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/reports/players';
.player-report {
  @media (max-width: 1050px) {
    .table-card__header-right {
      flex-grow: 0;
      flex-shrink: 1;
    }

    .table-card__header-left {
      flex-grow: 1;
      width: 100% !important;
    }
  }
}
</style>
