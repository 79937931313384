import stagingLogo from '@/assets/staging-logo.svg';
import demoLogo from '@/assets/af-control-logo.svg';
import betwinnerLogo from '@/assets/logo.svg';
import sprutLogo from '@/assets/sprut-logo.png';
import casinozLogo from '@/assets/casinoz-logo.svg';
import marathonLogo from '@/assets/marathon-logo.svg';
import betpariLogo from '@/assets/betpari-logo.svg';
import speletLogo from '@/assets/spelet-logo.svg';
import caswinoLogo from '@/assets/caswino-logo.svg';
import winonLogo from '@/assets/winon-logo.svg';


export function getPageTitle(customerId) {
  switch (customerId) {
    case 'test':
      return 'StagingAffiliates';

    case 'betwinner':
      return 'BetWinnerAffiliates';

    case 'sprut':
      return 'SprutPartners';

    case 'casinoz':
      return 'SpinBet';

    case 'marathonbet':
    case 'mbetru' :
      return 'MarathonBet Partners';

    case 'betpari':
      return 'BetPari Partners';
    case 'spelet':
      return 'Spelet Partners';

    case 'winon':
      return 'GORoll Partners';

    case 'caswino':
      return 'Caswino Partners';

    case 'demo':
    default:
      return 'AffiliateControl';
  }
}

export function getHomepageDomain(customerId) {
  switch (customerId) {
    case 'test':
      return 'affctrltest.com';

    case 'betwinner':
      return 'betwinneraffiliates.com';

    case 'sprut':
      return 'sprutpartners.com';

    case 'casinoz':
      return 'spinbet.partners';

    case 'marathonbet':
    case 'mbetru':
      return 'marathonbet.partners';

    case 'betpari':
      return 'betpari.partners';

    case 'spelet':
      return 'spelet.partners';

    case 'winon':
      return 'winon.partners';

    case 'caswino':
      return 'caswino.partners';

    case 'demo':
    default:
      return 'affiliatecontroldemo.com';
  }
}

export function getLogo(customerId) {
  switch (customerId) {
    case 'test':
      return stagingLogo;

    case 'betwinner':
      return betwinnerLogo;

    case 'sprut':
      return sprutLogo;

    case 'casinoz':
      return casinozLogo;

    case 'marathonbet':
    case 'mbetru':
      return  marathonLogo;

    case 'betpari':
      return  betpariLogo;

    case 'spelet':
      return  speletLogo;

     case 'caswino':
      return  caswinoLogo;

    case 'winon':
      return  winonLogo;

    case 'demo':
    default:
      return demoLogo;
  }
}

export function getTheme(customerId) {
  switch (customerId) {
    case 'test':
      return 'test';

    case 'betwinner':
      return 'betwinner';

    case 'sprut':
      return 'sprut';

    case 'casinoz':
      return 'casinoz';

    case 'marathonbet':
      return  'marathonbet';

    case 'mbetru':
      return  'mbetru';

    case 'betpari':
      return  'betpari';

    case 'spelet':
      return  'spelet';

    case 'caswino':
      return 'caswino';

    case 'winon':
      return 'winon';

    case 'demo':
    default:
      return 'demo';
  }
}
