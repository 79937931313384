<template>
  <section class="promo__codes-form-wrap media-buying-modal add-public-modal">
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title">
        <span class="incrementing-id">(ID: {{ form.incrementing_id }})</span>
        {{ $t('mediaBuying.campaign.payment') }}
      </h5>
      <template>
        <div>
          <form>
            <div v-if="form.id" class="comment-overflow">
              <div class="comment-wrap__wrap" v-for="(comment, index) in comments" :key="index">
                <div class="comment-wrap__mess">
                  <div v-if="comment.author_type === 'admin'" class="aff-text-left mb-8">
                    <span class="comment-wrap__mess-text comment-wrap__mess-text-left">
                      <span class="date">
                        {{ comment.created_at | formatDate3 }}
                      </span>
                      <span class="comment-wrap__mess-text-row">{{ comment.content }}</span>
                      <a
                        v-for="(file, ind) in comment.files"
                        :key="ind"
                        :href="file.download_url"
                        :download="file.filename"
                        target="_blank"
                        class="comment-wrap__mess-files mb-4 mr-4"
                      >
                        <span class="file-name">{{ file.filename }}</span>
                        <i class="fas fa-download pl-4" />
                      </a>
                    </span>
                  </div>
                  <div v-if="comment.author_type === 'affiliate'" class="aff-text-right text-right mb-8">
                    <span :class="{ editable: editIndexComment === index }" class="comment-wrap__mess-text comment-wrap__mess-text-right">
                      <span class="comment-wrap__content">
                        <span
                          v-if="hideEditAndDelete(comment)"
                          class="comment-wrap__control"
                        >
                          <span class="date">
                            {{ comment.created_at | formatDate3 }}
                          </span>
                          <i v-if="editIndexComment !== index" @click="editMessage(comment, index)" class="fas fa-pen pl-4"></i>
                          <i v-if="editIndexComment !== index" @click="deleteMessage(comment, index)" class="fas fa-times pl-4"></i>
                        </span>
                        <span class="comment-wrap__mess-text-row">{{ comment.content }}</span>
                        <a
                          v-for="(file, ind) in comment.files"
                          :key="ind"
                          :href="file.download_url"
                          :download="file.filename"
                          target="_blank"
                          class="comment-wrap__mess-files mb-4 mr-4"
                        >
                          <span class="file-name-wrap">
                            <span class="file-name">{{ file.filename }}</span>
                            <i class="fas fa-download pl-4" />
                          </span>
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="comment-wrap__inn mt-8">
              <div class="d-flex comment-wrap">
                <div>
                  <div class="form-group select-wrapper m-0 w-100">
                    <textarea
                      v-if="active"
                      v-model="comment"
                      :placeholder="$t('mediaBuying.campaign.comment')"
                      class="form-control form-control-ta"
                    />
                  </div>
                  <button
                    v-if="flagEditComment"
                    class="btn btn-outline-danger btn-sm btn-icon ml-8"
                    @click.prevent="resetEdit"
                  >
                    <i class="fas fa-times text-red"></i>
                  </button>
                  <button
                    class="btn btn-outline-primary d-flex align-items-center justify-content-center btn-sm ml-8"
                    :disabled="isDisabledBtnComment || !comment"
                    @click.prevent="preloadImage"
                  >
                    <img :src="require('@/assets/theme/icon/ok.svg')" alt="" height="12" />
                    <span class="ml-4">{{ $t('mediaBuying.campaign.send') }}</span>
                  </button>
                </div>
              </div>
              <div class="form-group select-wrapper file-wrap file-wrap-comment mb-0 mt-0">
                <el-upload
                  :class="{
                    disabled: fileListComment.length === $store.state.mediaBuying.uploadSettingsData.max_files_per_request,
                  }"
                  class="upload-demo"
                  action=""
                  :on-remove="handleRemoveComment"
                  :on-change="onSuccessComment"
                  :file-list="fileListComment"
                  :auto-upload="false"
                  multiple
                >
                  <button
                    class="btn btn-outline-primary btn-sm btn-icon"
                    @click.prevent
                  >
                    <i class="fas fa-paperclip" />
                  </button>
                </el-upload>
                <span v-if="errorFile.length" class="error error-file text-danger text-left mt-2">{{ errorFile[0] }}</span>
              </div>
            </div>
            <div class="statuses pay-info-wrap d-flex mb-4 mt-4 pt-10">
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.payments.fields.moderation_status.title') }}</label>
                <span v-html="formatStatus(form.moderation_status)" />
              </div>
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.payments.fields.payout_status.title') }}</label>
                <span v-html="formatStatus(form.payout_status)" />
              </div>
              <div class="select-wrapper">
                <label>{{ $t('mediaBuying.campaign.request_date') }}</label>
                <span>{{ $moment(form.created_at).format('DD MMM YYYY HH:mm') }}</span>
              </div>
            </div>
            <div class="select-wrapper select-wrapper-mob mb-8 mt-8">
              <label>{{ $t('mediaBuying.campaign.payment_period') }}</label>
              <span>{{ form.period }}</span>
            </div>
            <div class="pay-info-wrap d-flex mb-8">
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.campaign.sum') }}</label>
                <span>{{ currencyFormatter(form.currency_code, form.amount) }}</span>
              </div>
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.campaign.payment_type') }}</label>
                <span class="payment">
                  <img v-if="form.payment_method_logo_base64" :src="form.payment_method_logo_base64" :alt="form.payment_method_name" />
                  <template v-else>{{ form.payment_method_name }}</template>
                </span>
              </div>
              <div class="select-wrapper">
                <label>{{ $t('mediaBuying.campaign.wallet') }}</label>
                <span class="wallet"> {{ form.payment_wallet }}</span>
              </div>
            </div>
            <div class="form-group select-wrapper mb-8">
              <label>{{ $t('mediaBuying.campaign.description') }}</label>
              <span class="white-space-pre">{{ form.description }}</span>
            </div>
            <div class="form-group select-wrapper file-wrap mb-0" v-if="form.files && form.files.length">
              <label class="file-wrap__label">{{ $t('mediaBuying.campaign.files') }}</label>
              <div class="file-wrap__images-wrap">
                <div v-for="(item, index) in form.files" :key="index" class="file-wrap__images-wrap-sub">
                  <span class="demonstration">{{ item.filename }}</span>
                  <el-image :src="item.preview_url" :preview-src-list="srcList" :fit="'contain'" :z-index="100002000">
                    <div slot="error" class="image-slot">
                      <i class="far fa-file"></i>
                      <a :href="item.download_url" :download="item.filename" target="_blank">
                        <i class="fas fa-download"></i>
                      </a>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <div slot="footer" class="text-right d-flex align-items-baseline">
        <a class="btn btn-outline-primary btn-sm" @click="close">
          {{ $t('paymentHistory.close') }}
        </a>
        <a
          class="btn btn-primary btn-sm ml-16"
          :disabled="loading"
          v-if="form.moderation_status === 'for_rework' || form.payout_status === 'for_rework'"
          @click="edit"
        >
          <span v-if="!loading">{{ $t('mediaBuying.campaign.edit') }}</span>
          <i v-if="loading" class="fas fa-spinner spinner-hide" />
        </a>
      </div>
    </modal>
    <request-payment id="ShowPayment" ref="requestPayment" />
  </section>
</template>

<script>
import { Modal } from 'src/components';
import RequestPayment from 'src/views/Dashboard/MediaBuying/Modal/RequestPayment';
import editMessages from './mixins/editMessages';

const classes = {
  approved: 'text-green',
  on_review: 'text-orange',
  declined: 'text-red',
  paid: 'text-green',
  processing: 'text-orange',
  not_paid: 'text-red',
  for_rework: 'text-red',
  failed: 'text-red',
};

export default {
  components: {
    Modal,
    RequestPayment,
  },
  mixins: [editMessages],
  data() {
    return {
      fileListCommentBack: [],
      fileListComment: [],
      countFileComment: 0,
      comment: '',
      comments: [],
      formComment: {
        content: '',
        period: '',
        file_ids: [],
      },
      openImagePopup: [false],
      active: false,
      form: {},
      loading: false,
    };
  },
  computed: {
    srcList() {
      return this.form.files.filter(e => e.preview_url !== null).map(e => e.preview_url) || [];
    },
    currency() {
      return this.currencyFormatter(this.$store.state.mediaBuying.campaign.payments_currency_code);
    },
  },
  methods: {
    open(row) {
      this.reset();
      this.form = this.$_.cloneDeep(row);
      this.form.period = `${this.$moment(row.payment_period_start).format('DD MMM YYYY HH:mm')} - ${this.$moment(row.payment_period_end).format('DD MMM YYYY HH:mm')}`;
      this.active = true;
      this.getCommentsPayment(row.id);
      this.fileList = row.files.map(e => ({
        status: 'ready',
        name: e.filename,
        id: e.id,
      }));
    },
    close() {
      this.active = false;
      this.reset();
    },
    reset() {
      this.resetEdit();
      this.form.id = '';
      this.comments = [];
      this.fileListComment = [];
      this.errorFile = [];
      this.comment = '';
      this.formComment = {
        content: '',
        file_ids: [],
      };
    },
    edit() {
      this.active = false;
      this.$refs.requestPayment.open(this.form);
    },
    getComments() {
      this.getCommentsPayment(this.form.id);
    },
    postCommentsPayment(id) {
      this.formComment.content = this.comment;
      this.$store
        .dispatch('postPaymentComments', { id, ...this.formComment })
        .then(() => {
          this.comment = '';
          this.formComment = {
            content: '',
            file_ids: [],
          };
          this.getCommentsPayment(this.form.id);
        })
        .finally(() => {
          this.loading = false;
          this.isDisabledBtnComment = false;
        });
    },
    getCommentsPayment(id) {
      const [sort_column, sort_dir] = ['created_at', 'asc'];
      this.$store
        .dispatch('getPaymentComment', {
          id,
          sort_column,
          sort_dir,
        })
        .then((res) => {
          this.comments = res.payload;
          setTimeout(() => {
            const over = document.getElementsByClassName('comment-overflow')[0];
            if (over) over.scrollTop = 9999;
          }, 100);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatStatus(status) {
      return status && `<span class='${classes[status]}'>${this.$t(`mediaBuying.payments.status.${status}`)}</span>`;
    },
    currencyFormatter(code, amount) {
      const miscCurrencies = this.$store.state.misc.currencies;
      const currency = miscCurrencies.find(c => (code ? code.toLowerCase() : null) === c.code?.toLowerCase());

      if (!Number.isFinite(amount)) {
        if (!code) {
          return '';
        }

        return currency ? currency.code.toUpperCase() : '';
      } else {
        amount = amount.toFixed(2);
        const amountData = amount.split('.');
        const result = (+amountData[0]).toLocaleString();

        if (!code) {
          return `${result}.${amountData[1]}`;
        }

        return currency ? `${currency.code.toUpperCase()} ${result}.${amountData[1]}` : `${result}.${amountData[1]}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-buying-modal {
  .wallet {
    word-break: break-all;
  }
}

</style>
<style lang="scss">
.statuses {
  border-top: 1px solid #dee2e6;
}
</style>
