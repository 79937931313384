export default {
  // Channels
  saveChannels(state, data) {
    state.channels = data.payload;
    state.totals = {
      ...state.totals,
      channels: {
        count: data.misc.count,
        balance_sum: data.misc.totals.balance_sum,
        paid_sum: data.misc.totals.paid_sum,
        spent_sum: data.misc.totals.spent_sum,
      },
    };
  },

  saveCampaignsAttentionCounters(state, payload) {
    state.campaignsAttentionCounters = payload;
  },

  saveChannel(state, payload) {
    state.channel = payload;
  },

  // Campaigns
  saveCampaigns(state, data) {
    state.campaigns = data.payload;
    state.campaignsCount = data.misc.count;
  },
  saveCampaign(state, payload) {
    state.campaign = payload;
  },
  saveCampaignAvailableSpend(state, payload) {
    state.campaignAvailableSpend = payload.available_spend;
  },

  // Payments
  savePayments(state, data) {
    state.payments = data.payload;
    state.paymentsCount = data.misc.count;
  },
  savePaymentsCount(state, payload) {
    switch (payload.location) {
      case 'sidebarModeration':
        state.paymentsCountSidebarModeration = payload.count;
        break;
      case 'sidebarPayout':
        state.paymentsCountSidebarPayout = payload.count;
        break;
      case 'moderation':
        state.paymentsCountModeration = payload.count;
        break;
      case 'payout':
        state.paymentsCountPayout = payload.count;
        break;
      default:
        state.paymentsCount = payload.count;
    }
  },
  savePayment(state, payload) {
    state.payment = payload;
  },
  savePaymentMethod(state, payload) {
    state.paymentMethod = payload;
  },

  // Posts
  savePosts(state, data) {
    state.posts = data.payload;
    state.postsCount = data.misc.count;
  },
  savePostsCount(state, payload) {
    switch (payload.location) {
      case 'sidebar':
        state.postsCountSidebar = payload.count;
        break;
      default:
        state.postsCount = payload.count;
    }
  },
  savePost(state, payload) {
    state.post = payload;
  },

  // Files
  saveFile(state, payload) {
    state.files = payload;
  },
  uploadSettingsData(state, payload) {
    state.uploadSettingsData = payload.payload;
  },

  // Tracking URLs
  saveTrackingUrl(state, payload) {
    state.trackingUrl = payload;
  },
  saveLandings(state, payload) {
    state.landings = payload;
  },

  saveMarketingCampaigns(state, payload) {
    state.marketingCampaigns = payload;
  },
};
