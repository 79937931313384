export default {
  saveWallets(state, payload) {
    state.wallets = payload.payload;
  },

  saveWalletHistory(state, payload) {
    state.history = payload;
  },

  saveWalletHistoryCount(state, payload) {
    state.historyCount = payload.count;
  },

  saveWalletStats(state, payload) {
    state.pendingPayment = payload.amount_pending;
    state.paidInTotal = payload.amount_paid;
    state.has_approved_not_paid_payment = payload.has_approved_not_paid_payment;
  },

  countBalance(state) {
    let availableBalance = 0;
    let holdBalance = 0;
    state.wallets.forEach((e) => {
      availableBalance += e.available_balance;
      holdBalance += e.hold_balance;

      if (e.currency_code) {
        if (state.currencyCode !== e.currency_code.toUpperCase() || Array.isArray(state.currencyCode)) {
          if (!Array.isArray(state.currencyCode)) state.currencyCode = [state.currencyCode];
          state.currencyCode.push(e.currency_code.toUpperCase());
        } else {
          state.currencyCode = e.currency_code.toUpperCase();
        }
      }
    });

    if (Array.isArray(state.currencyCode)) {
      state.currencyCode = [...new Set(state.currencyCode)]
    }

    state.availableBalance = availableBalance;
    state.holdBalance = holdBalance;
    state.balance = (availableBalance + holdBalance);
  },
};
