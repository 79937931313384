<template>
  <section class="payment__history payment-history">
    <div class="card card-mob mb-24">
      <div class="payment-card__up d-flex justify-content-between">
        <h4 class="mt-0 mr-16">
          {{ $t('paymentHistory.yourBalance') }}
        </h4>
        <div class="d-flex justify-content-between align-items-center texts-btn__wrap">
          <div class="texts-wrap">
            <h6 class="mr-16 pr-16">
              {{ $t('paymentHistory.negativeBalanceAfterPayment') }}:
              <span class="text-green">{{ payments_negative_carryover }}</span>
            </h6>
            <h6 class="mr-16">
              {{ $t('paymentHistory.paymentPeriod') }}:
              <span>{{ payment_period }}</span>
            </h6>
          </div>
          <div class="btn-wrap">
            <request-payment
              v-if="showPaymentButton"
              v-show="(!waitingApproval && !$store.state.payments.has_approved_not_paid_payment) && $store.state.profile.user.payments_net === 'manual'"
              :changeSuccess="waitingApproval"
              @update:changeSuccess="getWaintingApproval"
            />
            <cancel-payment-request
              v-show="(waitingApproval || $store.state.payments.has_approved_not_paid_payment) && $store.state.profile.user.payments_net === 'manual'"
              :disabled="$store.state.payments.has_approved_not_paid_payment"
              :changeSuccess="waitingApproval"
              @update:changeSuccess="getWaintingApproval"
            />
          </div>
        </div>
      </div>
      <div class="payment-card__down row">
        <div class="col-md-3 col-6 card-wrap">
          <div class="card-info">
            <h5 class="head text-info">
              {{ $t('paymentHistory.inHold') }}
            </h5>
            <h4 class="data" v-html="formatPrice($store.state.payments.holdBalance)" />
          </div>
        </div>
        <div class="col-md-3 col-6 card-wrap">
          <div class="card-info">
            <h5 class="head text-success">
              {{ $t('paymentHistory.available') }}
            </h5>
            <h4 class="data" v-html="formatPrice($store.state.payments.availableBalance)" />
          </div>
        </div>
        <div class="col-md-3 col-6 card-wrap">
          <div class="card-info">
            <h5
              style="color: #cd8a0c;"
              class="head"
            >
              {{ $t('paymentHistory.pendingPayment') }}
            </h5>
            <h4 class="data" v-html="formatPrice($store.state.payments.pendingPayment)" />
          </div>
        </div>
        <div class="col-md-3 col-6 card-wrap">
          <div class="card-info">
            <h5 class="head text-black">
              {{ $t('paymentHistory.paidInTotal') }}
            </h5>
            <h4 class="data" v-html="formatPrice($store.state.payments.paidInTotal)" />
          </div>
        </div>
      </div>
      <div class="texts-btn__wrap-mob pb-16">
        <div class="texts-wrap pt-12">
          <div class="texts-wrap__table">
            <span>{{ $t('paymentHistory.negativeBalanceAfterPayment') }}</span>
            <span class="text-green">{{ payments_negative_carryover }}</span>
          </div>
          <div class="texts-wrap__table">
            <span>{{ $t('paymentHistory.paymentPeriod') }}</span>
            <span>{{ payment_period }}</span>
          </div>
        </div>
        <div class="btn-wrap pl-16 pr-16">
          <request-payment
            v-if="showPaymentButton"
            v-show="(!waitingApproval && !$store.state.payments.has_approved_not_paid_payment) && $store.state.profile.user.payments_net === 'manual'"
            :changeSuccess="waitingApproval"
            @update:changeSuccess="getWaintingApproval"
          />
          <cancel-payment-request
            v-show="(waitingApproval || $store.state.payments.has_approved_not_paid_payment) && $store.state.profile.user.payments_net === 'manual'"
            :disabled="$store.state.payments.has_approved_not_paid_payment"
            :changeSuccess="waitingApproval"
            @update:changeSuccess="getWaintingApproval"
          />
        </div>
      </div>
    </div>
    <table-card
      ref="datatable"
      class="ui-flex-grow"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :show-total="false"
      :count="$store.state.payments.historyCount"
      :formaters="formaters"
      period-picker
      :period.sync="period"
      dont-fill-empty
      extend-height
      i18n-path="reports"
      @set-range="period = $event"
      @change-range="fetchDataTable"
      @fetch-data="fetchDataTable"
    >
      <div
        class="checkbox payment-history__checkbox ui-12 ui-mobile-12 ui-g-md"
        slot="actionsAppend"
      >
        <input
          v-model="payouts"
          id="checkbox1"
          type="checkbox"
        >
        <label
          class="checkbox-label"
          :class="{
            'payouts': payouts
          }"
          for="checkbox1"
        >
          {{ $t('paymentHistory.showOnlyPayouts') }}
        </label>
      </div>
    </table-card>
  </section>
</template>

<script>
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import moment from 'moment';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import RequestPayment from 'src/views/Dashboard/Wallet/RequestPayment';
import CancelPaymentRequest from 'src/views/Dashboard/Wallet/CancelPaymentRequest';
import TableCard from 'src/components/Cards/TableCard.vue';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator, { getDefaultPeriod } from 'src/views/Dashboard/Reports/tableFlowMixin.js';

const sizes = {
  1080: 15,
  1440: 25,
};
const viewName = '/wallet';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});

const defaultParams = {
  page: 1,
  pageSize,
  sort_column: 'date',
  sort_dir: 'desc',
  transaction_type: undefined,
  payouts: false,
};

const defaultPeriod = getDefaultPeriod();

const fetch = async (ctx, params) => {
  if ((params && params.sort_column === 'index' && params.sort_dir === 'asc') || !(params.sort_column && params.sort_dir)) {
    params.sort_column = 'date';
    params.sort_dir = 'desc';
  }

  const start_timestamp = params && params.start_timestamp
    ? params.start_timestamp
    : ctx.period
      ? ctx.period.from
      : params.period
        ? params.period.from
        : defaultPeriod.from;

  const end_timestamp = params && params.end_timestamp
    ? params.end_timestamp
    : ctx.period
      ? ctx.period.to
      : params.period
        ? params.period.to
        : defaultPeriod.to;

  const query = ctx.params ? ctx.params.lastQuery : {};
  const getWallets = ctx.$store.dispatch('getWallets');

  const getData = () => Promise.all([
    ctx.$store.dispatch('getWalletHistory', {
      start_timestamp,
      end_timestamp,
      limit: ctx.pageSize || pageSize,
      offset: 0,
      ...params,
    }), ctx.$store.dispatch('getWalletHistoryCount', {
      start_timestamp,
      end_timestamp,
      transaction_type: params ? params.transaction_type : ctx.transaction_type,
    }),
  ]);

  if (ctx.lastQuery) {
    ctx.lastQuery = {
      ...params,
      ...query,
      from: ctx.period.from,
      to: ctx.period.to,
      period: ctx.period.period,
    };
  }

  await getWallets;
  return await getData();
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  period: 'param',
  windowRefreshHook: true,
  fromName: 'start_timestamp',
  toName: 'end_timestamp',
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  fetch,
  components: {
    PeriodWrapper,
    RequestPayment,
    CancelPaymentRequest,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    HelpersMixin,
    pageSizeMixin,
    tableFlowMixin,
  ],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      mobh: false,
      rotate: false,
      waitingApproval: 0,
      payouts: false,
      loading: false,
      columns: [
        {
          name: 'index',
          sortable: true,
          filterable: false,
          show: false,
        }, {
          name: 'date',
          sortable: 'custom',
          filterable: false,
          format: 'format-date',
        }, {
          name: 'transaction_type',
          sortable: false,
          filterable: false,
        }, {
          name: 'to_hold',
          sortable: false,
          filterable: false,
          format: 'ncurrency',
          align: 'right',
        }, {
          name: 'to_available',
          sortable: false,
          filterable: false,
          format: 'ncurrency',
          align: 'right',
        }, {
          name: 'hold_balance',
          sortable: false,
          filterable: false,
          format: 'ncurrency',
          align: 'right',
        }, {
          name: 'balance',
          sortable: false,
          filterable: false,
          format: 'ncurrency',
          align: 'right',
        },
      ],
      period: {
        ...defaultPeriod,
      },
      totalRows: 0,
      pagination: true,
      transaction_type: undefined,
      formaters: Formaters,
      isTableReady: false,
    };
  },
  beforeMount() {
    this.pageSize = this.limit;
  },
  computed: {
    payment_period() {
      return this.$t(`paymentHistory.${this.$store.state.profile.user.payments_net}`);
    },
    payments_negative_carryover() {
      return this.$store.state.profile.user.payments_negative_carryover === false ? this.$t('paymentHistory.no') : this.$t('paymentHistory.yes');
    },
    rows() {
      return this.$store.state.payments.history.map((e, i) => ({
        index: i,
        date: moment(e.grouping)
          .format('YYYY-MM-DD'),
        transaction_type: this.formatType(e.transaction_type),
        to_available: e.amount_to_available,
        to_hold: e.amount_to_hold,
        hold_balance: e.result_balance_hold,
        balance: e.result_balance_available,
      }));
    },
    showPaymentButton() {
      return this.$store.getters.getUser.payments_enabled;
    },
  },
  created() {
    this.setFromQuery(this.passedParams);
  },
  async activated() {},
  async mounted() {
   this.fetchData();
  },
  watch: {
    payouts(value, oldValue) {
      if (value === oldValue) {
        return;
      }

      if (value) {
        this.transaction_type = ['withdraw', 'withdraw_abort'];
      } else {
        this.transaction_type = undefined;
      }

      let params;
      if (this.pagination) {
        params = {
          sort_column: this.sorting.sort_column,
          sort_dir: this.sorting.sort_dir,
          limit: this.pageSize,
          offset: 0,
          transaction_type: this.transaction_type,
          start_timestamp: this.period.from,
          end_timestamp: this.period.to,
        };
      }
      if (params.sort_column === 'index' && params.sort_dir === 'asc') {
        params.sort_column = 'date';
        params.sort_dir = 'desc';
      }

      this.fetchData(params);
    },
  },
  methods: {
    filterColumns(columns) {
      return columns;
    },
    async getFetchDataParams(query) {
      const params = {
        ...query,
        transaction_type: this.payouts
          ? ['withdraw', 'withdraw_abort']
          : undefined,
      };

      this.getWaintingApproval();

      return params;
    },
    getWaintingApproval(success) {
      return this.$api.getPaymentsHistoryCount({ moderation_status: 'on_review' })
        .then((res) => {
          this.$store.dispatch('getWallets');
          if (!success) {
            this.waitingApproval = res.payload.count;
          }
        });
    },
    mobHead() {
      this.mobh ? (this.mobh = false) : (this.mobh = true);
    },
    formatPrice(amount) {
      const sum = this.$options.filters.currency(amount, '$', null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });

      if (Math.abs(amount) <= 0.005) {
        return `<span>${sum.replace('-', '')}</span>`;
      }

      return `<span>${sum}</span>`;
    },
    formatType(type) {
      return this.$t(`paymentHistory.transaction_type_${type}`);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/wallet/wallet';

.payment__history {
  .table-card__header-right-lower {
    flex-grow: 1;
    width:  100%;
  }
}

.payment-history__checkbox {
  align-items: center;

  .checkbox-label {
    margin-bottom: 0;
  }
}
</style>
