<template>
  <div
    :style="{ paddingBottom: !showTotal ? `${padding}px` : 'none' }"
    :class="{
      'extend-height': extendHeight,
      'table-actions': actions,
      'fit-content': !heightProp
    }"
    class="ui-table"
  >
    <el-table
      ref="dataTable"
      :data="data"
      :default-sort="getDefaultSort"
      :height="heightProp"
      :max-height="heightProp"
      :show-header="showHeader"
      :show-summary="showTotal"
      :summary-method="getSummaryRow"
      :highlight-current-row="rowSelectable"
      :row-class-name="rowCheck ? computedRowClasss : computedRowClass"
      border
      @sort-change="handleSort"
      @expand-change="handleExpand"
      @current-change="handleCurrentRowChange"
      @row-click="handleCurrentRowClick"
      @row-contextmenu="contextMenu"
    >
      <slot
        :renderHeader="renderHeader"
        :formatValue="formatValue"
        name="columns"
      >
        <el-table-column
          v-for="(field, index) in $_.filter(fields, f => ($_.isUndefined(f.show) && !hideFields.includes(f.name)) || f.show)"
          :key="index"
          :prop="field.name"
          :label="field.title ? field.title : $t(`${i18nPath}.fields.${field.name}.title`) || ''"
          :align="field.align || 'left'"
          :header-align="field.align || 'left'"
          :min-width="field.minWidth || 'auto'"
          :width="field.width || 'auto'"
          :sortable="disableSort || customSorting ? false : field.sortable || false"
          :formatter="field.formatter"
          :show-overflow-tooltip="!field.customTooltip"
          :render-header="renderHeader"
          resizable
        >
          <template v-slot="scope">
            <div
              v-if="field.link"
              :class="[field.class, field.computedClass ? field.computedClass(scope.row[field.name], scope.row) : '']"
              class="row-value link"
            >
              <router-link :to="field.link(scope.row)">
                {{ scope.row[field.name] }}
              </router-link>
            </div>
            <vnodes
              v-else-if="field.linkAppend"
              :vnodes="field.linkAppend(scope.row)"
            />
            <div
              v-else-if="field.renderValue && !field.customTooltip"
              :class="[field.class, field.computedClass ? field.computedClass(scope.row[field.name], scope.row) : '']"
              class="row-value"
              v-html="field.renderValue(scope.row[field.name], scope.row, field)"
            />
            <div
              v-else-if="field.renderValue && field.customTooltip"
              :class="[field.class, field.computedClass ? field.computedClass(scope.row[field.name], scope.row) : '']"
              class="row-value"
            >
              <span class="length-up text-nowrap d-flex justify-content-between"
                    :class="{'with-badge': scope.row[field.name].count,}"
              >
                <el-tooltip :content="scope.row[field.name].text"
                            placement="top"
                            :open-delay="500"
                >
                  <span v-html="field.renderValue(scope.row[field.name], scope.row, field)" />
                </el-tooltip>
                <span v-if="scope.row[field.name].count" class="tab-count mt-2">{{scope.row[field.name].count}}</span>
              </span>
            </div>
            <div
              v-else-if="field.copyable"
            >
              <span>{{scope.row[field.name]}}
                <el-tooltip placement="right"
                            popper-class="right"
                            :disabled="copied !== scope.row[field.name]"
                            :value="copied !== scope.row[field.name]"
                            :content="$t('mediaBuying.promocodes.copy_success_message')"
                >
                  <i class="far fa-copy text-green" @click="() => copyToClipboard(scope.row[field.name])" />
                </el-tooltip>
              </span>
            </div>
            <div
              v-else
              :class="[field.class, field.computedClass ? field.computedClass(scope.row[field.name], scope.row) : '']"
              class="row-value"
              @click="field.click ? field.click(scope.row) : ''"
              :ref="field.customTooltip && field.whiteSpacedText && scope.row['id']"
            >
              <span v-if="field.computedValue">{{ field.computedValue(scope.row, scope.row[field.name]) }}</span>
              <span v-if="field.customTooltip && !field.whiteSpacedText">
                <span
                  v-if="field.renderTooltip(scope.row[field.name]).length >= 1 &&
                    field.renderTooltip(scope.row[field.name])[0] !== ''"
                  class="length-up"
                >
                  <el-tooltip placement="top"
                              popper-class="urls-tooltip"
                              :open-delay="500"
                  >
                    <span>
                      <a :href="field.renderTooltip(scope.row[field.name])[0]" target="_blank" class="text-green">
                        {{field.renderTooltip(scope.row[field.name])[0]}}
                      </a>
                      <span v-if="field.renderTooltip(scope.row[field.name]).length > 1" class="countUrl">
                        +{{field.renderTooltip(scope.row[field.name]).length - 1}}
                      </span>
                    </span>
                    <div slot="content">
                      <span
                        class="d-flex flex-column"
                        :key="index"
                        v-for="(url, index) in field.renderTooltip(scope.row[field.name])"
                      >
                        <a :href="url" target="_blank">{{url}}</a>
                      </span>
                    </div>
                  </el-tooltip>
                </span>
                <span v-else>—</span>
              </span>
              <span v-else-if="field.customTooltip && field.whiteSpacedText" class="length-up text-nowrap">
                <el-tooltip :placement="placementTooltip || 'top'"
                            :open-delay="500"
                >
                  <div slot="content">{{scope.row[field.name]}}</div>
                  <span class="overflow-ellipsis">{{scope.row[field.name]}}</span>
                </el-tooltip>
              </span>
              <span v-else-if="field.component">
                <render-component :data="field.component(scope.row, scope.row[field.name])" />
              </span>
              <span v-else>{{ field.format ? formatValue(scope.row[field.name], field.format, field.formatOptions,
                                                         scope.row) : scope.row[field.name] }}</span>
              <flag
                v-if="field.format === 'ip-flag'"
                :iso="scope.row.country_code"
                :title="scope.row.country_name"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="actions"
          :label="actions.name"
          :align="actions.align || 'center'"
          :width="actions.width || 'auto'"
          :fixed="actions.fixed || false"
        >
          <template v-slot="scope">
            <div class="row-value actions">
              <template v-for="(action, i) in actions.operations">
                <span
                  v-if="action.computedValue"
                  :key="action.name"
                  :class="action.class"
                  v-html="action.computedValue(scope.row)"
                />
                <render-component v-else-if="action.component" :key="action.name"
                                  :data="action.component(scope.row, target)"
                />
                <el-popover
                  placement="top-end"
                  trigger="click"
                  :key="action.name"
                  v-else-if="action.popup"
                  ref="popover"
                >
                  <div class="message__wrapper">
                    <p>{{ $t('topNavbar.wantDelete') }}?</p>
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-outline-primary btn-sm mb-0"
                        @click="hidePopup()"
                      >
                        {{ $t('alerts.dataTable.cancel') }}
                      </button>
                      <button
                        class="btn btn-danger btn-sm mb-0 ml-8"
                        @click="action.callback(scope.row, action.callbackOptions, $event), hidePopup()"
                      >
                        {{ $t('alerts.dataTable.delete') }}
                      </button>
                    </div>
                  </div>
                  <span v-if="action.icon" slot="reference"
                        :class="`${action.class} el-tooltip icon__wrapper ${action.status !== scope.row.status ? 'disabled' : ''}`"
                  >
                    <i v-if="action.icon" :class="`far fa-${action.icon}`"></i>
                  </span>
                  <span v-else slot="reference" :class="action.class">
                    {{ action.name }}
                  </span>
                </el-popover>
                <el-tooltip
                  placement="top"
                  popper-class="tooltip-icon"
                  :key="action.name"
                  :open-delay="500"
                  v-else-if="action.icon"
                >
                  <a
                    class="icon__wrapper"
                    :class="action.class"
                    :href="action.url ? action.url(scope.row, action.callbackOptions) : null"
                    @click.stop="action.callback(scope.row)"
                  >
                    <i :class="`far fa-${action.icon}`"></i>
                  </a>
                  <div slot="content">
                    {{action.tooltip}}
                  </div>
                </el-tooltip>
                <a
                  v-else
                  :key="action.name"
                  :class="action.class"
                  :href="action.url ? action.url(scope.row, action.callbackOptions) : null"
                  @click="action.callback(scope.row, action.callbackOptions, $event)"
                >{{ action.name }}</a>
                <span :key="i" class="divider" />
              </template>
            </div>
          </template>
        </el-table-column>
      </slot>
      <template slot="append" v-if="!dontFillEmpty">
        <table class="el-table__body"
               cellspacing="0"
               cellpadding="0"
               border="0"
               style="width:100%"
               ref="emptySpace"
        >
          <tr style="height:0;">
            <td v-for="field in $_.filter(fields, f => $_.isUndefined(f.show) ? true : f.show)" :key="field.name" />
          </tr>
        </table>
      </template>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import render from './render';
import Popover from '../../Popover';

export default {
  name: 'UiTable',
  components: {
    RenderComponent: render,
    'ac-popover': Popover,
    Vnodes: {
      functional: true,
      render: (h, ctx) => (ctx.props.vnodes ? ctx.props.vnodes : ''),
    },
  },
  mixins: [DimensionsMixin],
  props: {
    hideFields: {
      type: Array,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    rowCheck: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Object,
      default() {
        return {};
      },
    },
    customTotalRow: {
      type: Array,
      default() {
        return [];
      },
    },
    sort: {
      type: Object,
      default() {
        return {};
      },
    },
    i18nPath: {
      type: String,
      default: '',
    },
    minTableHeight: {
      type: Number,
      default: 400,
    },
    padding: {
      type: Number,
      default: 40,
    },
    rowsCount: {
      type: Number,
      default: 0,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    dontFillEmpty: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    externalHeight: {
      type: [Number, String],
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: [Object, Array],
      default: null,
    },
    rowClick: {
      type: Function,
      default: () => {
      },
    },
    rowSelectable: {
      type: Boolean,
      default: false,
    },
    colFilters: {
      type: Object,
      default: () => {
      },
    },
    customContext: {
      type: Boolean,
      default: false,
    },
    computedRowClass: {
      type: Function,
      default() {
        return '';
      },
    },
    extendHeight: {
      type: Boolean,
      default: false,
    },
    customSorting: {
      type: Boolean,
      default: false,
    },
    checkedRow: {
      type: Boolean,
      default: false,
    },
    checkedRowId: {
      type: String,
      default: 'id',
    },
    selectRow: {
      type: Boolean,
      default: false,
    },
    placementTooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lastSortProp: null,
      lastSortOrder: null,
      tableHeight: 0,
      tableStatus: {
        height: false,
        rows: false,
      },
      sortProp: '',
      sortOrder: '',
      columnFilters: {},
      lastOpenFilter: '',
      isFiltersInit: false,
      currentRow: {},
      checkApplyFilter: false,
      activeFilter: '',
      activeColumnFilters: {},
      checkAll: false,
      checkRows: [],
      isPressCtrl: false,
      copied: null,
      amount: '',
      ready: false,
      heightPropLazy: 0,
    };
  },
  computed: {
    target() {
      return this;
    },
    tableReady() {
      return this.tableStatus.height && this.tableStatus.rows;
    },
    getDefaultSort() {
      return {
        prop: this.sortProp,
        order: `${this.sortOrder}ending`,
      };
    },
    heightProp() {
      // change detections issue in chrome
      const externalHeight = this.externalHeight;
      const tableHeight = this.tableHeight;

      return this.isStatic
        ? externalHeight || undefined
        : tableHeight || undefined;
    },
    ...mapGetters({
      countries: 'misc/countries',
      groups: 'misc/groups',
    }),

  },
  watch: {
    lastOpenFilter(v) {
      if (v === '') {
        Array.from(document.querySelectorAll('.cell.hover-once'))
          .map(e => e.classList.remove('hover-once'));
        if (!this.checkApplyFilter) {
          this.columnFilters[this.activeFilter] = this.activeColumnFilters[this.activeFilter];
          this.columnFilters[this.activeFilter].visible = false;
        }
      } else {
        this.activeFilter = v;
        this.activeColumnFilters = this.$_.cloneDeep(this.columnFilters);
      }
    },
    sort: {
      deep: true,
      handler(nv) {
        if (this.customSorting) {
          this.sortProp = nv.prop;
          this.sortOrder = nv.order;
        } else {
          this.sortProp = nv.prop;
          this.sortOrder = nv.order;
          this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
          this.$refs.dataTable.doLayout();
          this.$nextTick(() => {
            this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
            this.$refs.dataTable.doLayout();
          });
        }
      },
    },
    fields: {
      deep: true,
      handler() {
        this.isFiltersInit = false;
        this.fields.forEach((field) => {
          if (field.filterable && (this.$_.isEmpty(this.columnFilters[field.name]) || !this.columnFilters[field.name].op)) {
            this.$set(this.columnFilters, field.name, {
              visible: false,
              op: '',
              value: '',
            });
          }
        });
        this.$nextTick(() => {
          this.$refs.dataTable.$forceUpdate();
        });
        this.isFiltersInit = true;
      },
    },
    colFilters: {
      deep: true,
      handler(nv) {
        this.setColumnFilters(nv);
      },
    },
  },
  methods: {
    handleCurrentRowClick(row, e) {
      if (!this.selectRow) {
        if (!this.currentRow || this.currentRow.row_id !== row.row_id) {
          if (this.checkRows.length >= 1) {
            this.checkRows = [];
          }

          if (this.checkRows.indexOf(row.row_id) === -1) {
            this.checkRows.push(row.row_id);
            this.$emit('checkedRows', row.row_id, this.checkRows, row);
          }
        } else {
          this.checkRows = [];
          this.$emit('checkedRows', row.row_id, this.checkRows, row);
        }
      }

      this.rowClick(row);

      if (row === this.currentRow) {
        this.$refs.dataTable.setCurrentRow();
      }

      this.$nextTick(() => {
        if (this.$refs.dataTable) {
          const { currentRow } = this.$refs.dataTable.store.states;
          this.currentRow = currentRow;
        }
      });

      if (this.selectRow) {
        e && e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();
        this.changeRowCheck(row);
      }
    },
    computedRowClasss({ row }) {
      if (this.currentRow && (row.row_id === this.currentRow.row_id)) {
        if (this.checkRows.indexOf(row.row_id) === -1 && this.checkRows.length) {
          return '';
        } else {
          return 'current-row';
        }
      } else if (this.checkRows.indexOf(row.row_id) !== -1) {
        return 'current-row';
      } else {
        return '';
      }
    },
    changeRowCheck(row) {
      if (this.checkRows.indexOf(row.row_id) === -1) {
        this.checkRows.push(row.row_id);
      } else {
        this.checkRows = this.checkRows.filter(item => item !== row.row_id);
      }
      this.$emit('checkedRows', row.row_id, this.checkRows, row);
    },
    clearCheck() {
      this.checkRows = [];
      this.currentRow = {};
    },
    hidePopup() {
      this.$refs.popover.forEach(e => e.doClose());
    },
    fillEmptySpace() {
      if (this.dontFillEmpty) {
        return;
      }

      try {
        const { dataTable, emptySpace } = this.$refs;
        emptySpace.style.width = `${dataTable.$el.querySelector('table').offsetWidth}px`;
        const colgroup = emptySpace.querySelector('colgroup');
        if (colgroup) {
          colgroup.remove();
        }
        emptySpace.appendChild(dataTable.$el.querySelector('table colgroup')
          .cloneNode(true));

        const tr = emptySpace.querySelector('tr');
        tr.style.display = 'table-row';
        const originRows = dataTable.$el.querySelectorAll('table tbody .el-table__row ');
        let height = dataTable.$el.querySelector('.el-table__body-wrapper').offsetHeight;

        if (originRows) {
          height -= (originRows.length * originRows[0].offsetHeight);
          height = height < 0 ? 0 : height;
        }

        tr.style.height = `${height + 5}px`;

        if (!height) {
          tr.style.display = 'none';
        }
      } catch {
      }
    },
    setColumnFilters(filters) {
      this.isFiltersInit = false;
      this.columnFilters = {};
      this.fields.forEach((field) => {
        if (field.filterable) {
          this.$set(this.columnFilters, field.name, {
            visible: false,
            op: '',
            value: '',
          });
        }
      });
      if (filters) {
        this.columnFilters = this.$_.assign({}, this.columnFilters, filters);
      }
      this.isFiltersInit = true;
    },
    computeTableHeight() {
      let th = window.innerHeight - this.$el.offsetTop;

      if (!this.showTotal) {
        th -= this.padding;
      }

      if (th !== this.tableHeight) {
        // deactivated recalc issue fix
        if (this.$el.offsetTop || !this.tableHeight) {
          this.tableHeight = th < this.minTableHeight ? this.minTableHeight : th;
        }

        this.tableStatus.height = true;
        this.tableStatus.rows = false;
      }

      this.fillEmptySpace();
    },
    computeRowsCount() {
      const theader = this.$refs.dataTable.$el.querySelector('.el-table__header-wrapper');
      const tbody = this.$refs.dataTable.$el.querySelector('.el-table__body-wrapper');
      const ttotal = this.$refs.dataTable.$el.querySelector('.el-table__footer-wrapper');
      const trow = tbody.querySelector('.el-table__row td');
      let bodyHeight = tbody.offsetHeight;

      if (this.showTotal && ttotal.offsetHeight === 0) {
        bodyHeight -= 54;
      }
      if (this.showHeader && theader.offsetHeight === 0) {
        bodyHeight -= 36;
      }
      const rows = Math.floor(bodyHeight / (trow ? trow.offsetHeight : 32));

      if (rows > 10 && rows !== this.rowsCount) {
        this.$emit('change-rows-count', rows);
        this.tableStatus.rows = true;
      }
    },
    handleSort({ prop, order }, id) {
      if (this.customSorting) {
        if (this.lastSortProp === null) {
          this.lastSortProp = prop;
          this.lastSortOrder = order;
        }

        if (this.lastSortProp === prop) {
          this.sortProp = prop;
          this.sortOrder = order;
          const e = document.getElementsByClassName('el-table__header')[0];
          const removeClass = e.getElementsByTagName('th');

          for (let i = 0; i < removeClass.length; i += 1) {
            removeClass[i].classList.remove('asc', 'desc');
          }

          this.lastSortProp = prop;
          this.lastSortOrder = order;
        } else {
          this.sortProp = prop;
          this.sortOrder = this.lastSortOrder;
          this.$emit('sort', {
            prop: this.sortProp,
            order: this.lastSortOrder,
          });
          const e = document.getElementsByClassName('el-table__header')[0];
          const removeClass = e.getElementsByTagName('th');

          for (let i = 0; i < removeClass.length; i += 1) {
            removeClass[i].classList.remove('asc', 'desc');
          }

          this.lastSortProp = prop;
        }

        const addClass = document.getElementsByClassName(id)[0];
        if (addClass) {
          addClass.classList.add(this.sortOrder);
        }
      } else {
        if (this.lastOpenFilter) {
          this.columnFilters[this.lastOpenFilter].visible = false;
          this.lastOpenFilter = '';
        }

        if (prop && order) {
          if (prop !== this.sortProp || order.substring(0, order.length - 6) !== this.sortOrder) {
            this.sortProp = prop;
            this.sortOrder = order.substring(0, order.length - 6);
            this.lastSortProp = prop;
            this.lastSortOrder = order;
          } else {
            return;
          }
        } else {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
          this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
          this.$refs.dataTable.doLayout();
          this.$nextTick(() => {
            this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
            this.$refs.dataTable.doLayout();
          });
        }

        this.$emit('sort', {
          prop: this.sortProp,
          order: this.sortOrder,
        });
      }
    },
    getSummaryRow(param) {
      if (this.customTotalRow && this.customTotalRow.length > 0) {
        return this.customTotalRow;
      }
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0: {
            sums[index] = this.$t('dashboard.total');
            break;
          }
          default: {
            sums[index] = '';
            const field = this.fields.find(el => el.name === column.property);

            if (field) {
              sums[index] = field.format
                ? this.formatValue(
                  this.total[field.name],
                  field.format,
                  field.formatOptions,
                )
                : this.total[field.name];
            }
            break;
          }
        }
      });
      return sums;
    },
    renderHeader(h, { column, $index }) {
      const { filterable } = this.fields.find(el => el.name === column.property) || '';
      const _self = this;

      return h('div', {
        class: {
          'custom-header': true,
          [column.property]: true,
          'filterable-none': !filterable,
          'header-tooltip': this.$te(`${this.i18nPath}.fields.${column.property}.tooltip`),
        },
        on: {
          click() {
            if (filterable) {
              if (_self.lastOpenFilter) {
                _self.columnFilters[_self.lastOpenFilter].visible = false;
                _self.lastOpenFilter = '';
              }
            }
            if (column.sortable) {
              let order = _self.sortOrder === 'asc' ? 'desc' : 'asc';
              if (_self.lastSortProp !== column.property) {
                order = _self.lastSortOrder;
              }
            }
          },
        },
      }, [
        h('div', { class: 'text' }, [
          h('span', {
            class: 'title',
          }, column.label),
        ]),
        // сортинг ниже не используется - проверить(используется дефолтный из компонента)
        _self.customSorting
          ? h(
            'span',
            {
              class: 'caret-wrapper',
              slot: 'reference',
              on: {
                click(event) {
                },
              },
            },
            [
              h('i', {
                props: {
                  name: 'caret',
                  size: 10,
                  color: '#c0c4cc',
                },
                class: 'sort-caret ascending',
              }),
              h('i', {
                props: {
                  name: 'caret',
                  size: 10,
                  color: '#c0c4cc',
                },
                style: { marginTop: '-2px' },
                class: 'sort-caret descending',
              }),
            ],
          )
          : '',
        filterable && this.isFiltersInit ? h(
          'el-popover',
          {
            class: 'col-filter',
            props: {
              trigger: 'manual',
              value: this.columnFilters[column.property].visible,
            },
          },
          [h(
            'div',
            {
              class: 'col-filter-wrapper',
            },
            [h(
              'el-radio-group',
              {
                props: {
                  value: this.columnFilters[column.property].op,
                },
                on: {
                  input(event) {
                    _self.columnFilters[column.property].op = event;
                  },
                },
              },
              [h(
                'el-radio-button',
                {
                  props: {
                    label: '<',
                  },
                },
                '<',
              ), h(
                'el-radio-button',
                {
                  props: {
                    label: '=',
                  },
                },
                '=',
              ), h(
                'el-radio-button',
                {
                  props: {
                    label: '>',
                  },
                },
                '>',
              )],
            ),
            h(
              'el-input',
              {
                class: `filter-input filter-input-${$index}`,
                props: {
                  width: 144,
                  value: this.columnFilters[column.property].value,
                },
                on: {
                  input(e) {
                    let value = e;
                    const columns = _self.allMetrics.find(e => e.name === column.property);

                    if (columns.format === 'ncurrency' || columns.format === 'number' || columns.format === 'percent') {
                      if (value.trim() !== '') {
                        value = value.replace(/((?![0-9,.]).)*/g, '')
                          .replace(/,/g, '.');

                        if (value.includes('.')) {
                          const strArray = value.split('.');
                          value = `${(+strArray.slice(0, strArray.length - 1)
                            .join('')) || '0'}.${strArray.slice(strArray.length - 1)[0].slice(0, 2)}`;
                        } else {
                          value = `${+value}`;
                        }

                        e = value;
                      }
                    }

                    _self.columnFilters = {
                      ..._self.columnFilters,
                      [column.property]: {
                        ..._self.columnFilters[column.property],
                        value,
                      },
                    };
                  },
                },
              },
            ),
            // не удалять
            // : h(
            //   'el-date-picker',
            //   {
            //     class: 'filter-input datepicker-input',
            //     props: {
            //       width: 144,
            //       value: this.columnFilters[column.property].value,
            //       type: "date",
            //       format: "dd MMM yyyy"
            //     },
            //     on: {
            //       input(event) {
            //         _self.columnFilters[column.property].value = event;
            //       },
            //     },
            //   },
            // ),
            h(
              'div',
              {
                class: 'btns',
              },
              [h(
                'ui-button',
                {
                  props: {
                    color: 'red',
                  },
                  on: {
                    click() {
                      // if (_self.columnFilters[column.property] !== 'referred_created_at') {
                      _self.columnFilters[column.property].op = '';
                      _self.columnFilters[column.property].value = '';
                      _self.columnFilters[column.property].visible = false;
                      _self.lastOpenFilter = '';
                      _self.checkApplyFilter = true;
                      _self.$nextTick(() => {
                        _self.checkApplyFilter = false;
                      });
                      _self.$emit('filter', _self.columnFilters);
                      // } else {
                      //   _self.lastOpenFilter = '';
                      //   _self.checkApplyFilter = true;
                      //   _self.$nextTick(() => {
                      //     _self.checkApplyFilter = false;
                      //   });
                      //   _self.$emit('filters', _self.columnFilters);
                      // }
                    },
                  },
                },
                // this.$t('dashboard.reset'),
                [h(
                  'i',
                  {
                    attrs: {
                      class: 'far fa-times',
                    },
                  },
                ), h(
                  'span',
                  {
                    props: {

                    },
                  },
                  this.$t('dashboard.reset'),
                )],
              ), h(
                'ui-button',
                {
                  props: {
                    filled: true,
                    disabled: !(this.columnFilters[column.property].value && this.columnFilters[column.property].op),
                  },
                  on: {
                    click() {
                      _self.columnFilters[column.property].visible = false;
                      _self.lastOpenFilter = '';
                      _self.$emit('filter', _self.columnFilters);
                      _self.checkApplyFilter = true;
                      _self.$nextTick(() => {
                        _self.checkApplyFilter = false;
                      });
                    },
                  },
                },
                [h(
                  'i',
                  {
                    attrs: {
                      class: 'far fa-check',
                    },
                  },
                ), h(
                  'span',
                  {
                    props: {

                    },
                  },
                  this.$t('alerts.dataTable.apply'),
                )],
              )],
            )],
          ), h(
            'div',
            {
              class: {
                'filter-opener filter-opener-active': true,
                open: (this.lastOpenFilter === column.property
                  || (this.columnFilters[column.property].value && this.columnFilters[column.property].op)),
              },
              slot: 'reference',
              props: {},
              on: {
                click(event) {
                  event.stopPropagation(true);
                  event.target.closest('.cell')
                    .classList
                    .add('hover-once');
                  const set = !_self.columnFilters[column.property].visible;
                  _self.columnFilters[column.property].visible = set;
                  if (set) {
                    if (_self.lastOpenFilter) {
                      _self.columnFilters[_self.lastOpenFilter].visible = false;
                    }
                    _self.lastOpenFilter = column.property;
                  } else {
                    _self.lastOpenFilter = '';
                  }
                },
              },
            },
            [h(
              'i',
              {
                props: {
                  name: 'filter',
                  size: 8,
                },
                class: {
                  'fas fa-filter': true,
                },
              },
            )],
          )],
        ) : '',
        this.$te(`${this.i18nPath}.fields.${column.property}.tooltip`)
          ? h(
            'el-popover',
            {
              class: 'col-filter',
              props: {
                trigger: 'hover',
              },
              ref: 'filterWrapper',
            },
            [h(
              'div',
              {
                class: 'col-filter-wrapper',
              },
              this.$t(`${this.i18nPath}.fields.${column.property}.tooltip`),
            ), h(
              'div',
              {
                class: {
                  'filter-opener tip': true,
                },
                slot: 'reference',
                on: {
                  click(event) {
                    event.stopPropagation(true);
                  },
                },
              },
              [h(
                'i',
                {
                  props: {
                    name: 'info',
                    size: 8,
                  },
                  class: {
                    'fas fa-question': true,
                  },
                },
              )],
            )],
          ) : '',
      ]);
    },
    setSortVisibility(columns) {
      Array.from(document.querySelectorAll('.cell.hover-once'))
        .map(e => e.classList.remove('hover-once'));
      Array.from(document.querySelectorAll('.cell.hover'))
        .map(e => e.classList.remove('hover'));
      columns.map((col) => {
        if (col) {
          const header = document.querySelector(`.custom-header.${col}`);
          if (header) {
            const cell = header.closest('.cell');
            if (cell) {
              cell.classList.add('hover');
            }
          }
        }
      });
    },
    formatValue(value, format, options, row) {
      if (!value && value !== 0) {
        return '';
      }
      switch (format) {
        case 'device':
          return 'hello';
        case 'date': {
          const m = value._isAMomentObject
            ? value
            : this.$moment(
              value,
              !this.$_.isEmpty(options) && options.in ? options.in : '',
            );
          return m.isValid()
            ? m.locale(this.$i18n.locale)
              .format(!this.$_.isEmpty(options) && options.out
                ? options.out
                : 'DD.MM.YYYY HH:mm')
            : value;
        }
        case 'format-date': {
          const moment = this.$moment(value);
          return moment.isValid() ? moment.locale(this.$i18n.locale)
            .format('DD MMM YYYY') : '-';
        }
        case 'date-time': {
          const moment = this.$moment(value);
          return moment.isValid() ? moment.locale(this.$i18n.locale)
            .format('DD MMM YYYY HH:mm') : '-';
        }
        case 'date-time-sec': {
          const moment = this.$moment(value);
          return moment.isValid() ? moment.locale(this.$i18n.locale)
            .format('DD MMM YYYY HH:mm:ss') : '-';
        }
        case 'wallet-date': {
          const moment = this.$moment(value);
          return moment.isValid() ? moment.format('YYYY-MM-DD') : '-';
        }
        case 'number':
          return `${parseFloat(value)
            .toLocaleString('en-US')}`;
        case 'percent':
          return `${parseFloat(value / 100)
            .toLocaleString('en-US', {
              style: 'percent',
              maximumFractionDigits: 2,
            })}`;
        case 'odd':
          return `${parseFloat(value)
            .toLocaleString('en-US', {
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`;
        case 'currency':
          return `${value < 0 ? '-' : ''}$${Math.abs(this.$_.floor(value / 100, 2))
            .toLocaleString('en-US', {
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`;
        case 'ncurrency':
          return `${value < 0 ? '-' : ''}$${Math.abs(this.$_.floor(value, 4))
            .toLocaleString('en-US', {
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`;
        case 'ncurrencyUSD':
          return `${value < 0 ? '-' : ''}USD ${Math.abs(this.$_.floor(value, 4))
            .toLocaleString('en-US', {
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`;
        case '-ncurrency':
          return `${value === 0 ? '' : '-'}$${Math.abs(this.$_.floor(value, 4))
            .toLocaleString('en-US', {
              currency: 'USD',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`;
        case 'country': {
          const c = this.countries.filter(country => country.code === value);
          return c ? c[0].name : value;
        }
        case 'group': {
          const g = this.groups.filter(group => group.id === value);
          return g ? g[0].name : value;
        }
        case 'eventType': {
          if (value === 1) {
            return 'Single';
          } else if (value === 0) {
            return '';
          }
          return `Express-${value}`;
        }
        case 'event': {
          if (!Array.isArray(value)) return value;
          if (value.length === 1) {
            return value[0];
          } else if (value.length > 1) {
            return `Express(${row.events_count}/${row.coefficient})`;
          }
          return value;
        }
        default:
          return value;
      }
    },
    handleExpand(row, expRows) {
      this.$emit('expand-change', row, expRows);
    },
    handleCurrentRowChange(current) {
      this.$emit('current-change', current);
    },
    cleanUpSelection() {
      Array.from(document.querySelectorAll('.cell.hover-once'))
        .map(e => e.classList.remove('hover-once'));
      Array.from(document.querySelectorAll('.cell.hover'))
        .map(e => e.classList.remove('hover'));
    },
    contextMenu(row, column, event) {
      if (this.checkRows.length >= 1 && this.checkRows.find(e => e === row.row_id)) {

      } else if (this.checkRows.length >= 1) {
        this.checkRows = [];
      } else {
        this.checkRows.push(row.row_id);
      }
      this.currentRow = row;

      if (this.customContext) {
        event.preventDefault();
        this.$emit('context', row, event);
      }
    },
    copyToClipboard(value) {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copyDone = true;
      this.$alert.success(this.$t('profile.notifications.copied_message'));
    },
  },
  created() {
    if (!this.isStatic) {
      this.debouncedTableHeight = this.$_.debounce(
        this.computeTableHeight.bind(this),
        300,
      );
    }
    // eslint-disable-next-line
    this.sortProp = this.sort.prop || (this.disableSort ? '' : this.fields[0] ? this.fields[0].name : '');
    this.sortOrder = this.sort.order || (this.disableSort ? '' : 'desc');
    document.addEventListener('click', (e) => {
      if (this.lastOpenFilter) {
        let targetEl = e.target;
        do {
          if (targetEl.classList && targetEl.classList.contains('el-popover')) {
            return;
          }
          targetEl = targetEl.parentNode;
        } while (targetEl);
        this.columnFilters[this.lastOpenFilter].visible = false;
        this.lastOpenFilter = '';
      }
      this.fillEmptySpace();
    });

    window.onmouseup = () => {
      setTimeout(() => {
        this.fillEmptySpace();
      }, 100);
    };

    if (!this.$_.isEmpty(this.colFilters)) {
      this.columnFilters = this.$_.cloneDeep(this.colFilters);
    }
    setTimeout(() => {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.debouncedUpdateLayout();
      }
    }, 3000);
  },
  mounted() {
    if (this.rowCheck) {
      document.addEventListener('click', this.pressCtrl, true);
      document.addEventListener('keydown', this.switchMultiSelect, true);
      document.addEventListener('keyup', this.switchMultiSelect, true);
    }

    if (!this.isStatic) {
      this.computeTableHeight();
      window.addEventListener('resize', this.debouncedTableHeight);
      this.$eventBus.$on('connection-enabled', this.debouncedTableHeight);
      this.$watch('tableHeight', (nv, ov) => {
        if (nv !== ov) {
          this.computeRowsCount();
        }
        this.fillEmptySpace();
      });
    }
    this.$emit('init');

    setTimeout(() => {
      this.fillEmptySpace();
    });

    this.$nextTick(() => {
      const sortClick = this.$refs.dataTable.$refs.tableHeader.handleSortClick;
      const _self = this;
      this.$refs.dataTable.$refs.tableHeader.handleSortClick = function (event, column, givenOrder) {
        let sortOrder = _self.sortOrder && `${_self.sortOrder}ending` || undefined;
        if (_self.sortProp === column.property) {
          sortOrder = undefined;
        }
        try {
          Array.from(document.querySelectorAll('.cell.hover'))
            .map(e => e.classList.remove('hover'));
          event.target.closest('.cell')
            .classList
            .add('hover');
        } catch (e) {
        }
        return sortClick(event, column, givenOrder || sortOrder);
      }.bind(this.$refs.dataTable.$refs.tableHeader);
    });
  },
  activated() {
    if (!this.isStatic) {
      this.debouncedTableHeight();
    }
  },
  beforeDestroy() {
    if (!this.isStatic) {
      window.removeEventListener('resize', this.debouncedTableHeight);
    }
    window.onmouseup = null;
  },
  destroyed() {
    document.removeEventListener('click', this.pressCtrl, true);
    document.removeEventListener('keydown', this.switchMultiSelect, true);
    document.removeEventListener('keyup', this.switchMultiSelect, true);
  },
  updated() {
    if (!this.isStatic) {
      this.debouncedTableHeight();
    }
    this.fillEmptySpace();
  },
};
</script>

<style lang="scss">
.ui-table {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px !important;

  .el-tooltip.icon__wrapper {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-decoration: none;

    &.text-green:hover {
      background: #4d997e30;
    }

    &.text-orange:hover {
      background: rgba(205, 138, 12, .2);
    }

    &.text-red {
      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &:hover {
        background: #ff7d8d33;
      }
    }

    &.text-info:hover {
      background: #3b94e336;
    }

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }

  thead .cell {
    font-size: 12px;
    font-weight: 600;

    & > .custom-header {
      overflow: hidden;
      text-overflow: ellipsis;

      & > .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .filterable-none + .caret-wrapper {
      right: 0px !important;
    }
  }

  tbody {
    .el-table__row .divider {
      display: none !important;
    }

    .el-table__cell .cell {
      max-width: 100%;
    }
  }

  &.extend-height {
    .el-table {
      height: 100% !important;
      max-height: 100% !important;

      .el-table__body-wrapper {
        height: 100% !important;
      }
    }
  }

  &.table-actions {
    .el-table__row td:last-child {
      padding: 0px;
    }
  }

  &.fit-content {
    .el-table {
      height: fit-content;
      max-height: fit-content;
    }
  }

  .row-value.actions {
    span, a {
      cursor: pointer;
    }
  }

  .fa-filter:before {
    content: "\f0b0";
  }

  @media (max-width: 650px) {
    display: none;
  }
}

.el-table .el-table__body-wrapper, .toolbar.tabs .inner {
  &::-webkit-scrollbar-button {
    background-image: unset !important;
    background-repeat: unset !important;
  }

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7 !important;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: unset !important;
    border-radius: unset !important;
  }

  &::-webkit-resizer {
    background-repeat: no-repeat;
    width: 4px;
    height: 0;
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
}

.input-element {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 10%;
  max-width: 100%;
  flex: 10 1 auto;
  box-sizing: border-box;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
  outline: none;
  font-size: 13px;
  padding: 8px 10px;
  background: transparent;

  &:focus{
    border: 1px solid var(--active-color) !important;
  }
}
</style>
