<template>
  <section
    class="media-buying-detail media-buying-detail-tb public-table media-buying-ad"
    :class="{
      wavebg: loading,
    }"
  >
    <payments-view-table
      ref="dataTable"
      :statusChannel="statusChannel"
      :statusCampaign="statusCampaign"
      :activeTab="activeTab"
      :table-sorting="sortings.payments"
      @update="update"
    />
  </section>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import PaymentsViewTable from 'src/views/Dashboard/MediaBuying/PaymentsView/PaymentsViewTable';
import { debounce } from 'lodash';

const fieldMap = {
  post_status: 'status',
  post_type: 'type',
  period: 'post_period_start',
  post_sum: 'amount',
  payment_status: 'status',
  payment_sum: 'amount_usd',
};

const defaultSortings = {
  page: 1,
  pageSize: 20,
  search: '',
  prop: 'created_at',
  order: 'desc',
  needs_attention_first: true,

  moderation_status: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.moderationStatus || '',
  payout_status: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.payoutStatus || '',

  created_at_from: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.created_at_from
    || '',
  created_at_to: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.created_at_to
    || '',
  period: JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'))?.period || 2,

};

const fetch = async (ctx, params) => {
  ctx.cacheParams = params;

  const paymentsParams = {
    search: params.search || '',
    limit: params.limit || defaultSortings.pageSize,
    offset: (params.offset * params.limit) || 0,

    sort_column: fieldMap[params.prop]
      || params.prop || defaultSortings.prop,

    sort_dir: params.order || defaultSortings.order,
    needs_attention_first: params.needs_attention_first !== undefined
      ? params.needs_attention_first : defaultSortings.needs_attention_first,

    moderation_status: params.moderation_status !== undefined ? params.moderation_status : defaultSortings.moderation_status,
    payout_status: params.payout_status !== undefined ? params.payout_status : defaultSortings.payout_status,

    created_at_from: params.created_at_from !== undefined ? params.created_at_from : defaultSortings.created_at_from,
    created_at_to: params.created_at_to !== undefined ? params.created_at_to : defaultSortings.created_at_to,
    period: params.period !== undefined ? params.period : defaultSortings.period,

    campaign_id: null,
  };
  paymentsParams.sort_column = paymentsParams.sort_column !== 'payment_method'
    ? paymentsParams.sort_column
    : 'full_payment_method';
  const payments = await ctx.$store.dispatch('getPayments', paymentsParams);
  return [payments];
};

export default {
  name: 'PaymentsView',

  fetch,
  components: {
    PaymentsViewTable,
  },
  mixins: [UrlMixin, UrlMixinHooks, InjectPlugins, pageSizeMixin],
  props: ['passedParams'],

  data() {
    return {
      cacheParams: {},
      subRootId: null,
      balances: {
        approved: {},
        paid: {},
        spent: {},
        pending: {},
        total: {},
      },
      isLoadingCampaign: true,
      payment_requests: 'parent',
      cost_traffic_tracking: 'parent',
      loading: false,
      activeTab: '',
      campaign_name: '',
      campaign_description: '',
      channel_id: '',
      campaign_id: '',
      balance_sum: '',
      spent_sum: '',
      paid_sum: '',
      payments_on_review_sum: '',
      cost_model: '',
      sortings: defaultSortings,
      statusChannel: 'active',
      statusCampaign: 'active',
      interval: null,
      listener: '',
      querySum: {
        query: {},
        sub_affiliate_id: null,
        passedPeriod: {
          period: 2,
          from: '',
          to: '',
        },
        period: {},
        filters: [],
      },
    };
  },

  watch: {
    urlQuery(v) {
      if (this.$route.name === 'routes.mediaBuyingPayments' && v.activeTab !== '' && Object.keys(v).length) {
        localStorage.setItem('/dashboard/media-buying-payments', JSON.stringify(v));
      }
    },
  },

  computed: {
    cacheData() {
      return {
        ...this.cacheParams,
        moderationStatus: this.cacheParams.moderation_status,
        payoutStatus: this.cacheParams.payout_status,
      };
    },

    countPosts() {
      return this.$store.state.mediaBuying.postsCount;
    },
    countPayments() {
      return (
        this.$store.state.mediaBuying.paymentsCountModeration
        + this.$store.state.mediaBuying.paymentsCountPayout
      );
    },
    currency() {
      return this.currencyFormatter(
        this.$store.state.mediaBuying.campaign.payments_currency_code,
      );
    },
  },

  methods: {
    initDatepicker(params) {
      if (params?.created_at_from) {
        this.$refs.dataTable.setPeriod({
          from: params.created_at_from,
          to: params.created_at_to,
          period: params.period,
        });
      } else {
        this.$refs.dataTable.setPeriod(this.querySum.passedPeriod);
      }
    },

    fetchData: debounce(
      async function fetchData(params, silent) {
        if (!silent) this.loading = true;

        try {
          await fetch(this, params);
          this.cacheQuery();
        } finally {
          this.loading = false;
        }
      },
      1,
    ),

    async update(params, querySum, silent) {
      this.sortings = {
        sort_column: params.prop,
        sort_dir: params.order,
      };

      this.search = params.search;
      this.offset = params.offset;
      this.limit = params.limit;

      if (querySum) {
        this.querySum = querySum;
      }

      this.fetchData({
        sortings: this.sortings,
        ...params,
      }, silent);
    },

    getPayments(params) {
      this.$store.dispatch('getPayments', {
        ...params,
        campaign_id: this.$route.params.id,
      });
    },

    handleTabClick() {
      this.cacheQuery();
    },

    currencyFormatter(code, amount) {
      const miscCurrencies = this.$store.state.misc.currencies;
      const currency = miscCurrencies.find(
        c => (code ? code.toLowerCase() : null) === c.code?.toLowerCase(),
      );

      if (!Number.isFinite(amount)) {
        if (!code) {
          return '';
        }

        return currency ? currency.code.toUpperCase() : '';
      } else {
        amount = amount.toFixed(2);
        const amountData = amount.split('.');
        const result = (+amountData[0]).toLocaleString();

        if (!code) {
          return `${result}.${amountData[1]}`;
        }

        return currency
          ? `${currency.code.toUpperCase()} ${result}.${amountData[1]}`
          : `${result}.${amountData[1]}`;
      }
    },
    clearTooltip() {
      document.addEventListener(
        'mouseup',
        (this.listener = (e) => {
          const tooltip = document.querySelectorAll('.el-tooltip__popper');

          tooltip.forEach((e, i) => {
            tooltip[i].style.display = 'none';
          });
        }),
      );
    },
  },
  created() {
    this.$store.dispatch('getSubAffiliates');
    this.$store.dispatch('getPromocodeOffers');
    this.$store.dispatch('getTrafficSources', {});
    this.$store.dispatch('getCurrencies', {});
  },

  async activated() {
    this.loading = true;
    const q = JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'));

    this.initDatepicker(q);
    this.fetchData({
      activeTab: this.activeTab,
      sortings: this.sortings,
      querySum: this.querySum,
      ...q,
    });
    this.$refs.dataTable.initFilter(q);

    this.clearTooltip();
  },

  mounted() {
    const q = JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'));
    this.$refs.dataTable.initFilter(q);
    setTimeout(() => this.initDatepicker(q), 1000);

    this.$store.dispatch('uploadSettings');
    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.mediaBuyingPayments') {
        const q = JSON.parse(localStorage.getItem('/dashboard/media-buying-payments'));
        this.$refs.dataTable.initFilter(q);
        this.initDatepicker(q);

        this.fetchData({
          activeTab: this.activeTab,
          sortings: this.sortings,
          querySum: this.querySum,
          ...q,
        });
      }
    });
    this.clearTooltip();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  deactivated() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/mediabuying/mediabuyingdetail';
</style>
