import { debounce } from 'lodash';

export default {
  getWallets({ commit, dispatch }) {
    return this.$api.getWallets().then((data) => {
      commit('saveWallets', data);
      commit('countBalance');
      dispatch('getWalletStats');
      return data;
    });
  },

  getWalletStats({ commit }) {
    return this.$api.getPaymentsHistorySummary().then((data) => {
      commit('saveWalletStats', data.payload);
      return data;
    });
  },

  getWalletHistory({ state, commit }, filters) {
    const id = state.wallets[0].id;

    return this.$api.getWalletHistory(id, filters).then((data) => {
      commit('saveWalletHistory', data.payload);
      return data;
    });
  },

  getWalletHistoryLazy: debounce(({ dispatch }, filters) => dispatch('getWalletHistory', filters), 100),

  getWalletHistoryCount({ state, commit }, filters) {
    const id = state.wallets[0].id;

    return this.$api.getWalletHistoryCount(id, filters).then((data) => {
      commit('saveWalletHistoryCount', data.payload);
      return data;
    });
  },

  getWalletHistoryCountLazy: debounce(({ dispatch }, filters) => dispatch('getWalletHistoryCount', filters), 100),
};
