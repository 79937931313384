import qs from 'qs';
import * as settingsAPI from './api/settingsAPI';
import * as mediaBuyingAPI from './api/mediaBuyingAPI';
import * as miscAPI from './api/miscAPI';
import * as mediaAPI from './api/mediaAPI';
import * as trafficSourcesAPI from './api/trafficSourcesAPI';

export default class ApiConnector {
  constructor($affiliatesApi, $miscApi) {
    this.affiliates = $affiliatesApi;
    this.misc = $miscApi;

    // Settings
    this.getSettings = settingsAPI.getSettings.bind(this);
    this.getFinalPages = settingsAPI.getFinalPages.bind(this);

    // Media Buying
    // this.getChannels = mediaBuyingAPI.getChannels.bind(this);
    // this.createChannel = mediaBuyingAPI.createChannel.bind(this);
    // this.editChannel = mediaBuyingAPI.editChannel.bind(this);
    // this.getChannel = mediaBuyingAPI.getChannel.bind(this);
    // this.archiveChannel = mediaBuyingAPI.archiveChannel.bind(this);
    // this.restoreChannel = mediaBuyingAPI.restoreChannel.bind(this);
    this.getCampaignsCount = mediaBuyingAPI.getCampaignsCount.bind(this);
    this.getCampaigns = mediaBuyingAPI.getCampaigns.bind(this);
    this.getCampaignsAttentionCounters = mediaBuyingAPI.getCampaignsAttentionCounters.bind(
      this,
    );
    this.createCampaign = mediaBuyingAPI.createCampaign.bind(this);
    this.getCampaignsTrackable = mediaBuyingAPI.getCampaignsTrackable.bind(
      this,
    );
    this.editCampaign = mediaBuyingAPI.editCampaign.bind(this);
    this.getCampaign = mediaBuyingAPI.getCampaign.bind(this);
    this.getCampaignSummaryReport = mediaBuyingAPI.getCampaignSummaryReport.bind(
      this,
    );
    this.postCampaignArchive = mediaBuyingAPI.postCampaignArchive.bind(this);
    this.postCampaignActivate = mediaBuyingAPI.postCampaignActivate.bind(this);

    this.getPayments = mediaBuyingAPI.getPayments.bind(this);
    this.createPayment = mediaBuyingAPI.createPayment.bind(this);
    this.editPayment = mediaBuyingAPI.editPayment.bind(this);
    this.getPayment = mediaBuyingAPI.getPayment.bind(this);
    this.postPaymentComments = mediaBuyingAPI.postPaymentComments.bind(this);
    this.getPaymentComment = mediaBuyingAPI.getPaymentComment.bind(this);
    this.postPaymentMarkAsRead = mediaBuyingAPI.postPaymentMarkAsRead.bind(
      this,
    );
    this.postPaymentMarkAsUnread = mediaBuyingAPI.postPaymentMarkAsUnread.bind(
      this,
    );

    this.getPosts = mediaBuyingAPI.getPosts.bind(this);
    this.createPost = mediaBuyingAPI.createPost.bind(this);
    this.editPost = mediaBuyingAPI.editPost.bind(this);
    this.getPost = mediaBuyingAPI.getPost.bind(this);
    this.deletePost = mediaBuyingAPI.deletePost.bind(this);
    this.postToReview = mediaBuyingAPI.postToReview.bind(this);
    this.setPostComments = mediaBuyingAPI.setPostComments.bind(this);
    this.getPostComment = mediaBuyingAPI.getPostComment.bind(this);
    this.getPostCommentsCount = mediaBuyingAPI.getPostCommentsCount.bind(this);
    this.postPostMarkAsRead = mediaBuyingAPI.postPostMarkAsRead.bind(this);
    this.postPostMarkAsUnread = mediaBuyingAPI.postPostMarkAsUnread.bind(this);
    this.getPaymentMethod = mediaBuyingAPI.getPaymentMethod.bind(this);

    this.uploadFiles = mediaBuyingAPI.uploadFiles.bind(this);
    this.uploadSettings = mediaBuyingAPI.uploadSettings.bind(this);
    this.updateComments = mediaBuyingAPI.updateComments.bind(this);
    this.deleteComments = mediaBuyingAPI.deleteComments.bind(this);
    this.getTrackingUrl = mediaBuyingAPI.getTrackingUrl.bind(this);
    this.getRedirectUrl = mediaBuyingAPI.getRedirectUrl.bind(this);
    this.getMarketingCampaigns = mediaBuyingAPI.getMarketingCampaigns.bind(
      this,
    );
    this.getLandings = mediaBuyingAPI.getLandings.bind(this);

    // Misc
    this.getGeoData = miscAPI.getGeoData.bind(this);
    this.getCountriesCount = miscAPI.getCountriesCount.bind(this);
    this.getCountries = miscAPI.getCountries.bind(this);
    this.getRecaptchaKey = miscAPI.getRecaptchaKey.bind(this);
    this.getPaymentMethodsCount = miscAPI.getPaymentMethodsCount.bind(this);
    this.getTimezonesCount = miscAPI.getTimezonesCount.bind(this);
    this.getTimezones = miscAPI.getTimezones.bind(this);
    this.getMessengers = miscAPI.getMessengers.bind(this);
    this.getLanguages = miscAPI.getLanguages.bind(this);
    this.getChannelIcons = miscAPI.getChannelIcons.bind(this);
    this.getCurrencies = miscAPI.getCurrencies.bind(this);
    this.getCustomerCurrencies = miscAPI.getCustomerCurrencies.bind(this);
    this.getPaymentMethodCount = miscAPI.getPaymentMethodCount.bind(this);
    this.getPostPlatforms = miscAPI.getPostPlatforms.bind(this);
    this.getPostPlatformsCount = miscAPI.getPostPlatformsCount.bind(this);
    this.getTagTemplates = miscAPI.getTagTemplates.bind(this);
    this.getMiscSettings = miscAPI.getMiscSettings.bind(this);

    // Media
    this.getMarketingMaterial = mediaAPI.getMarketingMaterial.bind(this);
    this.getMarketingMaterials = mediaAPI.getMarketingMaterials.bind(this);
    this.getMarketingMaterialsCount = mediaAPI.getMarketingMaterialsCount.bind(
      this,
    );
    this.getMarketingMaterialTag = mediaAPI.getMarketingMaterialTag.bind(this);
    this.getMarketingMaterialLandingUrl = mediaAPI.getMarketingMaterialLandingUrl.bind(
      this,
    );
    this.getMarketingMaterialBannerScript = mediaAPI.getMarketingMaterialBannerScript.bind(
      this,
    );
    this.getSites = mediaAPI.getSites.bind(this);

    // Traffic Sources
    this.getTags = trafficSourcesAPI.getTags.bind(this);
    this.getTagsCount = trafficSourcesAPI.getTagsCount.bind(this);
    this.createTag = trafficSourcesAPI.createTag.bind(this);
    this.updateTag = trafficSourcesAPI.updateTag.bind(this);
    this.getTag = trafficSourcesAPI.getTag.bind(this);
    this.deleteTag = trafficSourcesAPI.deleteTag.bind(this);
  }

  // DedicatedDomain
  getDedicatedDomain({
    sort_column, sort_dir, search, limit, offset,
  }) {
    return this.affiliates
      .get('dedicated-domains', {
        params: {
          sort_column,
          sort_dir,
          search,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getDedicatedDomainCount({ search }) {
    return this.affiliates
      .get('dedicated-domains/count', {
        params: {
          search,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  putDedicatedDomain(id, payload) {
    return this.affiliates
      .put(`dedicated-domains/${id}`, payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getPaymentMethods() {
    return this.affiliates
      .get('payment-methods')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Affiliate offers
  getAffiliateOffers({
    sort_column,
    sort_dir,
    search,
    limit,
    offset,
    sub_affiliate_id,
  }) {
    return this.affiliates
      .get('affiliate-offers', {
        params: {
          sort_column,
          sort_dir,
          search,
          limit,
          offset,
          sub_affiliate_id,
        },
      })
      .then((res) => {
        res.data.payload.map((e) => {
          e.title = e.name;
          e.name = e.full_name;
        });
        return Promise.resolve(res.data);
      })
      .catch(err => Promise.reject(err));
  }

  getAffiliateOffersCount({ search, sub_affiliate_id }) {
    return this.affiliates
      .get('affiliate-offers/count', {
        params: {
          search,
          sub_affiliate_id,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getAffiliateOffer(id) {
    return this.affiliates
      .get(`affiliate-offers/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // API keys
  getApiKeys({
    sort_column, sort_dir, status, limit, offset,
  }) {
    return this.affiliates
      .get('api-keys', {
        params: {
          sort_column,
          sort_dir,
          status,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postApiKeys(body) {
    return this.affiliates
      .post('api-keys', body)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getApiKey(id) {
    return this.affiliates
      .get(`api-keys/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  putApiKey(id, body) {
    return this.affiliates
      .put(`api-keys/${id}`, body)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  deleteApiKey(id) {
    return this.affiliates
      .delete(`api-keys/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getApiKeysCount({ status }) {
    return this.affiliates
      .get('api-keys/count', {
        params: {
          status,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postAuthLogin(payload) {
    return this.affiliates
      .post('auth/login', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postAuthRegister(payload) {
    return this.affiliates
      .post('auth/register', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postVerifyEmail(payload) {
    return this.affiliates
      .post('auth/verify-email', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postVerifyAdditionalEmail(payload) {
    return this.affiliates
      .post(
        'auth/verify-domain-service-notifications-additional-email',
        payload,
      )
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postAuthTotp(payload) {
    return this.affiliates
      .post('auth/totp', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postAuthForgotPassword(payload) {
    return this.affiliates
      .post('auth/forgot-password', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postAuthResetPassword(payload) {
    return this.affiliates
      .post('auth/reset-password', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postAuthLogout() {
    return this.affiliates
      .post('auth/logout')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Offers
  getOffers({
    sort_column, sort_dir, search, limit, offset,
  }) {
    return this.affiliates
      .get('offers', {
        params: {
          sort_column,
          sort_dir,
          search,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getOffersCount() {
    return this.affiliates
      .get('offers/count')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getOffer(id) {
    return this.affiliates
      .get(`offers/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postOffersRequestAccess(payload) {
    return this.affiliates
      .post('offers/request-access', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Payment history
  getPaymentsHistory({
    sort_column, sort_dir, status, limit, offset,
  }) {
    return this.affiliates
      .get('payments-history', {
        sort_column,
        sort_dir,
        status,
        limit,
        offset,
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getPaymentsHistoryCount({ moderation_status }) {
    return this.affiliates
      .get('payments-history/count', {
        params: {
          moderation_status,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getPaymentsHistorySummary() {
    return this.affiliates
      .get('payments-history/summary')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getPaymentsHistoryOrder() {
    return this.affiliates
      .get('payments-history/order-payment-settings')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postPaymentsHistoryCancelPaymentRequest(payload) {
    return this.affiliates
      .post('payments-history/cancel-payment-request', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postPaymentsHistoryOrderPayment(payload) {
    return this.affiliates
      .post('payments-history/order-payment', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Players
  getPlayers({
    sub_affiliate_id,
    sort_column,
    sort_dir,
    search,
    limit,
    offset,
  }) {
    return this.affiliates
      .get('players', {
        params: {
          sub_affiliate_id,
          sort_column,
          sort_dir,
          search,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getPlayersCount({ sub_affiliate_id, search }) {
    return this.affiliates
      .get('players/count', {
        params: {
          sub_affiliate_id,
          search,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Profile
  getProfile() {
    return this.affiliates
      .get('profile')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  putProfile(payload) {
    return this.affiliates
      .put('profile', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postProfileUpdatePassword(payload) {
    return this.affiliates
      .post('profile/update-password', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postProfileUpdateLanguage(payload) {
    return this.affiliates
      .post('profile/update-language', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postProfileUpdatePaymentMethod(payload) {
    return this.affiliates
      .post('profile/update-payment-method', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Public profile
  putPublicProfile(payload) {
    return this.affiliates
      .put('profile/public-profile', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  deletePublicProfile() {
    return this.affiliates
      .delete('profile/public-profile')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Promo codes
  getPromoCode({
    from,
    to,
    sort_column,
    sort_dir,
    search,
    status,
    limit,
    offset,
    media_buying_campaign_id,
  }) {
    return this.affiliates
      .get('promo-codes', {
        params: {
          from,
          to,
          sort_column,
          sort_dir,
          search,
          status,
          limit,
          offset,
          media_buying_campaign_id,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postPromoCode(payload) {
    return this.affiliates
      .post('promo-codes', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getPromoCodeCount({ search, status, media_buying_campaign_id }) {
    return this.affiliates
      .get('promo-codes/count', {
        params: {
          search,
          status,
          media_buying_campaign_id,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  deletePromoCode(id) {
    return this.affiliates
      .delete(`promo-codes/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  putPromoCode(id, payload) {
    return this.affiliates
      .put(`promo-codes/${id}`, payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Affiliates reports
  getReport({
    from,
    to,
    limit,
    offset,
    timezone,
    affiliate_offer_id,
    player_id,
    traffic_source_id,
    bet_category_name,
    bet_game_name,
    country_code,
    subid1,
    subid2,
    subid3,
    subid4,
    subid5,
    subid6,
    subid7,
    subid8,
    subid9,
    subid10,
    sub_affiliate_id,
    landing_id,
    referrer,
    referrer_domain,
    affiliate_player_promo_code_id,
    marketing_material_id,
    language_code,
    device_type_id,
    continent_code,
    state_name,
    city_name,
    isp_id,
    connection_type_id,
    device_vendor,
    device_model,
    os,
    os_version,
    browser,
    browser_version,
    date,
    hour,
    year,
    month,
    day_of_month,
    day_of_week,
    minute,
    start_of_week,
    start_of_month,
    start_of_quarter,
    start_of_year,
    start_of_minute,
    player_country_code,
    group_by,
    columns,
    additional_columns,
    sort_by,
    sort_dir,
    export_format,
    search,
    language,
    device_type,
    having,
    players_filter,
    filter,
  }) {
    if (!from || !to) {
      throw 'Start Timestamp (from) and End Timestamp (to) are required';
    }
    if (!group_by || !sort_by || !sort_dir) {
      throw "'group_by', 'sort_by', 'sort_dir' are required";
    }

    if (!columns) {
      throw "'columns' is required, <array>";
    }
    if (having) {
      having = JSON.stringify(having);
    }
    return this.affiliates
      .get('reports', {
        params: {
          from,
          to,
          limit,
          offset,
          timezone,
          affiliate_offer_id,
          player_id,
          traffic_source_id,
          bet_category_name,
          bet_game_name,
          country_code,
          subid1,
          subid2,
          subid3,
          subid4,
          subid5,
          subid6,
          subid7,
          subid8,
          subid9,
          subid10,
          sub_affiliate_id,
          landing_id,
          referrer,
          referrer_domain,
          affiliate_player_promo_code_id,
          marketing_material_id,
          language_code,
          device_type_id,
          continent_code,
          state_name,
          city_name,
          isp_id,
          connection_type_id,
          device_vendor,
          device_model,
          os,
          os_version,
          browser,
          browser_version,
          date,
          hour,
          year,
          month,
          day_of_month,
          day_of_week,
          minute,
          start_of_week,
          start_of_month,
          start_of_quarter,
          start_of_year,
          start_of_minute,
          player_country_code,
          group_by,
          columns,
          additional_columns,
          sort_by,
          sort_dir,
          export_format,
          search,
          language,
          device_type,
          having,
          players_filter,
          filter,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then((res) => {
        res.data.response = res;
        return Promise.resolve(res.data);
      })
      .catch(err => Promise.reject(err));
  }

  postReport(query) {
    return this.affiliates
      .post('reports', query)
      .then((res) => {
        res.data.response = res;
        return Promise.resolve(res.data);
      })
      .catch(err => Promise.reject(err));
  }

  getReportOptions({
    group_by,
    from,
    to,
    timezone,
    sub_affiliate_id,
    country_code,
    player_id,
    affiliate_offer_id,
    traffic_source_id,
    subid1,
    subid2,
    subid3,
    subid4,
    subid5,
    filter,
  }) {
    if (!from || !to) {
      throw 'Start Timestamp (from) and End Timestamp (to) are required';
    }
    if (!group_by) {
      throw 'You must specify grop_by parameter';
    }

    return this.affiliates
      .get('reports', {
        params: {
          group_by,
          columns: ['visits_count', 'deposits_all_sum'],
          limit: 1000,
          offset: 0,
          sort_by: 'visits_count',
          sort_dir: 'desc',
          from,
          to,
          timezone,
          sub_affiliate_id,
          country_code,
          player_id,
          affiliate_offer_id,
          traffic_source_id,
          subid1,
          subid2,
          subid3,
          subid4,
          subid5,
          filter,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getReportSupportedMetrics(params = {}) {
    return this.affiliates
      .get('reports/supported-metrics', { params })
      .then(res => Promise.resolve(res.data))
      .catch(res => Promise.reject(res));
  }

  getReportSupportedDimentions() {
    return this.affiliates
      .get('reports/supported-dimensions')
      .then(res => Promise.resolve(res.data))
      .catch(res => Promise.reject(res));
  }

  getDashboardEarnings() {
    return this.affiliates
      .get('reports/dashboard-earnings')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  addBookmarks(payload) {
    return this.affiliates
      .post('/bookmarks', { bookmarks: payload })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getBookmarks() {
    return this.affiliates
      .get('/bookmarks')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Sub affiliates
  getSubAffiliatesCount({ search }) {
    return this.affiliates
      .get('sub-affiliates/count', {
        params: {
          search,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getSubAffiliates({
    sort_column, sort_dir, search, limit, offset,
  }) {
    return this.affiliates
      .get('sub-affiliates', {
        params: {
          sort_column,
          sort_dir,
          search,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getSubAffiliatesReferralUrls() {
    return this.affiliates
      .get('sub-affiliates/referral-urls')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // TOTP
  postTotpQrCode(payload) {
    return this.affiliates
      .post('totp/qr-code', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postTotpEnable(payload) {
    return this.affiliates
      .post('totp/enable', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postTotpDisable(payload) {
    return this.affiliates
      .post('totp/disable', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTotpBackupCodesCount() {
    return this.affiliates
      .get('totp/backup-codes/count')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postTotpBackupCodesGenerateNewCodes(payload) {
    return this.affiliates
      .post('totp/backup-codes/generate-new-codes', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTotpBackupCodes() {
    return this.affiliates
      .get('totp/backup-codes')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Traffic sources postbacks
  getTrafficSourcePostbacksCount({
    search,
    traffic_source_id,
    affiliate_offer_id,
  }) {
    return this.affiliates
      .get('traffic-source-postbacks/count', {
        params: {
          search,
          traffic_source_id,
          affiliate_offer_id,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postTrafficSourcePostbacks(payload) {
    return this.affiliates
      .post('traffic-source-postbacks', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTrafficSourcePostbacks({
    sort_column,
    sort_dir,
    traffic_source_id,
    affiliate_offer_id,
    search,
    limit,
    offset,
  }) {
    return this.affiliates
      .get('traffic-source-postbacks', {
        params: {
          sort_column,
          sort_dir,
          traffic_source_id,
          affiliate_offer_id,
          search,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  deleteTrafficSourcesPostback(id) {
    return this.affiliates
      .delete(`traffic-source-postbacks/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTrafficSourcesPostback(id) {
    return this.affiliates
      .get(`traffic-source-postbacks/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  putTrafficSourcesPostback(id, payload) {
    return this.affiliates
      .put(`traffic-source-postbacks/${id}`, payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTrafficSourcePostbackRequestsLog(
    id,
    {
      sort_column,
      sort_dir,
      traffic_source_id,
      affiliate_offer_id,
      search,
      limit,
      offset,
      from,
      to,
    },
  ) {
    return this.affiliates
      .get(`/traffic-source-postbacks/${id}/requests-log`, {
        params: {
          sort_column,
          sort_dir,
          traffic_source_id,
          affiliate_offer_id,
          search,
          limit,
          offset,
          from,
          to,
        },
      })
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err));
  }

  getTrafficSourcePostbackRequestsLogCount(id, { from, to, timezone }) {
    return this.affiliates
      .get(`/traffic-source-postbacks/${id}/requests-log/count`, {
        params: {
          from,
          to,
          timezone,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // traffic source
  getTrafficSorceCount({ sub_affiliate_id, search }) {
    return this.affiliates
      .get('traffic-sources/count', {
        params: {
          sub_affiliate_id,
          search,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  postTrafficSources(payload) {
    return this.affiliates
      .post('traffic-sources', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTrafficSources({
    sub_affiliate_id,
    sort_column,
    sort_dir,
    search,
    limit,
    offset,
  }) {
    return this.affiliates
      .get('traffic-sources', {
        params: {
          sub_affiliate_id,
          sort_column,
          sort_dir,
          search,
          limit,
          offset,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  deleteTrafficSource(id) {
    return this.affiliates
      .delete(`traffic-sources/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getTrafficSource(id) {
    return this.affiliates
      .get(`traffic-sources/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  putTrafficSource(id, payload) {
    return this.affiliates
      .put(`traffic-sources/${id}`, payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Wallets
  getWallets() {
    return this.affiliates
      .get('wallets')
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getWalletHistory(
    id,
    {
      timezone,
      start_timestamp,
      end_timestamp,
      sort_column,
      sort_dir,
      limit,
      offset,
      transaction_type,
    },
  ) {
    return this.affiliates
      .get(`wallets/${id}/history`, {
        params: {
          timezone,
          start_timestamp,
          end_timestamp,
          sort_column,
          sort_dir,
          limit,
          offset,
          transaction_type,
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getWalletHistoryCount(
    id,
    {
      timezone, start_timestamp, end_timestamp, transaction_type,
    },
  ) {
    return this.affiliates
      .get(`wallets/${id}/history/count`, {
        params: {
          timezone,
          start_timestamp,
          end_timestamp,
          transaction_type,
        },
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Support
  sendMessage(payload) {
    return this.affiliates
      .post('support/send-message', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  // Notifications
  saveNotificationSettings(payload) {
    return this.affiliates
      .post('profile/update-domain-change-notifications-settings', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  addAdditionalEmail(payload) {
    return this.affiliates
      .post('profile/domain-service-notifications-additional-email', payload)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  deleteAdditionalEmail(id) {
    return this.affiliates
      .delete(`profile/domain-service-notifications-additional-email/${id}`)
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  resendAdditionalEmail(id) {
    return this.affiliates
      .post(
        `profile/domain-service-notifications-additional-email/${id}/resend`,
      )
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  getMobileApps(params) {
    return this.affiliates
      .get('mobile-apps', { params })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err));
  }

  downloadApp(id, params) {
    return this.affiliates
      .get(`mobile-apps/${id}/download-url`, { params })
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err));
  }
}
