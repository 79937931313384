<template>
  <div class="CustomReportsHeader">
    <ui-columns-select
      ref="columnsPopup"
      :value="reports[currentReport].selectedColumns"
      :groups="columnsGroups"
      :default="defaultCols"
      :metrics="getMetrics"
      :supported="supportedMetrics"
      :isDragged="isDragged"
      @changed="beforeGetReport"
    />

    <bookmarks-modal
      ref="bookmarksModal"
      :bookmarks.sync="bookmarks"
      :reports="reports"
      :all-dimension="allDimension"
    ></bookmarks-modal>

    <div
      ref="tools"
      class="tools-wrap"
      :class="{wavebg: loading}"
    >
      <custom-reports-tabs
        ref="tabs"
        :reports="reports"
        :current-report.sync="currentReport"
        :all-dimension="allDimension"
        :cache-data="cacheData"
        :bookmarks.sync="bookmarks"
        :group-by-field="groupByField"
        :single-grouping-select="singleGroupingSelect"
        @update:single-grouping-select="$emit('update:singleGroupingSelect', $event)"
        :grouping-selects="groupingSelects"
        @update:groupingSelects="$emit('update:groupingSelects', $event)"
        @get-report="getReport"
        @open-bookmark-modal="openBookmarksModal"
        @reset-filters="resetFilters"
        @select-report="selectReport"
        @cache-query="$emit('cache-query')"
      >
        <bookmarks-list
          ref="bookmarkMain"
          slot="append"
          :bookmarks.sync="bookmarks"
          :reports="reports"
          :current-report="currentReport"
          :is-popover-edit-index.sync="isPopoverEditIndex"
          @create-report="createReport"
        ></bookmarks-list>
      </custom-reports-tabs>

      <div
        v-if="!$_.isEmpty(reports[currentReport].currentFilter)"
        class="toolbar filters"
      >
        <div class="wrapper">
          <div class="item all">
            <span class="field" @click="clearCurrentFilter">{{$t('filters.all')}}</span>
          </div>
          <div v-for="(value, key) in reports[currentReport].currentFilter" :key="key" class="item">
            <span class="field">
              <i
                v-if="reports[currentReport].currentFilter[key].op == '!='"
                class="fas fa-page-break"
                style="font-size: 11px;"
              ></i>
              <i v-else class="far fa-random" style="font-size: 11px;"></i>
              {{key === 'media_buying_channel_id' || key === 'media_buying_campaign_id' ? $t('customReports.mb') : ''}}
              {{ (allDimension.find(e => e.column_name === key) || {}).title }}:
            </span>
            <span class="value">
              {{ value.visible || reports[currentReport].currentFilterNames[key]}}
              <el-dropdown trigger="click" placement="bottom-end">
                <span>{{ value.selectIdsLength }}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    class="d-flex justify-content-between dropdown-item-drill"
                    v-for="(row, i) in value.selectedRowList"
                    :key="i"
                  >
                    <div>
                      {{row.rowTooltip }}
                    </div>
                    <span class="cross-close" @click="removeCurrentDrilldown(value, row)">×</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span class="cross-close" @click="removeCurrentFilter(key)">×</span>
          </div>
        </div>
      </div>

      <div class="ml-16 mr-16" style="position: relative">
        <bookmarks-list-mobile
          ref="bookmarksModalMobile"
          :bookmarks.sync="bookmarks"
          :reports="reports"
          :all-dimension="allDimension"
          :is-popover-edit-index.sync="isPopoverEditIndex"
          @remove-promocode-report="removePromocodeReport"
          @create-report="createReport"
        ></bookmarks-list-mobile>

        <div class="players-type-mob mt-8">
          <el-popover
            placement="bottom"
            trigger="click"
            width="237"
            popper-class="players-type-wrap players-type-wrap__mob"
            v-model="playerTypeMob"
          >
            <ui-button
              class="btn"
              slot="reference"
              icon="settings"
              :class="{'btn-sm-active': playerTypeMob || reports[currentReport].playersType === 'new' || reports[currentReport].playersType === 'old'}"
            />
            <div>
              <h6>{{$t('filters.players')}}</h6>
              <div class="btn-group btn-group-sm">
                <button
                  class="btn btn-sm btn-outline-primary"
                  :class="{'btn-sm-active': reports[currentReport].playersType === 'all'}"
                  @click="changePlayersType('all')"
                >
                  {{ $t('customReports.all') }}
                </button>
                <button
                  class="btn btn-sm btn-outline-primary"
                  :class="{'btn-sm-active': reports[currentReport].playersType === 'new'}"
                  @click="changePlayersType('new')"
                >
                  {{ $t('customReports.new') }}
                </button>
                <button
                  class="btn btn-sm btn-outline-primary"
                  :class="{'btn-sm-active': reports[currentReport].playersType === 'old'}"
                  @click="changePlayersType('old')"
                >
                  {{ $t('customReports.old') }}
                </button>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="toolbar grouping ml-16 mr-16">
        <div class="wrapper">
          <div class="section d-flex">
            <div
              v-for="dimension in groupedDimensions"
              :key="dimension.name"
            >
              <el-dropdown
                :show-timeout="0"
                class="select"
                placement="bottom"
                @command="handleGroupingSelect"
                v-if="!isHiddenMany((dimension.items || []).map(e => e.column_name))"
              >
                <div :class="{active : groupingSelects[dimension.name]}" class="select-link">
                  <span
                    class="text"
                    @click.prevent="groupingClickBlocked(dimension.name, dimension.items.map(e => e.column_name)) ?
                      selectDefaultGrouping(dimension.items[0].name, $event) : null"
                  >
                    {{ dimensionsGroups.find(e => e.group_name === dimension.name).group_name === Object.keys(groupingSelects)[0]
                      ? compTitle : dimensionsGroups.find(e => e.group_name === dimension.name).title }}
                  </span>
                  <i class="fas fa-chevron-down icon--right"/>
                </div>
                <el-dropdown-menu slot="dropdown" class="ddgroup">
                  <el-dropdown-item
                    v-for="field in dimension.items.filter(item => (item.type === dimension.name && deleteGroup().indexOf(item.column_name) < 0 ))"
                    :key="field.name"
                    :command="field"
                    :class="{selected : groupingSelects[dimension.name] && groupingSelects[dimension.name].name === field.name}"
                    class="grouping_select_item"
                  >
                    {{ (allDimension.find(e => e.column_name === field.name) || []).title }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="toolbar tools ml-16 mr-16">
        <div class="wrapper">
          <div class="section section-left">
            <div class="period-wr d-flex flex-column">
              <period-wrapper
                :settings="passedPeriod"
                class="sub-affiliates__period mr-8"
                @update="setPeriod"
                @change="setRange"
              />
            </div>
            <ui-input
              :placeholder="$t('customReports.search')"
              v-model.trim="reports[currentReport].searchQuery"
              :class="{'search-input-show': swowInput}"
              is-search
              class="search-input"
              @search="getReport"
              @clear="getReport"
            />
          </div>
          <div class="section section-right">
            <div class="d-flex">
              <div class="ui-btn-group">
                <ui-button
                  :disabled="disabledActions.exclude"
                  class="btn btn-hide btn-lowercase"
                  @click="beforeCreateReport(null, '!=')"
                >
                  <i class="fas fa-page-break"></i>
                  {{ $t('customReports.exclude') }}
                </ui-button>
                <el-dropdown
                  trigger="click"
                  class="dropdown-report-icon"
                  placement="bottom-end"
                  @command="handleSelectDrilldown"
                >
                  <ui-button
                    :disabled="disabledActions.exclude || currentReport === 0"
                    class="btn btn-hide btn-lowercase"
                  >
                    <i class="fas fa-chevron-down" />
                  </ui-button>
                  <el-dropdown-menu slot="dropdown" class="ddgroup-btn">
                    <el-dropdown-item command="continue-exclude" class="grouping_select_item">
                      {{ $t('customReports.continueNewTab') }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      command="start-exclude"
                      class="grouping_select_item"
                      :class="{'disabled': reports[currentReport].defaultFilter['affiliate_id']}"
                    >
                      {{ $t('customReports.startNewTab') }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div class="ui-btn-group">
                <ui-button
                  :disabled="disabledActions.drilldown"
                  class="btn btn-hide btn-lowercase"
                  icon="drilldown"
                  @click="beforeCreateReport(null, '=')"
                >
                  {{ $t('customReports.drilldown') }}
                </ui-button>
                <el-dropdown
                  trigger="click"
                  class="dropdown-report-icon"
                  placement="bottom-end"
                  @command="handleSelectDrilldown"
                >
                  <ui-button
                    :disabled="disabledActions.drilldown || currentReport === 0"
                    class="btn btn-hide btn-lowercase"
                  >
                    <i class="fas fa-chevron-down" />
                  </ui-button>
                  <el-dropdown-menu slot="dropdown" class="ddgroup-btn">
                    <el-dropdown-item command="continue-drilldown" class="grouping_select_item">
                      {{ $t('customReports.continueNewTab') }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      command="start-drilldown"
                      class="grouping_select_item"
                      :class="{'disabled': reports[currentReport].defaultFilter['affiliate_id']}"
                    >
                      {{ $t('customReports.startNewTab') }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="select btn-search-icon mb-8">
                <ui-button
                  :class="{'search-fill-btn': swowInput}"
                  class="btn mb-0"
                  icon="search"
                  @click="swowInput = !swowInput"
                />
              </div>
              <div class="players-type-desc">
                <el-popover
                  ref="playerstype"
                  v-model="playerType"
                  placement="bottom"
                  trigger="manual"
                  width="237"
                  popper-class="players-type-wrap"
                >
                  <ui-button
                    class="btn"
                    slot="reference"
                    icon="settings"
                    :class="{'btn-sm-active': playerType || reports[currentReport].playersType === 'new' || reports[currentReport].playersType === 'old'}"
                    @click="$refs.playerstype.showPopper = !$refs.playerstype.showPopper, openPlayersType($event)"
                  />
                  <div>
                    <h6>{{$t('filters.players')}}</h6>
                    <div class="btn-group btn-group-sm">
                      <button
                        class="btn btn-sm btn-outline-primary"
                        :class="{'btn-sm-active': reports[currentReport].playersType === 'all'}"
                        @click="changePlayersType('all')"
                      >
                        {{ $t('customReports.all') }}
                      </button>
                      <button
                        class="btn btn-sm btn-outline-primary"
                        :class="{'btn-sm-active': reports[currentReport].playersType === 'new'}"
                        @click="changePlayersType('new')"
                      >
                        {{ $t('customReports.new') }}
                      </button>
                      <button
                        class="btn btn-sm btn-outline-primary"
                        :class="{'btn-sm-active': reports[currentReport].playersType === 'old'}"
                        @click="changePlayersType('old')"
                      >
                        {{ $t('customReports.old') }}
                      </button>
                    </div>
                  </div>
                </el-popover>
              </div>
              <export-report :export-links="exportUrls">
                <ui-button class="btn btn-download">
                  <i class="fas fa-file-download"></i>
                </ui-button>
              </export-report>

              <div class="select">
                <ui-button
                  class="btn btn-eye"
                  icon="eye"
                  :icon-size="9"
                  @click="openColumnsPopup"
                />
              </div>
              <ui-button icon="refresh" class="export-btn btn btn-lowercase" @click="getReport" />
            </div>
            <ui-pagination
              :page="reports[currentReport].page"
              :page-size="reports[currentReport].limit || 0"
              :key="reports[currentReport].page"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
        </div>
        <div class="report__sort-wrap">
          <div class="sort-wrap report-wrap pr-8">
            <v-select-menu
              v-if="isMobile"
              :label="$t('customReports.reportBy') + ':'"
              :notLabel="true"
              :query="false"
              :list="groupedDimensionsMob"
              :value="reports[currentReport].group_by"
              key-field="id"
              :regular="false"
              :static-key="true"
              :alt-value-text="true"
              @change="changeReport"
            />
          </div>
          <div class="sort-wrap">
            <sort-by
              :columns="getTableFields"
              :sort_by="reports[currentReport].sort_by || reports[currentReport].group_by || 'date_formatted'"
              :sort_dir="reports[currentReport].sort_dir"
              :key="render"
              @sortBy="sortBy"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!$_.isEmpty(reports[currentReport].defaultFilter) || promocodeReport.promo_code || subAffiliateReport.sub_affiliate_email"
      class="toolbar filters filters-bottom"
      :class="{wavebg: loading}"
    >
      <div id="myel" class="wrapper">
        <div class="item all">
          <span class="field" @click="clearCurrentFilter">{{$t('filters.all')}}</span>
        </div>
        <!-- mobile -->
        <div class="item" v-if="reports[currentReport].currentFilter">
          <span>
            <span class="field">
              <i
                v-if="reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]] && reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]].op == '!='"
                class="fas fa-page-break"
                style="font-size: 11px;"
              ></i>
              <i v-else class="far fa-random" style="font-size: 11px;"></i>
              {{ (allDimension.find(e => e.column_name === Object.keys(reports[currentReport].defaultFilter)[0]) || {}).title }}:
            </span>
            <span class="value">
              <span class="value-text">
                {{reports[currentReport].defaultFilterName || reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]] || ''}}
              </span>
              <el-dropdown trigger="click" placement="bottom-end">
                <span>
                  {{ reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]]
                    ? reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]].selectIdsLength : '' }}
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdown-reports">
                  <el-dropdown-item
                    class="d-flex justify-content-between dropdown-item-drill"
                    v-for="(row, i) in reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]]
                      ? reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]].selectedRowList : []"
                    :key="i"
                  >
                    <div>
                      {{row.rowTooltip }}
                    </div>
                    <span class="cross-close"
                          @click="removeCurrentReport(reports[currentReport].defaultFilter[Object.keys(reports[currentReport].defaultFilter)[0]], row)"
                    >×</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
          <span class="cross-close pt-0" @click="removePromocodeReport">×</span>
        </div>

        <div
          v-for="(value, key) in reports[currentReport].currentFilter"
          :key="key"
          class="item"
        >
          <span>
            <span class="field">
              <i
                v-if="reports[currentReport].currentFilter[key].op == '!='"
                class="fas fa-page-break"
                style="font-size: 11px;"
              ></i>
              <i v-else class="far fa-random" style="font-size: 11px;"></i>
              {{key === 'media_buying_channel_id' || key === 'media_buying_campaign_id' ? $t('customReports.mb') : ''}}
              {{ (allDimension.find(e => e.column_name === key) || {}).title }}:
            </span>
            <span class="value">
              <span class="value-text">
                {{ value.visible || reports[currentReport].currentFilterNames[key] }}
              </span>
              <el-dropdown trigger="click" placement="bottom-end">
                <span>{{ value.selectIdsLength }}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    class="d-flex justify-content-between dropdown-item-drill"
                    v-for="(row, i) in value.selectedRowList"
                    :key="i"
                  >
                    <div>
                      {{row.rowTooltip }}
                    </div>
                    <span class="cross-close" @click="removeCurrentDrilldown(value, row)">×</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
          <span
            class="cross-close pt-0"
            @click="removeCurrentFilter(key)"
          >×</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import SortBy from 'src/components/SortBy.vue';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import { Modal } from 'src/components';
import VSelectMenu from 'src/components/VSelectMenu.vue';

import CustomReportsCommonMixin from 'src/views/Dashboard/CustomReports/custom-reports-common-mixin';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import ResponsiveMixin from 'src/components/Helpers/responsive-mixin.js';
import BookmarksModal from 'src/views/Dashboard/CustomReports/Bookmarks/BookmarksModal.vue';
import BookmarksList from 'src/views/Dashboard/CustomReports/Bookmarks/BookmarksList.vue';
import BookmarksListMobile from 'src/views/Dashboard/CustomReports/Bookmarks/BookmarksListMobile.vue';
import CustomReportsTabs from 'src/views/Dashboard/CustomReports/CustomReportsTabs.vue';
import ExportReport from 'src/views/Dashboard/Reports/ExportReport.vue';

export default {
  name: 'CustomReportsHeader',
  components: {
    SortBy,
    PeriodWrapper,
    VSelectMenu,
    draggable,
    Modal,
    BookmarksModal,
    BookmarksList,
    BookmarksListMobile,
    CustomReportsTabs,
    ExportReport,
  },
  mixins: [
    CustomReportsCommonMixin,
    DimensionsMixin,
    ResponsiveMixin,
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    currentReportProp: {
      type: Number,
      default: null,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    selectedRow: {
      type: Object,
      default: () => ({}),
    },
    allDimension: {
      type: Array,
      default: () => ([]),
    },
    count: {
      type: Number,
      default: 0,
    },
    exportUrls: {
      type: Object,
      default: () => ({}),
    },
    reports: {
      type: Array,
      default: () => ([]),
    },
    columnsGroups: {
      type: Array,
      default: () => ([]),
    },
    defaultCols: {
      type: Array,
      default: () => ([]),
    },
    disabledActions: {
      type: Object,
      default: () => ({}),
    },
    cacheData: {
      type: Object,
      default: () => ({}),
    },
    groupByField: {
      type: Object,
      default: () => ({}),
    },
    getTableFields: {
      type: Array,
      default: () => [],
    },
    singleGroupingSelect: {
      type: String,
      default: '',
    },
    groupingSelects: {
      type: Object,
      default: () => ({}),
    },
    supportedMetricsProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabCountVisible: null,
      activeModalIndex: '',
      activeModal: false,
      isPinnedPopover: [],
      isNavTabDropdownVisible: false,
      bookmarks: [],
      isPopoverVis: false,
      isLoadingBookmark: false,
      promocodeReport: {},
      arrayWidthItemForTooltip: [],
      playerType: false,
      playerTypeMob: false,
      passedPeriod: {},
      period: 30,
      swowInput: false,
      allMetrics: [],
      selectIds: [],
      render: 0,
      renders: 0,
      subAffiliateReport: {},
      pCurrentReport: 0,
      isPopoverEditIndex: null,
      isDragged: false,
    };
  },
  computed: {
    storeGroupedDimension() {
      return this.$store.state.settings.grouped_dimensions || [];
    },
    groupedDimensionsIndex() {
      const result = {};

      for (const dimension of this.storeGroupedDimension) {
        result[dimension.name] = dimension.items.map(item => item.name);
      }

      return result;
    },
    groupedDimensionsBase() {
      return this.storeGroupedDimension
        .filter(dimension => !this.isHiddenMany(this.groupedDimensionsIndex[dimension.name]))
        .map(dimension => ({
          ...dimension,
          items: dimension.items
            .filter(item => !this.isHidden(item.name))
            .map(item => ({
              ...item,
              ...this.allDimension.find(y => y.column_name === item.name),
            })),
        }));
    },
    groupedDimensions() {
      const groupedDimensions = this.groupedDimensionsBase.map(e => ({
        name: e.name,
        items: e.items.map(i => ({
          ...i,
          type: e.name,
        })),
      }));

      return groupedDimensions;
    },
    dimensionsGroups() {
      return this.$store.state.settings.dimensions_groups || [];
    },
    groupedDimensionsMob() {
      const groupedDimensionsMob = this.groupedDimensionsBase.map(e => ({
        title: this.dimensionsGroups.find(y => y.group_name === e.name).title,
        list: e.items.map(i =>
        // const dimension = this.allDimension.find(y => y.column_name === i.name);

          ({
            ...i,
            text: i.title,
            name: i.title,
            id: i.name,
          })),
      }));
      this.renders++;

      return groupedDimensionsMob;
    },
    // groupedDimensionsMob() {
    //   let groupedDimensionsMob = (this.$store.state.settings.grouped_dimensions || []).map(e => {
    //     return {
    //       title: (this.$store.state.settings.dimensions_groups || []).find(y => y.group_name === e.name).title,
    //       list: e.items.map(i => {
    //         const dimension = this.allDimension.find(y => y.column_name === i.name);

    //         return {
    //           text: dimension
    //             ? dimension.title
    //             : '',
    //           id: i.name,
    //         }
    //       }),
    //     }
    //   })
    //   this.renders++

    //   return groupedDimensionsMob;
    // },
    compTitle() {
      let groupTitle = {};
      (this.groupedDimensions || []).map((e) => {
        this.groupingSelects[e.name]
          ? (groupTitle = e.items.find(i => i.name === this.groupingSelects[e.name].name) || {})
          : '';
      });
      return groupTitle.title;
    },
    currentReport: {
      get() {
        return this.pCurrentReport;
      },
      set(value) {
        this.pCurrentReport = value;
        this.$emit('update:currentReportProp', value);
      },
    },
    getMetrics() {
      return this.$store.state.settings.metrics;
    },
  },
  watch: {
    currentReportProp: {
      immediate: true,
      handler(value) {
        this.pCurrentReport = value;
        this.initPassedPeriod(value);
      },
    },
    supportedMetricsProp: {
      immediate: true,
      handler(value) {
        this.supportedMetrics = value;
      },
    },
  },
  async mounted() {
    // document.addEventListener('click', this.eventStop, true);
    await this.getBookmarks();
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.eventStop, true);
  },
  methods: {
    initPassedPeriod(index) {
      this.passedPeriod = {
        from: this.reports[index].dateRange[0],
        to: this.reports[index].dateRange[1],
        period: this.reports[index].period,
      };
    },
    selectReport(index) {
      this.$emit('select-report', index);
    },
    handleSelectDrilldown(command) {
      const report = this.$_.cloneDeep(this.reports[this.currentReport]);
      switch (command) {
        case 'continue-exclude':
          this.reports.push(report);
          this.currentReport = this.reports.length - 1;
          this.drilldownReport('!=');
          return;
        case 'start-exclude':
          this.createReport(null, '!=');
          return;
        case 'continue-drilldown':
          this.reports.push(report);
          this.currentReport = this.reports.length - 1;
          this.drilldownReport('=');
          return;
        case 'start-drilldown':
          this.createReport(null, '=');
      }
    },
    isHidden(dimesnsion) {
      return Boolean((this.reports[this.currentReport].defaultFilter[dimesnsion] !== void 0 && this.reports[this.currentReport].defaultFilter[dimesnsion].op !== '!=')
        || (this.reports[this.currentReport].currentFilter[dimesnsion] !== void 0 && this.reports[this.currentReport].currentFilter[dimesnsion].op !== '!='));
    },
    isHiddenMany(list) {
      return list
        .map(e => Boolean(this.isHidden(e)))
        .every(e => e);
    },
    handleSingleGroupingSelect(nv) {
      // this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.$emit('update:selectedRow', {});
      // this.$refs.dataTable.clearCheck();

      this.$emit('update:singleGroupingSelect', '');
      this.$emit('update:groupingSelects', {
        [nv.type]: {
          ...nv,
          name: nv.id,
        },
      });

      const reports = this.$_.cloneDeep(this.reports);

      if (reports[this.currentReport].sort_by === reports[this.currentReport].group_by) {
        reports[this.currentReport].sort_by = nv.id;
      }
      reports[this.currentReport].group_by = nv.id;
      reports[this.currentReport].searchQuery = '';
      reports[this.currentReport].page = 1;

      this.$emit('update:reports', reports);

      this.$nextTick(() => {
        this.getReport('handle single select');
      });
    },
    selectDefaultGrouping(name, event) {
      event.stopPropagation(true);
      const field = this.$_.find(this.allDimension, { name });
      this.handleGroupingSelect(field);
    },
    handleGroupingSelect(gb) {
      // this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.$emit('update:selectedRow', {});
      // this.$refs.dataTable.clearCheck();
      this.$emit('update:singleGroupingSelect', '');
      this.$emit('update:groupingSelects', {
        [gb.type]: gb,
      });

      if (this.reports[this.currentReport].sort_by === this.reports[this.currentReport].group_by) {
        this.reports[this.currentReport].sort_by = gb.name;
      }

      this.reports[this.currentReport].group_by = gb.name;

      const reports = this.$_.cloneDeep(this.reports);
      reports[this.currentReport].searchQuery = '';
      reports[this.currentReport].page = 1;
      this.$emit('update:reports', reports);

      this.getReport('handle grouping select');
    },
    deleteGroup() {
      const cf = [];
      this.$_.forIn(this.reports[this.currentReport].currentFilter, (e) => { if (e.op === '=') { cf.push(e.group_by); } });
      const df = [];
      this.$_.forIn(this.reports[this.currentReport].defaultFilter, (e) => { if (e.op === '=') { df.push(e.group_by); } });

      return this.$_.concat([], cf, df);
    },
    getExportLink(format) {
      return (this.exportUrls || {})[format];
    },
    changeReport(report) {
      this.$emit('update:singleGroupingSelect', report.id);
      this.handleSingleGroupingSelect(report);
    },
    setPeriod(period) {
      if (this.$_.isEqual(this.period, period)) {
        this.reports[this.currentReport].page = 1;
      }

      const periodItems = [7, -70, -7, -14, 30, -30, 100, 365, -365, -3650];

      this.reports[this.currentReport].dateRange[0] = period.from;
      this.reports[this.currentReport].dateRange[1] = period.to;
      this.reports[this.currentReport].period = period.period;
      this.period = period;

      if (period.period === 1) {
        this.passedPeriod = { range: [period.from, period.to] };
      } else if (periodItems.includes(period.period)) {
        this.passedPeriod = { period: period.period };
      } else {
        this.passedPeriod = {
          period: `${moment(period.from)
            .format('YYYY-MM-DD')} ${this.$t('periodWrapper.to')} ${moment(period.to)
            .format('YYYY-MM-DD')}`,
        };
      }

      this.period.period = this.passedPeriod.period;
      // this.loading = true;
    },
    clearCurrentFilter() {
      // this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      // this.selectedRow = {};
      this.$emit('update:selectedRow', {});
      this.reports[this.currentReport].currentFilter = {};
      this.reports[this.currentReport].page = 1;
      this.getReport('clear filter');
    },
    removeCurrentFilter(filter) {
      // this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      // this.selectedRow = {};
      this.$emit('update:selectedRow', {});
      this.$delete(this.reports[this.currentReport].currentFilter, filter);
      this.reports[this.currentReport].page = 1;
      this.reports[this.currentReport].group_by = this.reports[this.currentReport].prevDimension;
      this.reports[this.currentReport].sort_by = this.reports[this.currentReport].prevDimension;
      // this.singleGroupingSelect = this.reports[this.currentReport].prevDimension;
      this.$emit('update:singleGroupingSelect', this.reports[this.currentReport].prevDimension);
      const dimmension = this.allDimension.find(e => e.name === this.reports[this.currentReport].prevDimension);
      const prev = this.allDimension[this.allDimension.indexOf(dimmension) - 1] || this.allDimension[0];
      this.reports[this.currentReport].prevDimension = prev.name;
      // this.groupingSelects = {};
      // this.groupingSelects[this.groupByField.type] = this.groupByField;
      this.$emit('update:groupingSelects', {
        [this.groupByField.type]: this.groupByField,
      });
      this.getReport('remove filter');
    },
    removeCurrentReport(value, row) {
      const filterList = this.updateFilter(this.reports[this.currentReport].defaultFilter[value.group_by], row);

      this.reports[this.currentReport].defaultFilter[value.group_by] = filterList;
      this.reports[this.currentReport].defaultFilterName = filterList.selectedRowList[0].rowTooltip;
      this.getReport('removeCurrentReport');
    },
    removeCurrentDrilldown(value, row) {
      const filterList = this.updateFilter(this.reports[this.currentReport].currentFilter[value.group_by], row);

      this.reports[this.currentReport].currentFilter[value.group_by] = filterList;
      this.getReport('removeCurrentDrilldown');
    },
    updateFilter(filterList, row) {
      filterList.row_id = filterList.row_id.filter(e => e !== row.row_id);
      filterList.selectedRow = filterList.selectedRow.filter(e => e !== row.rowTooltip);
      filterList.selectedRowList = filterList.selectedRowList.filter(e => e.row_id !== row.row_id);
      return {
        row_id: filterList.row_id,
        visible: filterList.selectedRowList[0].rowTooltip,
        group_by: filterList.group_by || '',
        op: filterList.op,
        selectIdsLength: filterList.row_id.length <= 1 ? '' : `[+${filterList.row_id.length - 1}]`,
        selectedRow: filterList.selectedRow,
        selectedRowList: filterList.selectedRowList,
        row_filter_column: filterList.row_filter_column,
      };
    },
    removePromocodeReport() {
      this.isPopoverEditIndex = null;
      this.removePromocode();
      this.selectReport(0);
      this.reports.splice(1);
    },
    removePromocode() {
      this.promocodeReport = {};
      this.subAffiliateReport = {};
    },
    beforeCreateReport(customSettings, op) {
      this.$emit('before-create-report', customSettings, op);
    },
    createReport(customSettings, op, changeOp) {
      this.$emit('create-report', customSettings, op, changeOp);
    },
    drilldownReport(op) {
      this.$emit('drilldown-report', op);
    },
    setRange(period) {
      this.setPeriod(period);
      this.getReport('setRange');
    },
    getReport() {
      this.$emit('get-report', this.reports[this.currentReport]);
    },
    beforeGetReport(value) {
      this.$emit('before-get-report', value);
    },
    openColumnsPopup() {
      this.$refs.columnsPopup.open(this.reports[this.currentReport].selectedColumns);
    },
    sortBy(sort) {
      this.reports[this.currentReport].sort_dir = sort.sort_dir;
      this.reports[this.currentReport].sort_by = sort.sort_by;
      this.getReport('sortBy');
    },
    pageChange(page, size) {
      if (this.reports[this.currentReport].page !== page || this.reports[this.currentReport].limit !== size) {
        // this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.reports[this.currentReport].page = page;
        this.reports[this.currentReport].limit = size;
        this.getReport('handle page change');
      }
    },
    openPlayersType(event) {
      const popper = this.$refs.playerstype.$refs.popper;
      let listener3;
      document.addEventListener('mousedown', listener3 = (e) => {
        if (event.target !== e.target && (e.clientY > popper.offsetTop + popper.offsetHeight || e.clientY < popper.offsetTop || e.clientX > popper.offsetLeft + popper.offsetWidth || e.clientX < popper.offsetLeft)) {
          this.$refs.playerstype.showPopper = false;
          document.removeEventListener('mousedown', listener3);
        }
      });
    },
    lastTab() {
      this.$refs.tabs.lastTab();
    },
    getBookmarks() {
      this.isLoadingBookmark = true;
      return this.$api.getBookmarks()
        .then((response) => {
          this.bookmarks = response.payload;
          this.isLoadingBookmark = false;
          if (this.bookmarks.length === 0) {
            this.isPopoverVis = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingBookmark = false;
        });
    },
    groupingClickBlocked(type, groups) {
      if ((this.groupingSelects[type] && this.groupingSelects[type].type === type) || !groups.every(e => !this.isHidden(e))) {
        return false;
      }
      return true;
    },
    openBookmarksModal(index, event) {
      if (this.$refs.bookmarksModal) {
        this.$refs.bookmarksModal.open(index, event);
      }
    },
    resetFilters(index) {
      this.$emit('reset-filters', index);
      this.passedPeriod = this.reports[index].period;
    },
    changePlayersType(type) {
      this.playerType = false;
      this.playerTypeMob = false;
      this.reports[this.currentReport].playersType = type;
      this.getReport('changePlayersType');
    },
  },
};
</script>
