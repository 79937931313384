<template>
  <div class="row chart-component">
    <div class="col-12 col-xl-6 cust-pr">
      <div class="card padding-mainchart">
        <h6 class="card-title">
          {{ $t('dashboard.conversionStatistics') }}
          <hr>
        </h6>
        <div class="main-wr">
          <div class="btn-group btn-group-sm ml-cust">
            <button
              :class="{'active': typeRange === 'week'}"
              class="btn btn-sm btn-outline-primary btn-brd"
              @click="mainchart=true; getMain(100); "
            >
              {{ $t('dashboard.week') }}
            </button>
            <button
              :class="{'active': typeRange === 'month'}"
              class="btn btn-sm btn-outline-primary btn-brd"
              @click="mainchart=true; getMain(30);"
            >
              {{ $t('dashboard.month') }}
            </button>
            <button
              :class="{'active': typeRange === 'year'}"
              class="btn btn-sm btn-outline-primary btn-brd"
              @click="mainchart=true; getMain(-30);"
            >
              {{ $t('dashboard.prev_month') }}
            </button>
          </div>
          <div
            class="err-data"
            v-if="$store.state.report.loadingmain !== false"
            v-loading="$store.state.report.loadingmain !== false"
          ></div>
          <div v-if="$store.state.report.dataerrmain == false || dCon" class="err-data">
            <p v-if="!$store.state.report.dataerrmain">
              {{ $t('alerts.dataTable.loadingError') }}
            </p>
            <p v-if="dCon && !$store.state.report.loadingmain">
              {{ $t('alerts.dataTable.noData') }}
            </p>
          </div>
          <line-chart
            ref="resetChartMain"
            :configuration="mainChartConfig"
            :labels="arrayDate"
            :datasets="chart_datasets"
            :height="414"
            :d-reg="dReg"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6 cust-pl-2">
      <div class="card padding-chart">
        <h6 class="card-title">
          {{ $t('dashboard.registrationStatistics') }}
          <hr>
        </h6>
        <div class="btn-group btn-group-sm ml-cust ml-cust-bottom">
          <button
            :class="{'active': typeRange1 === 'week'}"
            class="btn btn-sm btn-outline-primary btn-brd"
            @click="minichart=true; getMini(100);"
          >
            {{ $t('dashboard.week') }}
          </button>
          <button
            :class="{'active': typeRange1 === 'month'}"
            class="btn btn-sm btn-outline-primary btn-brd"
            @click="minichart=true; getMini(30);"
          >
            {{ $t('dashboard.month') }}
          </button>
          <button
            :class="{'active': typeRange1 === 'year'}"
            class="btn btn-sm btn-outline-primary btn-brd"
            @click="minichart=true; getMini(-30);"
          >
            {{ $t('dashboard.prev_month') }}
          </button>
        </div>
        <chart-mini
          ref="resetChartMini1"
          :configuration="miniChartConfig1"
          :labels="arrayDateRegistration"
          :datasets="chart_datasets_reg"
          :height="138"
          :d-reg="dReg"
        />
        <chart-mini
          ref="resetChartMini2"
          :configuration="miniChartConfig2"
          :labels="arrayDateDeposits"
          :datasets="chart_datasets_dep"
          :height="138"
          :d-reg="dDep"
        />
        <chart-mini
          ref="resetChartMini3"
          :configuration="miniChartConfig3"
          :labels="arrayDateNetProfit"
          :datasets="chart_datasets_net_prof"
          :height="138"
          :d-reg="dNetProf"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from 'src/components/Charts/LineChartDashbord';
import ChartMini from 'src/components/Charts/ChartMini';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper';
import moment from 'moment';
import UrlMixin from 'src/components/Query/url-mixin';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import formattedCurrency from 'src/helpers/formattedCurrency.js';

export default {
  components: {
    LineChart,
    ChartMini,
    PeriodWrapper,
  },
  mixins: [
    UrlMixin,
    HelpersMixin,
  ],

  data() {
    return {
      chartPeriod: '',
      miniChartPeriod: '',
      range: {},
      typeRange: 'week',
      typeRange1: 'week',
      mainchart: true,
      minichart: true,
      actions: {
        price: [
          {
            title: 'Buy',
            class: 'text-red',
            classMobile: 'text-red',
            callback: (row) => {
              console.log(row);
            },
          },
          {
            title: 'More Info',
            class: 'text-info',
            classMobile: 'btn-respons-info',
            callback: () => {},
            name: 'expand',
            type: 'expand',
          },
        ],
      },

      dataConversion: [],
      arrayDate: [],
      chart_datasets: [],
      dCon: false,

      dataRegistration: [],
      arrayDateRegistration: [],
      chart_datasets_reg: [],
      dReg: false,

      dataDeposits: [],
      arrayDateDeposits: [],
      chart_datasets_dep: [],
      dDep: false,

      dataNetProfit: [],
      arrayDateNetProfit: [],
      chart_datasets_net_prof: [],
      dNetProf: false,

      axisId: ['left-y-axis', 'right-y-axis'],

      mainChartConfig: {
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        tooltips: {
          bodySpacing: 4,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
        },
        responsive: 1,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                  day: 'MMM DD',
                },
                round: 'day',
              },
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
              },
            },
          ],
          yAxes: [
            {
              id: 'left-y-axis',
              position: 'left',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                callback(value) {
                  if (value % 1 === 0) {
                    return kFormatter(value);
                  }
                  function kFormatter(num) {
                    return num > 999 ? `${(num / 1000).toFixed(0)}k` : num;
                  }
                },
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('dashboard.visits_count'),
              },
            },
            {
              id: 'right-y-axis',
              position: 'right',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                beginAtZero: true,
                callback(value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
                suggestedMin: 0,
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('dashboard.impressions_count'),
              },
            },
          ],
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 15,
          },
        },
      },

      miniChartConfig1: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          bodySpacing: 4,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
        },
        responsive: 1,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                  day: 'MMM DD',
                },
                round: 'day',
              },
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                suggestedMin: 0,
              },
            },
          ],
          yAxes: [
            {
              position: 'left',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                display: false,
                beginAtZero: true,
                callback(value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
                suggestedMin: 0,
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('dashboard.registration'),
              },
            },
            {
              id: 'mini1',
              position: 'right',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                beginAtZero: true,
                callback(value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
                suggestedMin: 0,
              },
              scaleLabel: {
                display: false,
                labelString: this.$t('dashboard.registration'),
              },
            },
          ],
        },

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 15,
          },
        },
      },

      miniChartConfig2: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          bodySpacing: 4,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
        },
        responsive: 1,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                  day: 'MMM DD',
                },
                round: 'day',
              },
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
              },
            },
          ],
          yAxes: [
            {
              position: 'left',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                display: false,
                beginAtZero: true,
                callback(value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
                suggestedMin: 0,
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('dashboard.deposits'),
              },
            },
            {
              id: 'mini2',
              position: 'right',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                beginAtZero: true,
                callback(value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
                suggestedMin: 0,
              },
              scaleLabel: {
                display: false,
                labelString: this.$t('dashboard.deposits'),
              },
            },
          ],
        },

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 15,
          },
        },
      },

      miniChartConfig3: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          bodySpacing: 4,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label;

              if (label) {
                label += ': ';
              }
              label += `$ ${formattedCurrency(tooltipItem.yLabel)}`;
              return label;
            },
          },
        },
        responsive: 1,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                  day: 'MMM DD',
                },
                round: 'day',
              },
              gridLines: {
                display: false,
              },
              ticks: {
                autoSkip: true,
              },
            },
          ],
          yAxes: [
            {
              position: 'left',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                display: false,
                beginAtZero: true,
                suggestedMin: 0,
                callback(value, index, values) {
                  if (value % 1 === 0) {
                    return `$${value}`;
                  }
                },
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('dashboard.net_profit_sum'),
              },
            },
            {
              id: 'mini3',
              position: 'right',
              gridLines: {
                borderDash: [0, 5],
              },
              ticks: {
                beginAtZero: true,
                suggestedMin: 0,
                callback(value, index, values) {
                  if (value % 1 === 0) {
                    return `$${value}`;
                  }
                },
              },
              scaleLabel: {
                display: false,
                labelString: this.$t('dashboard.net_profit_sum'),
              },
            },
          ],
        },

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 15,
          },
        },
      },
    };
  },

  computed: {
    chartData() {
      return this.$store.state.report.chart;
    },
    chartDataMini() {
      return this.$store.state.report.chartmini;
    },
  },
  watch: {
    '$i18n.locale': function () {
      if (this.isComponentActivated) {
        setTimeout(() => {
          this.chartRender();
          this.chartRenderMini();
          this.mainChartConfig.scales.yAxes[0].scaleLabel.labelString = this.$t('dashboard.visits_count');
          this.mainChartConfig.scales.yAxes[1].scaleLabel.labelString = this.$t('dashboard.impressions_count');
          this.miniChartConfig1.scales.yAxes[0].scaleLabel.labelString = this.$t('dashboard.registration');
          this.miniChartConfig2.scales.yAxes[0].scaleLabel.labelString = this.$t('dashboard.deposits');
          this.miniChartConfig3.scales.yAxes[0].scaleLabel.labelString = this.$t('dashboard.net_profit_sum');
        }, 300);
      }
    },

    chartDataMini(chart) {
      if (chart.length) {
        const dataRegistration = chart.map(e => ({
          date: e.row_id,
          registrations_count: e.registrations_count,
        }));
        const dataDeposits = chart.map(e => ({
          date: e.row_id,
          deposits_all_count: e.deposits_all_count,
        }));
        const dataNetProfit = chart.map(e => ({
          date: e.row_id,
          net_profit_sum: e.net_profit_sum,
        }));

        this.dataRegistration = dataRegistration;
        this.dataDeposits = dataDeposits;
        this.dataNetProfit = dataNetProfit;

        this.chartRenderMini();
      }
    },
    chartData(chart) {
      if (chart.length) {
        const dataConversion = chart.map(e => ({
          date: e.row_id,
          visits: e.visits_count,
          impressions: e.impressions_count,
        }));
        this.dataConversion = dataConversion;

        this.chartRender();
      }
    },
  },
  activated() {
    if (localStorage.getItem('auth-token') !== 'null') {
      this.getMain(this.chartPeriod || 100);
      this.getMini(this.miniChartPeriod || 100);
    }
  },
  created() {
    this.$on('refresh', () => {
      this.getMain(this.chartPeriod || 100);
      this.getMini(this.miniChartPeriod || 100);
    });
  },

  mounted() {
    this.chartPeriod = this.getUrlQueryParam('chartPeriod');
    this.miniChartPeriod = this.getUrlQueryParam('miniChartPeriod');
    if (localStorage.getItem('auth-token') !== 'null') {
      this.getMain(this.chartPeriod || 100);
      this.getMini(this.miniChartPeriod || 100);
    }
  },
  methods: {
    resetChartMain() {
      this.$refs.resetChartMain.$data._chart.resetZoom();
    },

    resetChartMini(v) {
      switch (v) {
        case 1:
          this.$refs.resetChartMini1.$data._chart.resetZoom();
          break;
        case 2:
          this.$refs.resetChartMini2.$data._chart.resetZoom();
          break;
        case 3:
          this.$refs.resetChartMini3.$data._chart.resetZoom();
      }
    },

    setPeriod(period) {
      const firstDayOfMonth = moment().startOf('month');
      const firstDayOfWeek = moment().startOf('week');
      const firstDayOfYear = moment().startOf('year');
      const lastDayOfMonth = moment().endOf('month');
      const lastDayOfWeek = moment().endOf('week');
      const lastDayOfYear = moment().endOf('year');
      const endOfDay = moment().endOf('day');
      const now = new Date();

      let start = new Date();
      let end = new Date();

      switch (period) {
        // last month
        case -30:
          start = moment(firstDayOfMonth).subtract(1, 'months');
          end = moment(lastDayOfMonth).subtract(1, 'months');
          break;
        // -30 days
        case 100:
          start = (() => {
            const d = new Date();
            d.setDate(d.getDate() - 30);
            return moment(d).startOf('day');
          })();
          end = endOfDay;
          break;
        // this month
        case 30:
          start = firstDayOfMonth;
          end = endOfDay;
          break;
        default:
          start = (() => {
            const d = new Date();
            d.setDate(d.getDate() - 30);
            return moment(d).startOf('day');
          })();
          end = endOfDay;
      }
      start = `${moment(start).format('YYYY-MM-DD HH:mm:ss')}`;
      end = `${moment(end).format('YYYY-MM-DD HH:mm:ss')}`;
      return { from: start, to: end };
    },

    getMini(period) {
      switch (period) {
        case 100:
          this.typeRange1 = 'week';
          break;
        case 30:
          this.typeRange1 = 'month';
          break;
        case -30:
          this.typeRange1 = 'year';
          break;
        default:
          this.typeRange1 = 'week';
      }
      this.chartPeriod = period;
      const miniRange = this.setPeriod(period);

      this.$store.dispatch('getStatisticsMini', miniRange).then((data) => {
        this.$emit('chartPeriod', { type: 'mini', period });
      });
    },

    getMain(period) {
      switch (period) {
        case 100:
          this.typeRange = 'week';
          break;
        case 30:
          this.typeRange = 'month';
          break;
        case -30:
          this.typeRange = 'year';
          break;
        default:
          this.typeRange = 'week';
      }

      this.chartPeriod = period;
      const mainRange = this.setPeriod(period);

      this.$store.dispatch('getStatistics', mainRange).then(() => {
        this.$emit('chartPeriod', { type: 'normal', period });
      });
    },

    // /start govnokod
    chartRender() {
      const dateBool = Object.keys(this.dataConversion[0]).some(e => e == 'date');

      if (dateBool) {
        var objKey = Object.keys(this.dataConversion[0]).filter(e => e != 'date');

        this.arrayDate = [];

        for (let i = 0; i < this.dataConversion.length; i++) {
          this.arrayDate.push(this.dataConversion[i].date);
        }
      }

      const dataObj = [];

      for (let i = 0; i < objKey.length; i++) {
        const colorRandom = ['#008DDD', '#FF4B63'];

        // main chart setting
        dataObj.push({
          label: this.$t(`dashboard.${objKey[i]}`),
          borderColor: colorRandom[i],
          pointBorderColor: colorRandom[i],
          pointBackgroundColor: colorRandom[i],
          borderWidth: 1,
          pointRadius: 3,
          backgroundColor: colorRandom[i] + 11,
          data: [],
          yAxisID: this.axisId[i],
        });

        for (let j = 0; j < this.dataConversion.length; j++) {
          const keys = this.dataConversion[j];
          for (const key in this.dataConversion[j]) {
            if (key == objKey[i]) {
              dataObj[i].data.push(keys[key]);
            }
          }
        }
      }
      for (let i = 0; i < dataObj[1].data.length; i++) {
        if (dataObj[1].data[i] != 0) {
          this.dCon = false;
          break;
        } else if (dataObj[0].data[i] != 0) {
          this.dCon = false;
          break;
        } else {
          this.dCon = true;
        }
      }

      this.chart_datasets = dataObj;
    },

    chartRenderMini() {
      const dateBool = Object.keys(this.dataRegistration[0]).some(e => e == 'date');

      if (dateBool) {
        var objKeyReg = Object.keys(this.dataRegistration[0]).filter(e => e != 'date');
        var objKeyDep = Object.keys(this.dataDeposits[0]).filter(e => e != 'date');
        var objKeyNetProf = Object.keys(this.dataNetProfit[0]).filter(e => e != 'date');

        this.arrayDateRegistration = [];
        this.arrayDateDeposits = [];
        this.arrayDateNetProfit = [];

        for (let i = 0; i < this.dataRegistration.length; i++) {
          this.arrayDateRegistration.push(this.dataRegistration[i].date);
        }
        for (let i = 0; i < this.dataDeposits.length; i++) {
          this.arrayDateDeposits.push(this.dataDeposits[i].date);
        }
        for (let i = 0; i < this.dataNetProfit.length; i++) {
          // console.log('this.dataProfit', this.dataProfit);
          this.arrayDateNetProfit.push(this.dataNetProfit[i].date);
        }
      }

      const dataObjReg = [];
      const dataObjDep = [];
      const dataObjNetProf = [];

      for (let i = 0; i < objKeyReg.length; i++) {
        const colorRandom = ['#3F9A20', '#ffce06', '#4612ee'];

        // registration chart setting
        dataObjReg.push({
          label: this.$t(`customReports.${objKeyReg[i]}`),
          borderColor: colorRandom[0],
          pointBorderColor: colorRandom[0],
          pointBackgroundColor: colorRandom[0],
          borderWidth: 1,
          pointRadius: 2,
          backgroundColor: `${colorRandom[0]}11`,
          data: [],
          yAxisID: 'mini1',
        });

        // deposit chart setting
        dataObjDep.push({
          label: this.$t(`customReports.${objKeyDep[i]}`),
          borderColor: colorRandom[1],
          pointBorderColor: colorRandom[1],
          pointBackgroundColor: colorRandom[1],
          borderWidth: 1,
          pointRadius: 2,
          backgroundColor: `${colorRandom[1]}11`,
          data: [],
          yAxisID: 'mini2',
        });

        // profit chart setting
        dataObjNetProf.push({
          label: this.$t(`customReports.${objKeyNetProf[i]}`),
          borderColor: colorRandom[2],
          pointBorderColor: colorRandom[2],
          pointBackgroundColor: colorRandom[2],
          borderWidth: 1,
          pointRadius: 2,
          backgroundColor: `${colorRandom[2]}11`,
          data: [],
          yAxisID: 'mini3',
        });

        for (let j = 0; j < this.dataRegistration.length; j++) {
          const keysReg = this.dataRegistration[j];
          const keysDep = this.dataDeposits[j];
          const keysNetProf = this.dataNetProfit[j];

          for (const key in this.dataRegistration[j]) {
            if (key == objKeyReg[i]) {
              dataObjReg[i].data.push(keysReg[key]);
            }
          }
          for (const key in this.dataDeposits[j]) {
            if (key == objKeyDep[i]) {
              dataObjDep[i].data.push(keysDep[key]);
            }
          }
          for (const key in this.dataNetProfit[j]) {
            if (key == objKeyNetProf[i]) {
              dataObjNetProf[i].data.push(keysNetProf[key]);
            }
          }
        }
      }

      dataObjReg.forEach((e) => {
        if (e.data != 0) {
          this.dReg = false;
        } else {
          this.dReg = true;
        }
      });
      dataObjDep.forEach((e) => {
        if (e.data != 0) {
          this.dDep = false;
        } else {
          this.dDep = true;
        }
      });

      for (let i = 0; i < dataObjReg[0].data.length; i++) {
        if (dataObjReg[0].data[i] != 0) {
          this.dReg = false;
          break;
        } else {
          this.dReg = true;
        }
      }
      for (let i = 0; i < dataObjDep[0].data.length; i++) {
        if (dataObjDep[0].data[i] != 0) {
          this.dDep = false;
          break;
        } else {
          this.dDep = true;
        }
      }
      for (let i = 0; i < dataObjNetProf[0].data.length; i++) {
        if (dataObjNetProf[0].data[i] != 0) {
          this.dNetProf = false;
          break;
        } else {
          this.dNetProf = true;
        }
      }

      this.chart_datasets_reg = dataObjReg;
      this.chart_datasets_dep = dataObjDep;
      this.chart_datasets_net_prof = dataObjNetProf;
    },
    // / end govnokod //
  },
};
</script>

<style lang="scss">
.chart-component {
  .mini-wr,
  .main-wr {
    position: relative;
    .err-data {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 50px;
      width: 150px;
      margin: auto;
      z-index: 1;
      opacity: 0.7;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .reset-chart {
      opacity: 0.7;
      text-transform: uppercase;
      position: absolute;
      right: 50px;
      transition: all 0.3s;
      z-index: 1;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  .main-wr .err-data {
    font-size: 20px;
  }
  .padding-mainchart, .padding-chart{
    padding-bottom: 10px !important;
    .card-title {
      margin-bottom: 0;
    }
  }
  .padding-mainchart {
    position: relative;
    .reset-chart {
      position: absolute;
      text-transform: uppercase;
      right: 60px;
      top: 130px;
      opacity: 0.7;
      transition: all 0.3s;
      z-index: 1;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
      span {
        &::selection {
          background: transparent;
        }
      }
    }
  }
  .ml-cust {
    z-index: 2;
    .btn-sm.btn-outline-primary {
      padding: 4px 0px;
    }
  }
  .btn-group {
    .btn-brd {
      width: 30%;
    }
  }
  .cust-pr {
    padding-left: 0;
    padding-right: 8px;
  }
  .cust-pl-2 {
    padding-left: 8px;
    padding-right: 0;
  }
  .ml-cust {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1200px) {
    .cust-pl-2 {
      padding-left: 0px;
    }
    .cust-pr {
      padding-right: 0;
      padding-bottom: 24px;
    }
  }
  @media (max-width: 650px) {
    .padding-mainchart, .padding-chart{
      padding: 16px 16px 4px !important;
      h6{
        font-size: 14px;
      }
    }
    .ml-cust {
      padding: 0 !important;
      .btn {
        width: 100%;
      }
    }
    .ml-cust-bottom {
      padding: 0 !important;
    }
  }
}
</style>
